import _ from 'lodash';

export function urlForProduct (product) {
  if (_.get(product, 'sku')) {
    if (product.sku === 'PTD01') {
      return '/product/dress';
    } else if (product.sku === 'PTS01') {
      return '/product/t-shirts';
    } else if (product.sku === 'PTL01') {
      return '/product/leggings';
    } else if (product.sku === 'PTB01') {
      return '/product/lisa-beanie';
    } else if (product.sku === 'PTFC01') {
      return '/product/face-covers';
    }
  }
  return '/';
}

export function firstNameFromDesignersName (designersName) {
  const words = _.words(designersName);

  if (words.length > 0) {
    return words[0];
  }

  return '';
}

export function shortNameForVariant ({ name }) {
  return name
    .replace('T-Shirt ', '')
    .replace('Dress ', '')
    .replace('Leggings ', '')
    .replace('Beanie ', '')
    .replace('Face Cover ', '');
}
