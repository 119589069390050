import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { formatPriceShort } from '../../utils/currency';

const ReviewAddition = ({ parentProduct, products, onAddProduct }) => {

  let additionalSkus;
  let additionalTypes;
  if (parentProduct.sku === 'PTS01') {
    additionalSkus = ['PTS01AG', 'PTS01', 'SCR01' ];
    additionalTypes = [ null, null, null ];
  } else if (parentProduct.sku === 'PTD01') {
    additionalSkus = ['PTD01AG', 'PTD01', 'SCR01' ];
    additionalTypes = [ null, null, null  ];
  } else if (parentProduct.sku === 'PTB01') {
    additionalSkus = ['PTB01AG', 'PTB01', 'PTB01' ];
    additionalTypes = [ null, "Kids'", "Grown-up" ];
  } else if (parentProduct.sku === 'PTL01') {
    additionalSkus = ['PTL01', 'PTL01AG', 'SCR01' ];
    additionalTypes = [ null, null, null ];
  } else if (parentProduct.sku === 'PTFC01') {
    additionalSkus = ['PTFC01AG', 'PTFC01', 'SCR01' ];
    additionalTypes = [ null, null, null ];
  }

  if (!additionalSkus) {
    return null;
  }

  const productsJsx = _.map(additionalSkus, (sku,idx) => {
    const product = _.find(products, function (data) {
      return data.sku === sku;
    });

    const additionalType = additionalTypes[ idx ] || '';
    let description = product.description;
    let cssSuffix = '';

    if (sku === 'PTS01') {
      cssSuffix = '-combined';
      description = `A matching kid-sized or grown-up shirt with identical design, any size.`;
    } else if (sku === 'PTD01') {
      cssSuffix = '-combined';
      description = `A matching kid-sized or women's dress with identical design, any size.`;
    } else if (sku === 'PTL01') {
        description = `A matching kid-sized or women's leggings with identical design, any size.`;
    } else if (sku === 'PTB01') {
      if (additionalType === "Kids'") {
        cssSuffix = '-kids';
        description = `A matching kid-sized beanie with identical design, any size.`;
      }
      if (additionalType === "Grown-up") {
        cssSuffix = '-grownup';
        description = `A matching grown-up beanie with identical design, any size.`;
      }
    } else if (sku === 'PTFC01') {
      description = `A matching non-medical face covering with identical design, any size.`;
    }

    if (sku === 'SCR01' && parentProduct.sku === 'PTL01') {
      cssSuffix = '-leggings';
    }

    const imageClass = classNames({
      'review-addition__image': true,
      [`review-addition__image--${product.sku}${cssSuffix}`]: true
    });

    return (
      <div className="review-addition__box" key={`${product.id}-${idx}`}>
        <div className={imageClass} />

        <div className="review-addition__content">
          <h4 className="text--bold">{additionalType} {product.name}</h4>
          <span className="review-addition__price text--bold">
            {product.bundledPrice < product.price && (<React.Fragment>
              <span className="review-addition__old-price">{formatPriceShort(product.price)}</span>
              {' '}
            </React.Fragment>)} 
            {formatPriceShort(product.bundledPrice)}
          </span>

          <p className="text--light">{description}</p>

          <button className="button button--outline-primary" onClick={_.partial(onAddProduct, sku)}>Add one</button>
        </div>
      </div>
    );
  });

  return (
    <div className="review-addition clearfix">
      {productsJsx}
    </div>
  );
};

ReviewAddition.propTypes = {
  parentProduct: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  onAddProduct: PropTypes.func.isRequired
};

export default ReviewAddition;
