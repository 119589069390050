import numeral from 'numeral';

export function formatPrice (value) {
  return numeral(value).divide(100).format('$0,0.00');
}

export function formatNumber (value) {
  return numeral(value).format('0,0');
}

export function formatPriceShort (value) {
  return numeral(value).divide(100).format('$0,0[.]00');
}

export function priceToCents (price) {
  try {
    return numeral(price).multiply(100).value();
  } catch(e) {
    return 0;
  }
}

export function typedPriceIsValid (price) {
  return price && price.match(/^\$?\d+(,\d{3})*(\.[0-9]{2})?$/);
}

export function addDollarSign (price) {
  if (typedPriceIsValid(price) && !price.match(/^\$/)) {
    return `$${price}`;
  } else {
    return price;
  }
}