import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Moose from '../../clients/moose';
import { dateOnly } from '../../utils/datetime';
import { flattenedLineItems, creditDisplayName, canReorderLineItem } from '../../utils/orders';
import { formatPrice } from '../../utils/currency';
import { getCarrier, getCarrierLink } from '../../utils/shipmethod';
import { beforeTaxCredits, afterTaxCredits } from '../../utils/checkout';

import LoadingSpinner from '../../components/meta/loading-spinner';
import DualPrice from '../../components/products/dual-price';
import UploadThumbnail from '../../components/products/upload-thumbnail';
import Header from '../../components/structure/header';
import MyAccountHeader from '../../components/my-account/my-account-header';
import BackgroundOverlay from '../../components/structure/background-overlay';

const OrdersRoute = createReactClass({

  propTypes: {
    user: PropTypes.object.isRequired,
    onReorderClick: PropTypes.func.isRequired
  },

  getInitialState() {
    return {
      isFetchingOrders: false
    }
  },

  componentDidMount() {
    this.isMounted = true;
    this.fetchOrders();    
  },

  componentWillUnmount() {
    this.isMounted = false;
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.id !== this.props.user.id) {
      this.fetchOrders();
    }
  },

  fetchOrders() {
    this.setState({ isFetchingOrders: true });
    Moose.fetchMyOrders().then((orders) => {
      if (this.isMounted) {
        this.setState({ orders, isFetchingOrders: false });
      }
    });
  },

  renderAddresses(order) {
    const { shippingAddress:ship={}, billingAddress:bill={} } = order;
    const showShippingAddress = order.lineItems && order.lineItems.length > 0;
    const showBillingAddress = false;
    if (!showShippingAddress && !showBillingAddress) {
      return null;
    }
    return (<div className="my-account__order-addresses">
      {showShippingAddress ? <div className="my-account__order-address">
        <div className="my-account__order-address-label">Shipping Address</div>
        <div>{ship.name}</div>
        {ship.companyName && <div>{ship.companyName}</div>}
        <div>{ship.street1}</div>
        {ship.street2 && <div>{ship.street2}</div>}
        <div>{ship.city}, {ship.state} {ship.postalCode}</div>
        <div>{ship.country}</div>
      </div> : null}
      {showBillingAddress ? <div className="my-account__order-address">
        <div className="my-account__order-address-label">Billing Address</div>
        <div>{ship.name}</div>
        {bill.companyName && <div>{bill.companyName}</div>}
        <div>{bill.street1}</div>
        {bill.street2 && <div>{bill.street2}</div>}
        <div>{bill.city}, {bill.state} {bill.postalCode}</div>
        <div>{bill.country}</div>        
      </div> : null}
    </div>)
  },

  renderShipments(order) {
    if (!order.shipments || order.shipments.length === 0) {
      return null;
    }
    const shipments = order.shipments.map(shipment => {
      const carrier = getCarrier(shipment.trackingNumber);
      const trackingLink = getCarrierLink(carrier, shipment.trackingNumber);
      if (trackingLink) {
        return (
          <div className="my-account__order-shipment" key={shipment.shipmentId}>
            <div><b>{carrier} Tracking</b></div>
            <div><a href={trackingLink} target="_blank" rel="noopener noreferrer">{shipment.trackingNumber}</a></div>
          </div>
        )
      }
      return null;
    });
    return (<div className="my-account__order-shipments">
      {shipments}
    </div>);
  },

  renderPayment(order) {
    const btCredits = beforeTaxCredits(order.credits).map(c => {
      return (<div className="my-account__order-payment-line" key={c.id}>
        {creditDisplayName(c)}
        <span>-{formatPrice(c.amountUsed)}</span>
      </div>);
    });
    const atCredits = afterTaxCredits(order.credits).map(c => {
      return (<div className="my-account__order-payment-line" key={c.id}>
        {creditDisplayName(c)}
        <span>-{formatPrice(c.amountUsed)}</span>
      </div>);
    });    
    const payments = order.payments.map(p => {
      return (<div className="my-account__order-payment-method" key={p.id}>
        Paid with {p.cardType || 'Credit Card'}{p.last4 && ' ending in ' + p.last4}
      </div>);
    });
    return (<div className="my-account__order-payment">
      
      <div className="my-account__order-payment-line">
        Subtotal
        <span>{formatPrice(order.subtotalPrice)}</span>
      </div>

      {btCredits}
      
      {(order.salesTax > 0) && <div className="my-account__order-payment-line">
        Sales Tax
        <span>{formatPrice(order.salesTax)}</span>
      </div>}
      
      {(order.shippingPrice > 0) && <div className="my-account__order-payment-line">
        {order.shippingDescription}
        <span>{formatPrice(order.shippingPrice)}</span>
      </div>}
      
      {atCredits}
      
      <div className="my-account__order-payment-total">
        Total
        <span>{formatPrice(order.finalPrice)}</span>
      </div>
      {payments}
    </div>);
  },

  renderOrders() {
    const { orders } = this.state;
    if (!orders || orders.length === 0) {
      return <p>No order has been made yet.</p>
    }
    return orders.map((order) => {

      const lineItems = flattenedLineItems(order).map((lineItem) => {
        const quantityText = lineItem.quantity > 1 ? ` x ${lineItem.quantity}` : ''; 
        const productOptions = lineItem.variants.map(v => v.productVariantOptionDisplayValue).join(' | ');

        return (<div className={`my-account__order-item review-line-item--${lineItem.productSku}`} key={lineItem.id}>
          <div className="order-item__cols">
            <div className="order-item__thumbnail">
              <UploadThumbnail upload={lineItem.upload} />
            </div>
            <div className="order-item__content">
              <div className="order-item__description">{lineItem.productName} {quantityText}</div>
              <div className="order-item__options">{productOptions}</div>
              <div className="order-item__designer">Designed by: {lineItem.designersName}</div>
              {canReorderLineItem(lineItem) && (<button className="order-item__reorder" 
                onClick={() => this.props.onReorderClick(lineItem)}>Re-order</button>)}
            </div>
            <div className="order-item__price">
              <DualPrice subtotalPrice={lineItem.subtotalPrice} finalPrice={lineItem.finalPrice} />
            </div>
          </div>
        </div>);
      }); 

      const giftCerts = order.giftCertificates.map((gc) => {
        return (<div className="my-account__order-item review-line-item--GC" key={gc.id}>
          <div className="order-item__cols">
            <div className="order-item__thumbnail">
              <div className="review-line-item__thumbnail"></div>
            </div>
            <div className="order-item__content">
              <div className="order-item__description">Gift Certificate</div>
              {gc.to && <div className="order-item__to">To: {gc.to}</div>}
              {gc.from && <div className="order-item__from">From: {gc.from}</div>}
            </div>
            <div className="order-item__price">{formatPrice(gc.amount)}</div>
          </div>
          {gc.message && <div className="order-item__message">Message: {gc.message}</div>}
        </div>);
      });

      return (<div className="my-account__order" key={order.id}>
        <div className="my-account__order-title">
          Order <b>#{order.id}</b> - {dateOnly(order.createdAt)}
          <div className={`my-account__order-status order-status-${order.status.name}`}>{order.status.description}</div>
        </div>
        {lineItems}
        {giftCerts}
        {this.renderAddresses(order)}
        {this.renderShipments(order)}
        {this.renderPayment(order)}
      </div>);
    });
  },
  
  render() {
    const { user } = this.props;
    const { isFetchingOrders } = this.state;
    return (
      <>
        <Header />
        <BackgroundOverlay />
        <div className="my-account clearfix">
          <MyAccountHeader user={user} title="My Orders" />
          <div className="my-account__content">
            <div className="my-account__orders clearfix">
              {isFetchingOrders ? <LoadingSpinner /> : this.renderOrders()}
            </div>
          </div>
        </div>
      </>
    );
  }

});

export default OrdersRoute;
