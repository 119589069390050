export const SET_REHYDRATED = 'SET_REHYDRATED';

export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

/*
 * Product Flow
 */

export const SET_PRODUCT_SKU = 'SET_PRODUCT_SKU';
export const SET_PRODUCT_SKU_WITH_VARIANTS = 'SET_PRODUCT_SKU_WITH_VARIANTS';
export const SET_FLOW_PROPERTIES = 'SET_FLOW_PROPERTIES';
export const SET_FLOW_LINE_ITEM_VARIANTS = 'SET_FLOW_LINE_ITEM_VARIANTS';
export const SET_FLOW_LINE_ITEM_QUANTITY = 'SET_FLOW_LINE_ITEM_QUANTITY';
export const ADD_FLOW_LINE_ITEM = 'ADD_FLOW_LINE_ITEM';
export const REMOVE_FLOW_LINE_ITEM = 'REMOVE_FLOW_LINE_ITEM';
export const RESET_FLOW = 'RESET_FLOW';
export const ADD_FLOW_FROM_CART = 'ADD_FLOW_FROM_CART';
export const ADD_FLOW_FROM_ORDER_LINE_ITEM = 'ADD_FLOW_FROM_ORDER_LINE_ITEM';
export const ADD_FLOW_FROM_EDITOR = 'ADD_FLOW_FROM_EDITOR';

export const FETCH_FLOW_PRICING_REQUEST = 'FETCH_FLOW_PRICING_REQUEST';
export const FETCH_FLOW_PRICING_SUCCESS = 'FETCH_FLOW_PRICING_SUCCESS';
export const FETCH_FLOW_PRICING_FAILURE = 'FETCH_FLOW_PRICING_FAILURE';

/*
 * Gift Certificate Flow
 */

export const SET_GIFT_FLOW_PROPERTIES = 'SET_GIFT_FLOW_PROPERTIES';
export const SET_GIFT_FLOW_LINE_ITEM_PROPERTIES = 'SET_GIFT_FLOW_LINE_ITEM_PROPERTIES';
export const RESET_GIFT_FLOW = 'RESET_GIFT_FLOW';
export const ADD_GIFT_FLOW_LINE_ITEM = 'ADD_GIFT_FLOW_LINE_ITEM';
export const REMOVE_GIFT_FLOW_LINE_ITEM = 'REMOVE_GIFT_FLOW_LINE_ITEM';
export const ADD_GIFT_FLOW_FROM_CART = 'ADD_GIFT_FLOW_FROM_CART';

/*
 * Cart
 */

export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const UPDATE_QUANTITY_IN_CART = 'UPDATE_QUANTITY_IN_CART';
export const CLEAR_CART = 'CLEAR_CART';

export const ADD_GIFT_CERTIFICATE_TO_CART = 'ADD_GIFT_CERTIFICATE_TO_CART';
export const REMOVE_GIFT_CERTIFICATE_FROM_CART = 'REMOVE_GIFT_CERTIFICATE_FROM_CART';

export const ADD_MAGIC_CODE_TO_CART = 'ADD_MAGIC_CODE_TO_CART';
export const REMOVE_MAGIC_CODE_FROM_CART = 'REMOVE_MAGIC_CODE_FROM_CART';

export const REMOVE_STORE_CREDIT_FROM_CART = 'REMOVE_STORE_CREDIT_FROM_CART';
export const ADD_STORE_CREDIT_TO_CART = 'ADD_STORE_CREDIT_TO_CART';
export const RESET_STORE_CREDIT_IN_CART = 'RESET_STORE_CREDIT_IN_CART';

export const FETCH_PRICING_REQUEST = 'FETCH_PRICING_REQUEST';
export const FETCH_PRICING_SUCCESS = 'FETCH_PRICING_SUCCESS';
export const FETCH_PRICING_FAILURE = 'FETCH_PRICING_FAILURE';

/*
 * Checkout
 */

export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

/*
 * User
 */

export const SET_LOGIN_NOTE = 'SET_LOGIN_NOTE';
export const SET_LOGIN_REDIRECT = 'SET_LOGIN_REDIRECT';

export const SIGN_OUT_USER = 'SIGN_OUT_USER';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const SIGN_IN_USER_REQUEST = 'SIGN_IN_USER_REQUEST';
export const SIGN_IN_USER_SUCCESS = 'SIGN_IN_USER_SUCCESS';
export const SIGN_IN_USER_FAILURE = 'SIGN_IN_USER_FAILURE';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';


/**
 * Pixel Editor
 */

export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_CANVAS_WIDTH = 'SET_CANVAS_WIDTH';
export const SET_COLOR = 'SET_COLOR';
export const SET_COLOR_BANK = 'SET_COLOR_BANK';
export const SET_PIXEL_WIDTH = 'SET_PIXEL_WIDTH';
export const SET_SHOW_GRID = 'SET_SHOW_GRID';
export const SET_CANVAS_STATE = 'SET_CANVAS_STATE';
export const SET_TEMPLATE_OPTIONS = 'SET_TEMPLATE_OPTIONS';
export const SET_LOADED_DESIGN = 'SET_LOADED_DESIGN';