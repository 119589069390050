import { combineReducers } from 'redux';

import {
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE
} from '../actions';

const orderInitialState = {
  entity: null,
  isFetching: false,
  error: null
};

const order = function (state = orderInitialState, action) {
  switch (action.type) {
    case CREATE_ORDER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };

    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        entity: action.payload,
        isFetching: false,
        error: null
      };

    case CREATE_ORDER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default combineReducers({
  order
});

export function getCheckoutOrder (state) {
  return state.order;
}
