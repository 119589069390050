import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import CheckoutBox from '../checkout/checkout-box';
import { formatPrice } from '../../utils/currency';

const MyAccountCredits = createReactClass({

  propTypes: {
    user: PropTypes.object.isRequired
  },

  getInitialState() {
    return {
      showDepleted: false
    };
  },

  renderCredit(credit) {
    const description = credit.description;
    const amount = formatPrice(credit.amount - credit.amountUsed);
    let note;
    if (credit.type === 'order-refund') {
      note = _.get(credit, 'refund.reason');
    }
    if (credit.type === 'gift-certificate') {
      note = _.get(credit, 'giftCertificate.message');
    }    
    return (<div key={credit.id} className="my-account__user-credit">
      <div className="user-credit__description">
        <div className="user-credit__type">{description}</div>
        <div className="user-credit__amount">{amount} remaining</div>
      </div>
      {note && <div className="user-credit__note">{note}</div>}
    </div>);
  },

  handleShowDepleted(e) {
    e.preventDefault();
    this.setState({
      showDepleted: true
    });
  },

  renderAvailable(credits) {
    const { showDepleted } = this.state;
    if (credits.length === 0 && !showDepleted) {
      return <p>When you have codes or credit available, they will be listed here.</p>
    }
    return credits.map( this.renderCredit );
  },

  renderDepleted(credits) {
    const { showDepleted } = this.state;
    if (credits.length === 0) {
      return false;
    }
    if (showDepleted) {
      return credits.map( this.renderCredit );
    } else {
      return <a href="#show-depleted-credits" onClick={this.handleShowDepleted} 
        className="my-account__show-depleted">Show credits that have been used up?</a>
    }
  },

  render() {
    const { user } = this.props;
    const credits = _.get( user, 'credits', [] );
    const usableCredits = credits.filter(c => c.amountUsed < c.amount);
    const depletedCredits = credits.filter(c => c.amountUsed >= c.amount);
    return ( <CheckoutBox title="Magic Codes &amp; Credits" className="my-account__credits">
      {this.renderAvailable(usableCredits)}
      {this.renderDepleted(depletedCredits)}
    </CheckoutBox>);
  }

});

export default MyAccountCredits;