import _ from 'lodash';

import {
  SET_IS_LOADING,
  SET_CANVAS_WIDTH,
  SET_COLOR,
  SET_COLOR_BANK,
  SET_PIXEL_WIDTH,
  SET_SHOW_GRID,
  SET_CANVAS_STATE,
  SET_TEMPLATE_OPTIONS,
  SET_LOADED_DESIGN
} from './';

export function setIsLoading (isLoading) {
  return {
    type: SET_IS_LOADING,
    payload: isLoading
  };
}

export function setCanvasWidth (canvasWidth) {
  return {
    type: SET_CANVAS_WIDTH,
    payload: canvasWidth
  };
}

export function setColor (color) {
  return {
    type: SET_COLOR,
    payload: color
  };
}

export function setColorBank (colorBank) {
  return {
    type: SET_COLOR_BANK,
    payload: colorBank
  };
}

export function setPixelWidth (pixelWidth) {
  return {
    type: SET_PIXEL_WIDTH,
    payload: pixelWidth
  };
}

export function setShowGrid (showGrid) {
  return {
    type: SET_SHOW_GRID,
    payload: showGrid
  };
}

export function setCanvasState (canvasState) {
  return {
    type: SET_CANVAS_STATE,
    payload: canvasState
  };
}

export function setLoadedDesign (design) {
  return {
    type: SET_LOADED_DESIGN,
    payload: design
  };
}

export function setTemplateOptions (templateOptions) {
  return {
    type: SET_TEMPLATE_OPTIONS,
    payload: templateOptions
  };
}