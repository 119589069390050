import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';

import { removeGiftCertificateFromCart } from '../../actions/cart';

import CartGiftCertificateRow from './cart-gift-certificate-row';

const CartGiftCertificates = createReactClass({
  propTypes: {
    giftCertificates: PropTypes.array.isRequired,
    removeGiftCertificateFromCart: PropTypes.func.isRequired
  },

  handleRemoveClick (uniqueId) {
    if (window.confirm('Are you sure you would like to remove this item from your cart?')) {
      this.props.removeGiftCertificateFromCart({ uniqueId });
    }
  },

  render () {
    const { giftCertificates } = this.props;

    return (
      <div className="cart-gift-certificates">
        {_.map(giftCertificates, (giftCertificate) => {
          return (
            <div key={`parent${giftCertificate.uniqueId}`} className="cart-line-items__group">
              <CartGiftCertificateRow
                giftCertificate={giftCertificate}
                onRemoveClick={_.partial(this.handleRemoveClick, giftCertificate.uniqueId)} />

              <a href="/product/gift-certificates#cards" className="cart-line-items__group__cta">
                <span>Here</span> is how to make your own printable card
              </a>
            </div>
          );
        })}
      </div>
    );
  }
});

function mapStateToProps () {
  return {

  };
}

const mapDispatchToProps = {
  removeGiftCertificateFromCart
};

export default connect(mapStateToProps, mapDispatchToProps)(CartGiftCertificates);
