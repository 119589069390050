import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';

import { removeFlowLineItem, setFlowLineItemQuantity, setFlowLineItemVariants } from '../../actions/flow';
import { shortNameForVariant } from '../../utils/products';

import DualPrice from './dual-price';
import QuantitySelector from './quantity-selector';
import VariantSelector from './variant-selector';
import ViewSizeChart from './view-size-chart';
import UploadThumbnail from './upload-thumbnail';

const ReviewLineItem = createReactClass({
  propTypes: {
    designersName: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired,
    pricing: PropTypes.object,
    product: PropTypes.object.isRequired,
    quantity: PropTypes.number.isRequired,
    uniqueId: PropTypes.string.isRequired,
    variants: PropTypes.array.isRequired,
    upload: PropTypes.object.isRequired,
    userDesign: PropTypes.object,
    removeFlowLineItem: PropTypes.func.isRequired,
    setFlowLineItemQuantity: PropTypes.func.isRequired,
    setFlowLineItemVariants: PropTypes.func.isRequired
  },

  handleDelete () {
    this.props.removeFlowLineItem(this.props.uniqueId);
  },

  handleQuantityChange (quantity) {
    this.props.setFlowLineItemQuantity({ quantity, uniqueId: this.props.uniqueId });
  },

  handleVariantsChange (variants) {
    this.props.setFlowLineItemVariants({ uniqueId: this.props.uniqueId, variants });
  },

  renderVariants () {
    const { userDesign, product } = this.props;
    if (!this.props.editable) {
      return (
        <div className="review-line-item__variants">
          {userDesign 
            ? (<span className="review-line-item__variants__pair text--light">
              {product.name}
              <span className="review-line-item__variants__pair__separator">|</span>
            </span>) 
            : null}
          {_.map(this.props.variants, ({ variant, variantOption }, idx) => (
            <span className="review-line-item__variants__pair text--light" key={variant.id}>
              {shortNameForVariant(variant)} {variantOption.displayValue}

              {idx < (this.props.variants.length - 1) && <span className="review-line-item__variants__pair__separator">|</span>}
            </span>
          ))}

          <div className="review-line-item__variants__pair text--light">
            Designed by: {this.props.designersName}
          </div>
        </div>
      );
    }

    return (
      <div className="review-line-item__variants">
        <VariantSelector
          layout="inline"
          product={this.props.product}
          values={this.props.variants}
          onVariantsChange={this.handleVariantsChange} />
        {this.props.product.variants.length > 0 && <ViewSizeChart sku={this.props.product.sku} />}
      </div>
    );
  },

  render () {
    const { pricing, product, userDesign } = this.props;
    const className = classNames({
      clearfix: true,
      'review-line-item': true,
      [`review-line-item--${product.sku}`]: true,
      'review-line-item--editable': this.props.editable
    });

    const subtotalPrice = _.get(pricing, 'subtotalPrice');
    const finalPrice = _.get(pricing, 'finalPrice');
    const productName = userDesign
      ? userDesign.name
      : product.name;

    return (
      <div className={className}>
        <div className="review-line-item__thumbnail-container">
          <UploadThumbnail upload={this.props.upload} userDesign={userDesign} retries={20} />
        </div>

        <div className="review-line-item__content">
          <div className="review-line-item__title">
            {productName}
            {this.renderVariants()}
          </div>

          <div className="review-line-item__quantity">
            <QuantitySelector quantity={this.props.quantity} onChange={this.handleQuantityChange} />
          </div>
          <div className="review-line-item__price text--bold"><DualPrice subtotalPrice={subtotalPrice} finalPrice={finalPrice} /></div>
          <div className="review-line-item__delete"><i className="icon icon-delete" onClick={this.handleDelete} /></div>
        </div>
      </div>
    );
  }
});

function mapStateToProps () {
  return {

  };
}

const mapDispatchToProps = {
  removeFlowLineItem,
  setFlowLineItemQuantity,
  setFlowLineItemVariants
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewLineItem);
