import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { reduxForm, Field } from 'redux-form';
import {
  combineValidators,
  composeValidators,
  isRequired
} from 'revalidate';
import { isValidEmail } from '../../utils/validators';

import CheckoutBox from '../checkout/checkout-box';
import CheckoutField from '../checkout/checkout-field';

const ForgotPasswordRequest = createReactClass({
  propTypes: {
    requestSent: PropTypes.bool, 
    onSubmit: PropTypes.func.isRequired
  },

  render () {
    const { handleSubmit, onSubmit, requestSent } = this.props;
    if (requestSent) {
      return (
        <CheckoutBox title="Resetting Your Password">
          <p>Please check your inbox for further instructions.</p>
        </CheckoutBox>  
      )
    };
    return (
      <CheckoutBox title="Reset Your Password">
        <form className="forgot-password-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="forgot-password__instructions">
            <p>Please enter your email address.</p>
            <p>If we recognize you, you'll receive an email with a link to pick a new password.</p>
          </div>
          <Field name="email" component={CheckoutField} type="text" className="checkout__input" placeholder="Email" />
          <button className="button primary text--light forgot-password__button" type="submit">Reset Password</button>
        </form>
      </CheckoutBox>
    );
  }
});

const validate = combineValidators({
  'email': composeValidators(
    isRequired,
    isValidEmail
  )('Email')
});

export default reduxForm({
  form: 'forgot-password-request',
  onSubmitFail: () => {
    window.scrollTo(0, 0);
  },
  validate,
  shouldValidate: () => true
})(ForgotPasswordRequest);
