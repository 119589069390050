import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Preview from '../components/editor/preview';
import Canvas from '../components/editor/canvas';
import ColorPalette from '../components/editor/color-palette';
import ColorBankButtons from '../components/editor/color-bank-buttons';
import AddToCart from '../components/editor/add-to-cart';
import SaveDesign from '../components/editor/save-design';
import TemplatePicker from '../components/editor/template-picker';
import Tools from '../components/editor/tools';
import Header from '../components/structure/header';
import Footer from '../components/structure/footer';
import { PainterContext } from '../contexts';

const EditorRoute = ({}) => {
  const painter = useRef();
  const [ template, setTemplate ] = useState();
  const [ showTemplateWarning, setShowTemplateWarning ] = useState(false);
  const cartUniqueId = useSelector(state => state.editor.cartUniqueId);
  const addToCartText = cartUniqueId 
    ? 'Update Design'
    : 'Make It Real';
  return (
    <PainterContext.Provider value={painter}>
      <Header />

      <div className="editor">

        <div className="header">
          <div className="palette-group">
            <ColorBankButtons prev />
            <ColorPalette template={template} />
            <ColorBankButtons next />
          </div>
        </div>

        <div className="main">
          <div className="canvas-group">
            <Canvas template={template} />
          </div>
          
          <div className="preview-group">
            <Preview template={template} showTemplateWarning={showTemplateWarning} />
            <TemplatePicker onChange={setTemplate} template={template} />
            <SaveDesign text="Save Design" template={template} setShowTemplateWarning={setShowTemplateWarning} />
            <AddToCart template={template} text={addToCartText} setShowTemplateWarning={setShowTemplateWarning} />
          </div>

          <Tools template={template} />
        </div>

        <div className="mobile-buttons">
          <AddToCart template={template} text={addToCartText} setShowTemplateWarning={setShowTemplateWarning} />
          <SaveDesign text="Save Design" template={template} setShowTemplateWarning={setShowTemplateWarning} />
        </div>

      </div>

      <Footer />
    </PainterContext.Provider>
  );
};

export default EditorRoute;