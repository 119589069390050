import React from 'react';
import { Button, Wrapper, Menu, MenuItem } from 'react-aria-menubutton';

const FilterDropdown = ({ labelPrefix='', value: selectedValue, options=[], onChange=()=>{} }) => {
  const currentOption = (selectedValue && options.find(option => option[0] === selectedValue)) || options[0];
  return (<Wrapper className="filter-dropdown" onSelection={onChange}>
    <Button className="filter-dropdown__button">{labelPrefix}{currentOption[1]} <i className="icon icon-arrow-a" /></Button>
    <Menu className="filter-dropdown__menu">
      {options.map(([value, label], i) => (<MenuItem 
        key={i} 
        value={value} 
        className="filter-dropdown__menuitem">
          {label}
        </MenuItem>))}
    </Menu>
  </Wrapper>);
};

export default FilterDropdown;