import {
  SET_GIFT_FLOW_PROPERTIES,
  SET_GIFT_FLOW_LINE_ITEM_PROPERTIES,
  RESET_GIFT_FLOW,
  ADD_GIFT_FLOW_LINE_ITEM,
  REMOVE_GIFT_FLOW_LINE_ITEM
} from './';

export function setGiftFlowProperties (properties) {
  return {
    type: SET_GIFT_FLOW_PROPERTIES,
    payload: properties
  };
}

export function resetGiftFlow () {
  return {
    type: RESET_GIFT_FLOW
  };
}

export function addGiftFlowLineItem () {
  return {
    type: ADD_GIFT_FLOW_LINE_ITEM
  };
}

export function removeGiftFlowLineItem (lineItem) {
  return {
    type: REMOVE_GIFT_FLOW_LINE_ITEM,
    payload: {
      uniqueId: lineItem.uniqueId
    }
  };
}

export function setGiftFlowLineItemProperties (lineItem, properties) {
return {
  type: SET_GIFT_FLOW_LINE_ITEM_PROPERTIES,
  payload: {
    uniqueId: lineItem.uniqueId,
    properties
  }
};
}