import React from 'react';
import { Link } from 'react-router-dom';
import { isMobileApp, sendAppMessage } from '../../utils/mobile-app';

const handleClick = (e, { to, title, onClick }) => {
  e.stopPropagation();
  if (onClick) {
    onClick();
  }
  if (to.match(/^\/shop\/.+/)) {
    sendAppMessage('showShop', { to, title });
  }
  if (to.match(/^\/account/)) {
    sendAppMessage('showAccount', { to, title });
  }
  if (to === '/') {
    sendAppMessage('showEditor', { to, title });
  }
  if (to === '/checkout') {
    sendAppMessage('showCheckout', { to, title });
  }
}

const MobileAppLink = ({ children, ...params }) => {
  if (isMobileApp()) {
    return (<div role="link" {...params} onClick={(e) => handleClick(e, params)}>{children}</div>)
  } else {
    return <Link {...params}>{children}</Link>
  }
};

export default MobileAppLink;