import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import MobileLink from '../mobile-app/Link';
import ProfileImage from './profile-image';
import StaticPreview from '../editor/static-preview';
import { useWaitForImage } from '../../utils/hooks';
import { canRenderInBrowser } from '../../utils/image';
import { formatPriceShort } from '../../utils/currency';
import { isMobileApp, sendAppMessage } from '../../utils/mobile-app';
import _ from 'lodash';

const Product = ({ pixelDesign, userProduct, productData, children, linkTo, imageLinkTo, showDesigner, history }) => {
  userProduct = userProduct || pixelDesign.userProduct;
  pixelDesign = pixelDesign || userProduct.pixelDesign;

  let bgStyle = {};
  const mainImage = _.get(userProduct, 'mainImage.upload.thumbnails.small');
  const staticImage = _.get(userProduct, 'upload.thumbnails.small');
  if (mainImage) { 
    const isLoading = useWaitForImage(mainImage);
    const canDisplay = canRenderInBrowser(mainImage);
    bgStyle = isLoading || !canDisplay ? {} : {
      backgroundImage: `url(${mainImage})`
    };
  }

  const goToDesign = to => {
    if (isMobileApp()) {
      sendAppMessage('showDesign', { to, title: userProduct.name });
    } else {
      history.push(to);
    }
  }

  const OptionalLink = ({ to, children, ...props }) => to
    ? (<div role="link" onClick={(e) => {  goToDesign(to); e.stopPropagation(); }} {...props}>{children}</div>)
    : (<div {...props}>{children}</div>);

  const designersName = _.get(userProduct, 'store.name');
  const storeCode = _.get(userProduct, 'store.code');
  const profileImage = _.get(userProduct, 'store.profileImage.thumbnails.profile') || _.get(userProduct, 'store.gravatar');
  const skuInfo = (productData && productData.find(product => product.id === pixelDesign.productId)) || {};

return (<OptionalLink to={linkTo} className="user-product">
    <OptionalLink to={imageLinkTo} className="image" style={bgStyle}>
      {!mainImage && <StaticPreview design={pixelDesign} image={staticImage} maxWidth={260} stroke />}
    </OptionalLink>
    <div className="info">
      <div className="name">{userProduct.name}</div>
      <div className="price">{formatPriceShort(skuInfo.price)}</div>
    </div>
    {showDesigner && (<MobileLink to={`/shop/${storeCode}`} className="product-designer" 
      title={`by ${designersName}`} onClick={e => e.stopPropagation()}>
      <ProfileImage image={profileImage} /> by {designersName}
    </MobileLink>)}
    {children}
  </OptionalLink>);
};

export default withRouter(Product);