import React, { useRef, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCanvasWidth, setCanvasState, setIsLoading } from '../../actions/editor';
import { PainterContext } from '../../contexts';
import TemplateOverlay from './template-overlay';
import { getPixelHeight, createPainter, useWindowSize, calcCellWidth } from '../../utils/editor';
import cx from 'classnames';

const Canvas = ({ template }) => {
  
  const container = useRef();
  const painter = useContext(PainterContext);
  const pixelWidth = useSelector(state => state.editor.pixelWidth); 
  const pixelHeight = getPixelHeight(template, pixelWidth);
  const windowSize = useWindowSize();
  const isLoading = useSelector(state => state.editor.isLoading);
  const canvasState = useSelector(state => state.editor.canvasState);
  const canvasWidth = useSelector(state => state.editor.canvasWidth);
  const canvasUpdateCount = useSelector(state => state.editor.canvasUpdateCount);
  const showGrid = useSelector(state => state.editor.showGrid); 
  const dispatch = useDispatch();

  useEffect(() => {
    const cellWidth = calcCellWidth(windowSize, pixelWidth);
    dispatch(setCanvasWidth(cellWidth * pixelWidth));
    if (painter.current) {
      painter.current.width = pixelWidth;
      painter.current.height = pixelHeight;
      painter.current.cellWidth = cellWidth;
      painter.current.cellHeight = cellWidth;
      painter.current.grid = showGrid;
      painter.current.resize();
      painter.current.draw();
    } else {
      painter.current = createPainter({ 
        width: pixelWidth, 
        height: pixelHeight,
        cellWidth,
        cellHeight: cellWidth,
        grid: showGrid
      });
      painter.current.gridColour = 'rgba(219,219,219,0.5)';
      container.current.appendChild(painter.current.dom);
      if (canvasState) {
        try {
          painter.current.thawState(canvasState);
        } catch (e) {
          console.log(e);
        }
      }
      painter.current.init();
    }

    // Update canvas state on every mouse action
    const onChangeCanvas = () => {
      const canvasState = painter.current.freezeState();
      dispatch(setCanvasState(canvasState));
    }
    painter.current.dom.addEventListener('mouseup', onChangeCanvas);
    return () => {
      painter.current.dom.removeEventListener('mouseup', onChangeCanvas);
    };
  }, [ painter, pixelWidth, pixelHeight, windowSize, showGrid ]);



  useEffect(() => {
    if (canvasUpdateCount === 0 && painter.current) {
      // Canvas Reset from Flow or Loaded
      dispatch(setIsLoading(true));
      painter.current.thawState(canvasState);
      painter.current.resize();
      painter.current.draw();
      dispatch(setIsLoading(false));
    }
  }, [ painter, canvasState, canvasUpdateCount ]);

  const showCursor = () => painter.current && (painter.current.showCursor = true);
  const hideCursor = () => painter.current && (painter.current.showCursor = false);
  const containerStyle = {
    minWidth: `${canvasWidth}px`
  };
  
  return (<div className={cx('canvas', { 'show-grid': showGrid })}>
    <div ref={container} style={containerStyle} id="canvas-container" onMouseOver={showCursor} onMouseOut={hideCursor} />
    <TemplateOverlay template={template} canvasWidth={canvasWidth} />
    {isLoading && (<div className="loading-overlay">
      <i className="fa fa-spinner fa-pulse fa-3x" />
    </div>)}
  </div>);
};

export default Canvas;