import React from 'react';

const CartEmpty = () => {
  return (
    <div>
      <div className="cart-empty clearfix">
        <div className="cart-empty__text">
          Your cart seems sad and lonely! Let's add something to it!
        </div>

        <a href="/" className="button primary">Make something cool!</a>
      </div>

      <div className="cart-empty__icon" />
    </div>
  );
};

export default CartEmpty;
