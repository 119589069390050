import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { reduxForm, Field } from 'redux-form';
import CheckoutBox from '../checkout/checkout-box';
import CheckoutField from '../checkout/checkout-field';
import {
  combineValidators,
  composeValidators,
  isRequired
} from 'revalidate';
import { isValidEmail } from '../../utils/validators';

const MyAccountInfo = createReactClass({

  propTypes: {
    user: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired
  },

  componentWillMount() {
    const { user, initialize } = this.props;
    initialize({
      name: user.name,
      email: user.email
    })
  },

  componentWillReceiveProps(nextProps) {
    const { user, initialize } = this.props;
    if (user.id !== nextProps.user.id) {
      initialize({
        name: user.name,
        email: user.email
      })
    }
  },
  
  render() {
    const { handleSubmit } = this.props;
    return (
      <CheckoutBox title="My Info">
        <form className="my-account-info-form" onSubmit={handleSubmit(this.props.onSubmit)}>
          <Field name="name" component={CheckoutField} type="text" className="checkout__input my-account__name" placeholder="First &amp; Last Name" />

          <Field name="email" component={CheckoutField} type="text" className="checkout__input my-email" placeholder="Email" />

          <button className="button primary text--light" type="submit">Save</button>
        </form>
      </CheckoutBox>
    );
  }

});

const validate = combineValidators({
  'name': isRequired('Name'),
  'email': composeValidators(
    isRequired,
    isValidEmail
  )('Email')
});

export default reduxForm({
  form: 'my-account-info',
  validate,
  shouldValidate: () => true
})(MyAccountInfo);
