import React, { useState } from 'react';
import { useWaitForImage } from '../../utils/hooks';
import { canRenderInBrowser } from '../../utils/image';
import Thumbnail from '../user-store/product-image-thumbnail';
import StaticPreview from '../editor/static-preview';
import _ from 'lodash';

const ProductImages = ({ product, children }) => {
  const thumbnails = product.images && product.images.length > 0
    ? _.sortBy([ product, ...product.images ], image => image.imageOrder)
    : [];
  const [ image, setImage ] = useState(thumbnails[0] || product);
  const loaderImage = (<i className="fa fa-spin fa-circle-o-notch" />);
  const placeholderImage = (<i className="fa fa-picture-o" />);

  const BigImage = () => {
    if (image.pixelDesignId) {
      return (<div className="user-store__product-image">
        <StaticPreview design={image.pixelDesign} maxWidth={400} stroke />
      </div>);
    }
    const imageUrl = _.get(image, 'upload.thumbnails.large') || _.get(image, 'upload.thumbnails.web') || _.get(image, 'upload.url')
    const canDisplay = canRenderInBrowser(imageUrl);
    const isLoading = useWaitForImage(imageUrl);
    const style = isLoading || !canDisplay ? {} : {
      backgroundImage: `url(${imageUrl})`
    };
    return (<div className="user-store__product-image" style={style}>
      {isLoading ? loaderImage : children}
      {canDisplay ? null : placeholderImage}
    </div>);
  };
 
  return (<div className="user-store__product-images">
    <BigImage />
    <div className="user-store__product-thumbnails">
      {thumbnails.map((image, index) => {
        return (<Thumbnail image={image} design={product.pixelDesign} key={`image${index}`} onClick={() => setImage(image)}/>);
      })}
    </div>
  </div>);
};

export default ProductImages;