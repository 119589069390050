import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const ErrorBox = ({ title, errors, info, onClick=()=>{} }) => {
  if (errors.length === 0) {
    return null;
  }

  return (
    <div className={cx('error-box', { 'info-box': !!info })} onClick={onClick}>
      {title && <h3>{title}</h3>}
      {_.map(errors, function (error, idx) {
        return <p key={`error${idx}`}>{error}</p>;
      })}
    </div>
  );
};

ErrorBox.propTypes = {
  errors: PropTypes.array.isRequired,
  title: PropTypes.string
};

export default ErrorBox;
