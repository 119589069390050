import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { reduxForm } from 'redux-form';
import {
  combineValidators,
  composeValidators,
  isRequired,
  hasLengthGreaterThan,
  matchesField
} from 'revalidate';

import { isValidEmail } from '../../utils/validators';
import CheckoutAuthentication from './checkout-authentication';

const AuthenticationForm = createReactClass({
  propTypes: {
    from: PropTypes.string.isRequired,
    title: PropTypes.string,
    buttonLabel: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    authSegmentIndex: PropTypes.number.isRequired,
    onAuthTypeChange: PropTypes.func.isRequired
  },

  render () {
    const { title, buttonLabel, from, handleSubmit, authSegmentIndex, onAuthTypeChange } = this.props;
    const label = buttonLabel || (authSegmentIndex === 0 ? 'Create Account' : 'Sign In');
    return (
      <form className="sign-in-form" onSubmit={handleSubmit}>

        <CheckoutAuthentication title={title || label}
          authSegmentIndex={authSegmentIndex} 
          onAuthTypeChange={onAuthTypeChange} 
          from={from}>

          <button className="button primary text--light sign-in__button" type="submit">{label}</button>

        </CheckoutAuthentication>

      </form>
    );
  }
});

const validate = combineValidators({
  'signUp.name': isRequired('Name'),
  'signUp.email': composeValidators(
    isRequired,
    isValidEmail
  )('Email'),
  'signUp.password': composeValidators(
    isRequired,
    hasLengthGreaterThan(7)
  )('Password'),
  'signUp.passwordConfirmation': matchesField('signUp.password')({
    message: 'Passwords do not match'
  }),

  'signIn.email': isRequired('Email'),
  'signIn.password': isRequired('Password'),
});

export default reduxForm({
  form: 'sign-in',
  onSubmitFail: () => {
    window.scrollTo(0, 0);
  },
  validate,
  shouldValidate: () => true
})(AuthenticationForm);
