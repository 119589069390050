import _ from 'lodash';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { withRouter } from 'react-router';

const ScrollToTop = createReactClass({
  propTypes: {
    location: PropTypes.object.isRequired,
    children: PropTypes.node
  },

  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location && _.get(this.props, 'history.action') === 'PUSH') {
      window.scrollTo(0, 0);
    }
  },

  render () {
    return null;
  }
});

export default withRouter(ScrollToTop);
