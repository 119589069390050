import _ from 'lodash';
import axios from 'axios';
import { combineReducers } from 'redux';

import {
  ADD_PRODUCT_TO_CART,
  REMOVE_PRODUCT_FROM_CART,
  UPDATE_QUANTITY_IN_CART,
  CLEAR_CART,
  ADD_GIFT_CERTIFICATE_TO_CART,
  REMOVE_GIFT_CERTIFICATE_FROM_CART,
  ADD_MAGIC_CODE_TO_CART,
  REMOVE_MAGIC_CODE_FROM_CART,
  REMOVE_STORE_CREDIT_FROM_CART,
  ADD_STORE_CREDIT_TO_CART,
  RESET_STORE_CREDIT_IN_CART,
  FETCH_PRICING_REQUEST,
  FETCH_PRICING_SUCCESS,
  FETCH_PRICING_FAILURE
} from '../actions';
import { generateUUID } from '../utils/uuid';

const lineItemsInitialState = [];
const giftCertificatesInitialState = [];
const magicCodesInitialState = [];
const unappliedStoreCreditInitialState = [];
const pricingInitialState = {
  entity: null,
  isFetching: false,
  error: null
};

const giftCertificates = function (state = giftCertificatesInitialState, action) {
  switch (action.type) {
    case ADD_GIFT_CERTIFICATE_TO_CART: {
      return [
        ...state,
        {
          ...action.payload,
          uniqueId: generateUUID()
        }
      ];
    }

    case REMOVE_GIFT_CERTIFICATE_FROM_CART: {
      return _.filter(state, function (giftCertificate) {
        return giftCertificate.uniqueId !== action.payload.uniqueId;
      });
    }

    case CLEAR_CART: {
      return giftCertificatesInitialState;
    }

    default:
      return state;
  }
};

const lineItems = function (state = lineItemsInitialState, action) {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART: {
      const merged = [];
      let hasExisting = false;

      _.forEach(state, function (lineItem) {
        if (action.payload.uniqueId === lineItem.uniqueId) {
          hasExisting = true;

          merged.push({
            ...lineItem,
            ...action.payload
          });
        } else {
          merged.push(lineItem);
        }
      });

      if (!hasExisting) {
        merged.push(action.payload);
      }

      return merged;
    }

    case REMOVE_PRODUCT_FROM_CART: {
      return _.filter(state, function (lineItem) {
        return lineItem.uniqueId !== action.payload.uniqueId && lineItem.parentUniqueId !== action.payload.uniqueId;
      });
    }

    case UPDATE_QUANTITY_IN_CART: {
      const { quantity, uniqueId } = action.payload;

      return _.map(state, function (lineItem) {
        const newLineItem = {
          ...lineItem
        };

        if (newLineItem.uniqueId === uniqueId) {
          newLineItem.quantity = quantity;
        }

        return newLineItem;
      });
    }

    case CLEAR_CART: {
      return lineItemsInitialState;
    }

    default:
      return state;
  }
};

const magicCodes = function (state = magicCodesInitialState, action) {
  switch (action.type) {
    case ADD_MAGIC_CODE_TO_CART: {
      const newCodes = [...state];

      if (!_.includes(newCodes, action.payload)) {
        newCodes.push(action.payload);
      }

      return newCodes;
    }

    case REMOVE_MAGIC_CODE_FROM_CART: {
      return _.filter(state, function (magicCode) {
        return String(magicCode).toLowerCase() !== String(action.payload).toLowerCase();
      });
    }

    case CLEAR_CART: {
      return magicCodesInitialState;
    }

    default:
      return state;
  }
};

const unappliedStoreCredit = function (state = unappliedStoreCreditInitialState, action) {
  switch (action.type) {
    
    case REMOVE_STORE_CREDIT_FROM_CART: {
      const newIds = [...state];
      if (!_.includes(newIds, action.payload)) {
        newIds.push(action.payload);
      }
      return newIds;
    }
    
    case ADD_STORE_CREDIT_TO_CART: {
      return _.filter(state, function (creditId) {
        return creditId !== action.payload;
      });
    }

    case RESET_STORE_CREDIT_IN_CART:
    case CLEAR_CART: {
      return unappliedStoreCreditInitialState;
    }

    default:
      return state;
  }
};

const pricing = function (state = pricingInitialState, action) {
  switch (action.type) {
    case FETCH_PRICING_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };

    case FETCH_PRICING_SUCCESS:
      return {
        ...state,
        entity: action.payload,
        isFetching: false,
        error: null
      };

    case FETCH_PRICING_FAILURE:
      if (axios.isCancel(action.payload)) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default combineReducers({
  giftCertificates,
  lineItems,
  magicCodes,
  unappliedStoreCredit,
  pricing
});

export function getGiftCertificates (state) {
  return state.giftCertificates;
}

export function getLineItems (state) {
  return state.lineItems;
}

export function getMagicCodes (state) {
  return state.magicCodes;
}

export function getUnappliedStoreCredit (state) {
  return state.unappliedStoreCredit;
}

export function getPricing (state) {
  return state.pricing;
}
