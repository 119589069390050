import React from 'react';
import { connect } from 'react-redux';
import { signOut } from '../actions/user'; 
import { Redirect } from 'react-router-dom';

class LogOut extends React.Component {

  componentDidMount() {
    this.props.signOut();
  }

  render() {
    return <Redirect to="/sign-in" />
  }

};

const mapDispatchToProps = {
  signOut
};

export default connect(null, mapDispatchToProps)(LogOut);