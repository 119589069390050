import _, { template } from 'lodash';
import axios from 'axios';
import { combineReducers } from 'redux';

import {
  SET_PRODUCT_SKU,
  SET_PRODUCT_SKU_WITH_VARIANTS,
  SET_FLOW_PROPERTIES,
  SET_FLOW_LINE_ITEM_VARIANTS,
  SET_FLOW_LINE_ITEM_QUANTITY,
  ADD_FLOW_LINE_ITEM,
  REMOVE_FLOW_LINE_ITEM,
  RESET_FLOW,
  ADD_FLOW_FROM_CART,
  ADD_FLOW_FROM_ORDER_LINE_ITEM,
  ADD_FLOW_FROM_EDITOR,
  FETCH_FLOW_PRICING_REQUEST,
  FETCH_FLOW_PRICING_SUCCESS,
  FETCH_FLOW_PRICING_FAILURE
} from '../actions';
import { getProductBySku } from '../reducers/products';
import { generateUUID } from '../utils/uuid';

const getInitialState = () => ({
  designersName: '',
  lineItems: [{
    quantity: 1,
    sku: 'PTS01',
    uniqueId: generateUUID(),
    parentUniqueId: null,
    variants: []
  }],
  upload: null,
  userDesign: null,
  canvasState: null
});

const pricingInitialState = {
  entity: null,
  isFetching: false,
  error: null
};

const data = function (state = getInitialState(), action) {
  switch (action.type) {
    case SET_PRODUCT_SKU:
      return {
        ...state,
        lineItems: [{
          quantity: 1,
          sku: action.payload.sku,
          uniqueId: generateUUID(),
          variants: [],
          userDesign: null
        }]
      };

    case SET_PRODUCT_SKU_WITH_VARIANTS:
      return {
        ...state,
        lineItems: [{
          quantity: 1,
          sku: action.payload.sku,
          uniqueId: generateUUID(),
          variants: action.payload.variants,
          userDesign: null
        }]
      };

    case SET_FLOW_PROPERTIES:
      return {
        ...state,
        ...action.payload
      };

    case SET_FLOW_LINE_ITEM_VARIANTS: {
      const newState = {
        ...state,
        lineItems: []
      };

      _.forEach(state.lineItems, function (lineItem) {
        if (lineItem.uniqueId === action.payload.uniqueId) {
          newState.lineItems.push({
            ...lineItem,
            variants: action.payload.variants
          });
        } else {
          newState.lineItems.push(lineItem);
        }
      });

      return newState;
    }

    case SET_FLOW_LINE_ITEM_QUANTITY: {
      const newState = {
        ...state,
        lineItems: []
      };

      _.forEach(state.lineItems, function (lineItem) {
        if (lineItem.uniqueId === action.payload.uniqueId) {
          newState.lineItems.push({
            ...lineItem,
            quantity: action.payload.quantity
          });
        } else {
          newState.lineItems.push(lineItem);
        }
      });

      return newState;
    }

    case ADD_FLOW_LINE_ITEM:
      return {
        ...state,
        lineItems: [
          ...state.lineItems,
          {
            quantity: 1,
            parentUniqueId: state.lineItems[0].uniqueId,
            sku: action.payload.sku,
            uniqueId: generateUUID(),
            variants: []
          }
        ]
      };

    case REMOVE_FLOW_LINE_ITEM:
      return {
        ...state,
        lineItems: _.filter(state.lineItems, function (lineItem) {
          return lineItem.uniqueId !== action.payload.uniqueId;
        })
      };

    case RESET_FLOW:
      return {
        ...getInitialState()
      };

    case ADD_FLOW_FROM_CART: {
      const { lineItem, childLineItems } = action.payload;
      return {
        designersName: lineItem.designersName,
        userDesign: lineItem.userDesign,
        lineItems: [{
          quantity: lineItem.quantity,
          sku: lineItem.product.sku,
          uniqueId: lineItem.uniqueId,
          parentUniqueId: null,
          variants: lineItem.variants
        }, ...mapCartChildLineItems(childLineItems)],
        upload: lineItem.upload,
        canvasState: _.cloneDeep(lineItem.canvasState)
      };
    }

    case ADD_FLOW_FROM_EDITOR: {
      const { canvasState, upload, templateOptions, lineItem={}, childLineItems } = action.payload;
      return {
        designersName: templateOptions.designersName,
        userDesign: null,
        lineItems: [{
          quantity: lineItem.quantity || 1,
          sku: templateOptions.sku,
          uniqueId: lineItem.uniqueId || generateUUID(),
          parentUniqueId: null,
          variants: templateOptions.variants
        }, ...mapCartChildLineItems(childLineItems)],
        upload: upload,
        canvasState: _.cloneDeep(canvasState)
      };
    }


    case ADD_FLOW_FROM_ORDER_LINE_ITEM: {
      const { lineItem } = action.payload;
      return {
        designersName: lineItem.designersName,
        userDesign: false,
        lineItems: [{
          quantity: 1,
          sku: lineItem.productSku,
          uniqueId: generateUUID(),
          parentUniqueId: null,
          variants: lineItem.variants.map(variant => {
            return {
              variantId: variant.productVariantId,
              variantOptionId: variant.productVariantOptionId
            };
          })
        }],
        upload: lineItem.upload
      };
    }

    default:
      return state;
  }
};

const pricing = function (state = pricingInitialState, action) {
  switch (action.type) {
    case FETCH_FLOW_PRICING_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };

    case FETCH_FLOW_PRICING_SUCCESS:
      return {
        ...state,
        entity: action.payload,
        isFetching: false,
        error: null
      };

    case FETCH_FLOW_PRICING_FAILURE:
      if (axios.isCancel(action.payload)) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case RESET_FLOW:
      return {
        ...pricingInitialState
      };

    default:
      return state;
  }
};

export default combineReducers({
  data,
  pricing
});

function mapCartChildLineItems(childLineItems) {
  return _.map(childLineItems, ({ quantity, product, uniqueId, parentUniqueId, variants }) => {
    return {
      quantity,
      sku: product.sku,
      uniqueId,
      parentUniqueId,
      variants
    };
  });
}

export function getFlowData (state) {
  return state.data;
}

export function getParentProduct (state, products) {
  const { lineItems } = getFlowData(state);

  return getProductBySku(products, lineItems[0].sku);
}

export function getSkuList (products) {
  return products.list.entities.map(product => product.sku);
}

export function getFlowPricing (state) {
  return state.pricing;
}
