import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { shortNameForVariant } from '../../utils/products';

import DualPrice from '../products/dual-price';
import QuantitySelector from '../products/quantity-selector';
import UploadThumbnail from '../products/upload-thumbnail';

const CartLineItemRow = ({ lineItem, onEditClick, onQuantityChange, onRemoveClick }) => {
  const { designersName, quantity, product, variants, userDesign } = lineItem;
  const subtotalPrice = _.get(lineItem, 'pricing.subtotalPrice');
  const finalPrice = _.get(lineItem, 'pricing.finalPrice');
  const productName = userDesign
  ? userDesign.name
  : product.name;

  const className = classNames({
    clearfix: true,
    'cart-line-item-row': true,
    'review-line-item': true,
    [`review-line-item--${product.sku}`]: true,
    'review-line-item--editable': true,
    'cart-line-item-row--editable': product.variants.length > 0
  });

  return (
    <div className={className}>
      <div className="review-line-item__thumbnail-container">
        <UploadThumbnail upload={lineItem.upload} userDesign={userDesign} retries={20} />
      </div>

      <div className="review-line-item__content">
        <div className="review-line-item__title">
          {productName} <i className="icon icon-edit" onClick={onEditClick} />

          <div className="review-line-item__variants">
          {userDesign 
            ? (<span className="review-line-item__variants__pair text--light">
              {product.name}
              <span className="review-line-item__variants__pair__separator">|</span>
            </span>) 
            : null}
            {_.map(variants, ({ variant, variantOption }, idx) => (
              <span className="review-line-item__variants__pair text--light" key={variant.id}>
                {shortNameForVariant(variant)} {variantOption.displayValue}
                {idx < (variants.length - 1) && <span className="review-line-item__variants__pair__separator">|</span>}
              </span>
            ))}

            {!lineItem.parentUniqueId && <div className="review-line-item__variants__pair text--light">
              Designed by: {designersName}
            </div>}
          </div>
        </div>

        <div className="review-line-item__quantity">
          <QuantitySelector quantity={quantity} onChange={onQuantityChange} />
        </div>
        <div className="review-line-item__price text--bold"><DualPrice subtotalPrice={subtotalPrice} finalPrice={finalPrice} /></div>
        <div className="review-line-item__delete"><i className="icon icon-delete" onClick={onRemoveClick} /></div>
      </div>
    </div>
  );
};

CartLineItemRow.propTypes = {
  lineItem: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired
};

export default CartLineItemRow;
