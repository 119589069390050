import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Wrapper, Menu, MenuItem } from 'react-aria-menubutton';
import { getMyAccountLinks } from '../../utils/users';
import { basename } from 'path';

const MyAccountHeader = ({ 
  user,
  title, 
  location: { pathname },
  history,
  children
}) => {
  const links = getMyAccountLinks(user);
  const basePath = basename(pathname); 
  const activeLink = links.find(link => (link.value === basePath) || (link.pattern && link.pattern.test(pathname))) || links[0];
  const isIndex = basePath === 'account';
  const isMyProduct = pathname.indexOf('/my-shop/product/') !== -1;

  const handleSelection = (link) => {
    if (link === 'logout') {
      history.push('/account/log-out');
    } else {
      history.push(`/account/${link.value}`);
    }
  };

  return (<div className="my-account__header">
    {children
      ? children
      : (<h2 className="header">{title || activeLink.label}</h2>)}
    <div className="my-account__breadcrumbs">
      {!isIndex && <Link to="/account">My Account</Link>}
      {isMyProduct && <Link to="/account/my-shop">My Shop</Link>}
      {!isIndex && <Wrapper className="my-account__dropdown-wrapper" onSelection={handleSelection}>
        <Button><i className="icon icon-arrow-a" /> {activeLink.label}</Button>
        <Menu className="my-account__dropdown-menu">
          {links.filter(link => !link.hideInMenu).map(link => (
            <MenuItem key={link.value} value={link}>{link.label}</MenuItem>
          ))}
          <MenuItem value="logout">Log Out</MenuItem>
        </Menu>
      </Wrapper>}
    </div>
  </div>);
};

export default withRouter(MyAccountHeader);