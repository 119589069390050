import { EXPORT_WIDTH, PALETTE_COLORS } from '../../config';
import { createCanvas, createImage, getTemplate } from './';
import { getExportHeight } from './dimensions';
import { uploadBlob } from '../../clients/moose';

export const applyDesign = (designData, canvas) => {
  const { width, height, painting } = designData;
  const pixelSize = Math.round(canvas.width / width);
  const ctx = canvas.getContext('2d');
  for (let x=0;x<width;x++) {
    for (let y=0;y<height;y++) {
      ctx.fillStyle = PALETTE_COLORS[ painting[y][x] ];
      ctx.fillRect(x * pixelSize, y * pixelSize, pixelSize, pixelSize);
    }
  }
}

export const applyTemplate = (template, canvas) => {
  return new Promise(async (resolve) => {
    if (template && template.filename) {
      let svgPath = template.svgPath;
      if (!svgPath) { // svgPath might not be preloaded
        const { default: importedSvgPath } = await import(`../../assets/templates/${template.filename}`);
        svgPath = importedSvgPath;
      }
      const templateOverlay = createImage(svgPath, canvas.width);
      templateOverlay.onload = () => {
        const ctx = canvas.getContext('2d');
        ctx.globalCompositeOperation = 'destination-in';
        ctx.drawImage(templateOverlay, 0, 0, canvas.width, canvas.height);
        ctx.globalCompositeOperation = 'source-over';
        resolve();
      }      
    } else {
      resolve();
    }
  });
};

export const createBlob = (designData, template) => {
  return new Promise(async (resolve) => {
    const exportSize = [ EXPORT_WIDTH, getExportHeight(template) ];
    const canvas = createCanvas(exportSize[0], exportSize[1]);
    applyDesign(designData, canvas);
    await applyTemplate(template, canvas);
    canvas.toBlob(function (blob) {
      resolve(blob);
    });
  });
};

export const updateStaticImage = (pixelDesign) => {
  const { product: { sku }, variants, data } = pixelDesign;
    const template = getTemplate(sku, variants);
    createBlob(data, template).then(async (blob) => {
      await uploadBlob(blob, {
        pixelDesignId: pixelDesign.id,
        uploadType: 'userProduct'
      });
    });
};