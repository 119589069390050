import { SET_REHYDRATED } from '../actions';

const initialState = {
  rehydrated: false
};

export default function app (state = initialState, action) {
  switch (action.type) {
    case SET_REHYDRATED:
      return {
        ...state,
        rehydrated: true
      };

    default:
      return state;
  }
}
