import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { fetchUserIfNeeded } from '../../actions/user';
import { toast, updateToast } from '../../utils/toast';
import Moose from '../../clients/moose';

import MyAccountAddresses from '../../components/my-account/my-account-addresses';
import Header from '../../components/structure/header';
import MyAccountHeader from '../../components/my-account/my-account-header';
import BackgroundOverlay from '../../components/structure/background-overlay';

const AddressesRoute = createReactClass({

  propTypes: {
    user: PropTypes.object.isRequired,
    fetchUserIfNeeded: PropTypes.func.isRequired
  },

  getInitialState() {
    return {
      updateCount: 0
    };
  },

  handleUpdateSavedShippingAddress(address) {
    const toastId = toast('Updating your address info..');
    Moose.updateSavedShippingAddress(address).then(res => {
      this.props.fetchUserIfNeeded().then(() => {
        updateToast(toastId, 'Your address has been updated!');
      });
    });
  },

  handleDeleteSavedShippingAddress() {
    const toastId = toast('Updating your address info..');
    if (window.confirm('Are you sure you want to clear your saved shipping address info?')) {
      Moose.deleteSavedShippingAddress().then(res => {
        this.props.fetchUserIfNeeded().then(() => {
          // Needed to clear form.
          this.setState({ updateCount: this.state.updateCount + 1 });
          updateToast(toastId, 'Your address has been removed!');
        });;
      });
    }
  },

  
  render() {
    const { user } = this.props;
    const initialValues = user.savedShippingAddress || {};
    return (
      <>
        <Header />
        <BackgroundOverlay />
        <div className="my-account clearfix">
          <MyAccountHeader user={user} />
          <div className="my-account__content">
            <div className="my-account__addresses clearfix">
              <MyAccountAddresses key={this.state.updateCount} onSubmit={this.handleUpdateSavedShippingAddress} onDelete={this.handleDeleteSavedShippingAddress} initialValues={initialValues} />
            </div>
          </div>
        </div>
      </>
    );
  }

});

function mapStateToProps (state) {
  return {
  };
}

const mapDispatchToProps = {
  fetchUserIfNeeded
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressesRoute);