import templates from '../../config/svgTemplates.json';

export const allVariantsSelected = (product, variants=[]) => {
  return product && product.variants.length === variants.length;
}

export const getTemplate = (sku, variants=[]) => {
  return templates
    .filter(template => template.sku === sku)
    .find(template => {
      if (!template.variants) {
        return true; // Template works for all variants
      } else {
        let isMatch = true;
        template.variants.forEach(templateVariant => {
          const optionMatch = variants.some(variant => 
            variant.variantId === templateVariant.variantId && 
            templateVariant.variantOptionIds.includes(variant.variantOptionId)
          );
          if (!optionMatch) {
            isMatch = false;
          }
        });
        return isMatch;
      }
    });
}

export const getOptionsName = (product, variants=[]) => {
  if (!product) return '';
  const options = variants.map(v => {
    const variant = product.variants.find(pv => pv.id === v.variantId);
    if (variant) {
      const option = variant.options.find(vo => vo.id === v.variantOptionId);
      if (option) {
        return option.displayValue;
      }
    }
  });
  return options.filter(option => !!option).join(' - ');
}