import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const SegmentedControl = createReactClass({
  propTypes: {
    segments: PropTypes.array.isRequired,
    selectedIndex: PropTypes.number.isRequired,
    onSegmentClick: PropTypes.func.isRequired
  },

  handleClick (index) {
    this.props.onSegmentClick(index);
  },

  renderButtons () {
    return _.map(this.props.segments, (label, index) => {
      const className = classNames({
        'button--reset': true,
        'segmented-control__button': true,
        'segmented-control__button--active': this.props.selectedIndex === index,
        'text--light': true
      });

      return (
        <button key={`segment-${index}`} className={className} onClick={_.partial(this.handleClick, index)} type="button">{label}</button>
      );
    });
  },

  render () {
    return (
      <div className="segmented-control">
        {this.renderButtons()}
      </div>
    );
  }
});

export default SegmentedControl;
