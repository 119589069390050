import React from 'react';
import createReactClass from 'create-react-class';

const CartShippingNote = createReactClass({

  propTypes: {
  },

  render () {
    return (
      <div className="cart-shipping-note">
        <h4>
          <div className="cart-plane__icon" />
          <span>Shipping Note</span>
        </h4>
        <p>
          There may be additional fees for international customs/duty taxes when shipping outside of the United States of America. These are variable and something we're not able to predict or account for ahead of time and are separate from our shipping costs.
        </p>
      </div>
    );
  }

});

export default CartShippingNote;
