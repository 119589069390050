import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { getCurrentUser } from '../reducers/user';

import { Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { addFlowFromOrderLineItem } from '../actions/flow';

import LoadingSpinner from '../components/meta/loading-spinner';
import IndexRoute from './my-account/index-route';
import OrdersRoute from './my-account/orders-route';
import DetailsRoute from './my-account/details-route';
import PaymentsRoute from './my-account/payments-route';
import AddressesRoute from './my-account/addresses-route';
import MyStatsRoute from './my-account/my-stats-route';
import MyStoreRoute from './my-account/my-store-route';
import MyProductRoute from './my-account/my-product-route';

const MyAccountRoute = createReactClass({
  propTypes: {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    user: PropTypes.object,
    isFetchingUser: PropTypes.bool.isRequired,
    addFlowFromOrderLineItem: PropTypes.func.isRequired
  },

  handleReorderClick(lineItem) {
    const { addFlowFromOrderLineItem, history } = this.props;
    addFlowFromOrderLineItem(lineItem);
    history.push('/product/upload');
  },

  render () {
    const { user, isFetchingUser } = this.props;
    
    if (!user && isFetchingUser) {
      return <LoadingSpinner />;
    }
    if (!user) {
      return <Redirect to="/sign-in" />;
    }
    return (
      <div className="my-account__container">
        <Switch>
          <Route path="/account" exact={true} render={() => <IndexRoute user={user} />} />
          <Route path="/account/orders" render={() => <OrdersRoute user={user} 
            onReorderClick={this.handleReorderClick} />} />
          <Route path="/account/details" render={() => <DetailsRoute user={user} />} />
          <Route path="/account/payments" render={() => <PaymentsRoute user={user} />} />
          <Route path="/account/addresses" render={() => <AddressesRoute user={user} />} />
          <Route path="/account/affiliate" render={() => <Redirect to="/account/my-stats" />} />
          <Route path="/account/my-shop/product/:productId" render={(matchProps) => 
            <MyProductRoute user={user} {...matchProps} />
          } />
          <Route path="/account/my-stats" render={(matchProps) => <MyStatsRoute user={user} />} />
          <Route path="/account/my-store" render={(props) => <Redirect to="/account/my-shop" />} />
          <Route path="/account/my-shop" render={() => <MyStoreRoute user={user} />} />
        </Switch>
        <ToastContainer />
      </div>
    );
  }
});

function mapStateToProps (state) {
  const { entity: user, isFetching: isFetchingUser } = getCurrentUser(state.user);
  return {
    user,
    isFetchingUser
  };
}

const mapDispatchToProps = {
  addFlowFromOrderLineItem
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountRoute);
