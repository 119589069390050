import React, { useState } from 'react';
import { useWaitForImage } from '../../utils/hooks';
import { canRenderInBrowser } from '../../utils/image';
import UploadInput from '../products/upload-input';
import Thumbnail from '../user-store/product-image-thumbnail';
import StaticPreview from '../editor/static-preview';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import _ from 'lodash';

const SortableHandle = sortableHandle(() => <i className="fa fa-arrows" />);

const SortableContainer = sortableContainer(({ children }) => {
  return (<div className="user-store__product-thumbnails">{children}</div>);
});

const SortableThumbnail = sortableElement(({ image, children, design, isMain }) => {
  return (<div>
    <Thumbnail image={image} design={design}>{children}</Thumbnail>
    {isMain && <div  className="user-store__product-thumbnail-label">Main Image</div>}
  </div>);
});

const MyProductImages = ({ design, design: { userProduct }, children, onAddImage, onRemoveImage, onReorderImages }) => {
  const thumbnails = _.sortBy([ userProduct, ...(userProduct.images || []) ], image => image.imageOrder);
  const [ image, setImage ] = useState(thumbnails[0] || userProduct);
  
  const allowUploads = thumbnails.length < 3;
  const loaderImage = (<i className="fa fa-spin fa-circle-o-notch" />);
  const placeholderImage = (<i className="fa fa-picture-o" />);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newThumbnails = Array.from(thumbnails);
    const [ item ] = newThumbnails.splice(oldIndex, 1);
    newThumbnails.splice(newIndex, 0, item);
    const newOrder = newThumbnails.map(image => image.userProductId ? image.id : 'main');
    onReorderImages(newOrder);
  };

  const BigImage = () => {
    if (image.pixelDesignId) {
      return (<div className="user-store__product-image">
        <StaticPreview design={design} maxWidth={400} stroke />
      </div>);
    }
    const imageUrl = _.get(image, 'upload.thumbnails.large') || _.get(image, 'upload.thumbnails.web') || _.get(image, 'upload.url')
    const canDisplay = canRenderInBrowser(imageUrl);
    const isLoading = useWaitForImage(imageUrl);
    const style = isLoading || !canDisplay ? {} : {
      backgroundImage: `url(${imageUrl})`
    };
    return (<div className="user-store__product-image" style={style}>
      {isLoading ? loaderImage : children}
      {canDisplay ? null : placeholderImage}
    </div>);
  };

  return (<div className="user-store__product-images">
    <BigImage />
    <SortableContainer onSortEnd={onSortEnd} axis="xy" useDragHandle>
      {thumbnails.map((image, index) => {
        const isMain = !image.userProductId; 
        const key = isMain ? 'main' : `image${image.id}`;
        return (<SortableThumbnail image={image} design={design} index={index} key={key} isMain={index === 0}>
          <div className="thumbnail-controls">
            <button onClick={() => setImage(image)}><i className="fa fa-search" /></button>
            <SortableHandle />
            {isMain 
              ? (<i className="fa fa-trash" />)
              : (<button onClick={() => onRemoveImage(image.id)}><i className="fa fa-trash" /></button>)}
          </div>
        </SortableThumbnail>);
      })}
      {allowUploads && <UploadInput 
        minWidth={700}
        minHeight={700}
        uploadType="userProduct"
        render={(props,{ saving }) => {
          return (<div className="user-store__product-thumbnail">
              {saving 
                ? loaderImage
                : (<div className="circle-icon-button">
                    <i className="fa fa-camera" />
                  </div>)}
            </div>);
        }}
        onChange={onAddImage}
      />}
    </SortableContainer>
  </div>);
};

export default MyProductImages;