import {
  SET_GIFT_FLOW_PROPERTIES,
  SET_GIFT_FLOW_LINE_ITEM_PROPERTIES,
  RESET_GIFT_FLOW,
  ADD_GIFT_FLOW_LINE_ITEM,
  REMOVE_GIFT_FLOW_LINE_ITEM
} from '../actions';

import _ from 'lodash';
import { giftOptionsById } from '../utils/gift-options';
import { generateUUID } from '../utils/uuid';

const initialLineItemState = {
  quantities: _.mapValues(giftOptionsById, () => 0),
  amount: 0,
  to: '',
  from: '',
  message: ''
};

const getInitialState = () => ({
  lineItems: [
    { 
      uniqueId: generateUUID(),
      ...initialLineItemState 
    }
  ]
});

function calculateAmountForLineItem ({ quantities }) {
  return _.reduce(quantities, (total, quantity, id) => {
    const price = _.get(giftOptionsById[id], 'price') || 0;
    return total + (quantity * price);
  }, 0);
}

export default function giftFlow (state = getInitialState(), action) {
  switch (action.type) {
    case SET_GIFT_FLOW_PROPERTIES: {
      const newState = {
        ...state,
        ...action.payload
      };
      return newState;
    }

    case RESET_GIFT_FLOW:
      return {
        ...getInitialState()
      };

    case ADD_GIFT_FLOW_LINE_ITEM:
      return {
        ...state,
        lineItems: [
          ...state.lineItems,
          {
            ...initialLineItemState,
            uniqueId: generateUUID()
          }
        ]
      };

    case REMOVE_GIFT_FLOW_LINE_ITEM:
      return {
        ...state,
        lineItems: _.filter(state.lineItems, function (lineItem) {
          return lineItem.uniqueId !== action.payload.uniqueId;
        })
      };

    case SET_GIFT_FLOW_LINE_ITEM_PROPERTIES: {
      const newState = {
        ...state,
        lineItems: []
      };

      _.forEach(state.lineItems, function (lineItem) {
        if (lineItem.uniqueId === action.payload.uniqueId) {
          const newLineItem = {
            ...lineItem,
            ...action.payload.properties
          };
          newLineItem.calculatedAmount = calculateAmountForLineItem(newLineItem);
          newState.lineItems.push(newLineItem);
        } else {
          newState.lineItems.push(lineItem);
        }
      });
      return newState;
    }

    default:
      return state;
  }
}
