import { getUA } from 'react-device-detect';
import { getStoredState } from 'redux-persist';

const appRegex = new RegExp(/PixelThisApp\/(\d+\.\d+\.\d+)/);

export function isMobileApp() {
    return appRegex.test(getUA) && window.ReactNativeWebView;
}

export function sendAppMessage(type, data={}) {
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type, data }));
    }
}

export function rehydrateOnFocusEvent(persistor, config) {
  window.addEventListener('PixelThisApp:focus', rehydrateStoredState, false);
  function rehydrateStoredState(e) {
    getStoredState(config, (err, restoredState) => {
      if (!err) {
        persistor.rehydrate({ 
          restoredState,  
          app: { 
            focusRehydrate: Date.now() 
          }
        });
      }
    })
  }
}
