import React from 'react';
import PresetPriceSelector from './preset-price-selector';
import GiftPriceCalculator from './gift-price-calculator';
import { priceToCents, addDollarSign, typedPriceIsValid } from '../../utils/currency';

const GiftSelectForm = ({ 
  lineItem,
  setGiftFlowLineItemProperties,
  canRemove, 
  onRemoveClick
}) => {

  const {
    to, 
    from, 
    message, 
    typedAmount,
    presetAmount,
    calculatedAmount,
    quantities,
    emailError,
    priceError,
    invalidPriceError,
    uniqueId
  } = lineItem;

  const onChangeField = (fieldName) => {
    return (event) => {
      setGiftFlowLineItemProperties(lineItem, { [fieldName]: event.target.value });
    };
  };

  const handlePriceClick = (presetAmount) => {
    setGiftFlowLineItemProperties(lineItem, { 
      amount: presetAmount,
      typedAmount: '',
      priceError: false,
      invalidPriceError: false,
      presetAmount 
    });  
  }

  const handlePriceChange = (typedAmount) => {
    if (typedPriceIsValid(typedAmount)) {
      setGiftFlowLineItemProperties(lineItem, { 
        amount: priceToCents(typedAmount),
        presetAmount: null,
        typedAmount: addDollarSign(typedAmount),
        priceError: false,
        invalidPriceError: false
      });
    } else {
      setGiftFlowLineItemProperties(lineItem, { 
        amount: 0,
        presetAmount: null,
        typedAmount: addDollarSign(typedAmount),
        priceError: false,
        invalidPriceError: true
      });
    }
  }

  const handleChangeQuantity = (id, newQuantity) => {
    setGiftFlowLineItemProperties(lineItem, { 
      quantities: {
        ...quantities,
        [id]: newQuantity
      }
    });  
  }

  return (
    <div className="gift-select__form" id={uniqueId}>
      <div className="gift-select__image gift-select__image--product" />

      <div className="gift-select__box-content">

        {canRemove && <button className="gift-select__remove" onClick={() => onRemoveClick(lineItem)}><i className="icon icon-delete" /></button>}

        <input type="text" className="input" placeholder="Recipient email (Can be you)" 
          value={to} onChange={onChangeField('to')} />
        {emailError && <div className="gift-select__field-error">Please enter a valid email address.</div>}

        <input type="text" className="input" placeholder="From (Your name)" value={from} onChange={onChangeField('from')} />

        <textarea className="input textarea gift-select__message" placeholder="Write your message" value={message} onChange={onChangeField('message')} />

        <div>
          <PresetPriceSelector
            typedAmount={typedAmount}
            presetAmount={presetAmount}
            onChangePrice={handlePriceChange}
            onClickPrice={handlePriceClick}
            invalidPriceError={invalidPriceError}
            priceOptions={[]}>
              <GiftPriceCalculator 
                quantities={quantities}
                calculatedAmount={calculatedAmount}
                onChangeQuantity={handleChangeQuantity}
                onChangePrice={handlePriceChange} />
          </PresetPriceSelector>
          {priceError && <div className="gift-select__price-error">Please choose a valid amount.</div>}
        </div>

      </div>
    </div>
  );
};

GiftSelectForm.propTypes = {
};

export default GiftSelectForm;
