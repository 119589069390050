import _ from 'lodash';
import { createSelector } from 'reselect';

const flowSelector = state => state.flow.data;
const pricingSelector = state => state.flow.pricing;
const productsSelector = state => state.products.list.entities;

export const expandedFlowSelector = createSelector(flowSelector, pricingSelector, productsSelector, (flow, pricing, products) => {
  const expandedFlow = _.cloneDeep(flow);
  const { entity } = pricing;

  _.forEach(expandedFlow.lineItems, (lineItem) => {
    const product = _.find(products, ({ sku }) => {
      return lineItem.sku === sku;
    });

    lineItem.product = product;

    _.forEach(lineItem.variants, function (variantItem) {
      const { variantId, variantOptionId } = variantItem;

      variantItem.variant = _.find(product.variants, function ({ id }) {
        return id === variantId;
      });

      variantItem.variantOption = _.find(variantItem.variant.options, function ({ id }) {
        return id === variantOptionId;
      });
    });

    if (entity) {
      lineItem.pricing = _.find(entity.lineItems, ({ uniqueId }) => {
        return uniqueId === lineItem.uniqueId;
      });
    }
  });

  return expandedFlow;
});
