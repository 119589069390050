import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import CheckoutBox from '../checkout/checkout-box';
import LoadingSpinner from '../../components/meta/loading-spinner';

const MyAccountPayments = createReactClass({

  propTypes: {
    sources: PropTypes.array.isRequired,
    onRemove: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired
  },

  handleRemove(source) {
    return (e) => {
      e.preventDefault();
      this.props.onRemove(source.id);
    };
  },

  renderSources() {
    const { sources } = this.props;
    if (sources && sources.length > 0) {
      return sources.map((source,i) => (
        <div key={source.id} className="my-account__payment-method">
          {source.brand} ending in {source.last4} (expires {source.exp_month}/{source.exp_year})
          <a href="#remove-payment-method" onClick={this.handleRemove(source)}>Remove</a>
        </div>
        )
      );     
    } else {
      return <p>Payment methods are added automatically when purchases are made if you choose to have them saved. Once they've been added, you can manage them here!</p>
    }
  },
  
  render() {
    const { isFetching } = this.props;
    return (
      <CheckoutBox title="Payment Methods">
        {isFetching ? <LoadingSpinner mini /> : this.renderSources() }
      </CheckoutBox>
    );
  }

});

export default MyAccountPayments;