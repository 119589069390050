import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { CardElement } from 'react-stripe-elements';
import Scroll from 'react-scroll';
import CheckoutBox from './checkout-box';
import CheckoutCheckbox from './checkout-checkbox';
import CheckoutError from './checkout-error';
import { Field, FormSection, change } from 'redux-form';
import { stripeStyles } from '../../utils/checkout';

const CheckoutBilling = createReactClass({
  propTypes: {
    error: PropTypes.string,
    stripeComplete: PropTypes.bool.isRequired,
    onStripeChange: PropTypes.func.isRequired,
    stripeSources: PropTypes.array.isRequired,
    change: PropTypes.func.isRequired
  },


  componentDidMount() {
    if (this.props.error) {
      Scroll.animateScroll.scrollToBottom();
    }
  },

  componentDidUpdate() {
    if (this.props.error) {
      Scroll.animateScroll.scrollToBottom();
    }
  },

  handleCardClick(e) {
    this.props.change('checkout', 'billing.stripeSource', 'new');
  },

  renderNewCardInput() {
    const { stripeComplete } = this.props;
    return (<div className="checkout-billing__card-input">
      <div className="checkout-billing">
        <div className="checkout__input input checkout-billing__card">
          <CardElement onChange={this.props.onStripeChange} style={stripeStyles} />
        </div>
      </div>

      {stripeComplete && <Field name="saveForLater" component={CheckoutCheckbox} className="checkout__input checkout-billing__save" label="Save this payment method for future orders" />}
    </div>);
  },

  renderPaymentOptions() {
    const { stripeSources } = this.props;
    if (stripeSources.length === 0) {
      return this.renderNewCardInput();
    }
    const savedPayments = stripeSources.map(source => {
      return (<label className="checkout-billing__option checkout-billing__saved" key={source.id}>
        <Field name="stripeSource" component="input" type="radio" value={source.id} />
        {source.brand} ending in {source.last4} (expires {source.exp_month}/{source.exp_year})
      </label>);
    });
    return (<div className="checkout-billing__options">
      <div className="checkout-billing__option checkout-billing__new" onClick={this.handleCardClick}>
        <Field name="stripeSource" component="input" type="radio" value="new" />
        {this.renderNewCardInput()}
      </div>
      {savedPayments}
    </div>);
  },

  render () {
    const { error } = this.props;

    return (
      <CheckoutBox title="Billing info">
        {error && <CheckoutError>{error}</CheckoutError>}
        <FormSection name="billing">

        {this.renderPaymentOptions()}

        </FormSection>
      </CheckoutBox>
    );
  }
});

const mapDispatchToProps = {
  change
};

export default connect(null, mapDispatchToProps)(CheckoutBilling);
