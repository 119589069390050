import _ from 'lodash';
import { isMobileApp } from './mobile-app';

export function firstNameFromUser (user) {
  if (user) {
    return _.split(user.name, ' ')[0];
  }

  return '';
}

export function getAffiliateLinkForUser (user) {
  const affiliateCode = _.get(user, 'affiliate.activeCode.code');
  return affiliateCode 
    ? `${process.env.REACT_APP_URL}/a/${affiliateCode}`
    : null
}

export function getAffiliateCodeForUser (user) {
  return _.get(user, 'affiliate.activeCode.code');
}

export function userIsInFunraiserMode (user) {
  return _.get(user, 'affiliate.activeCode.funraiser');
}

export function getMyAccountLinks (user) {
  const isAffiliate = user && user.affiliate && !user.affiliate.disabled;
  return [
    { label: 'Orders', value: 'orders' },
    { label: 'Email & Password', value: 'details' },
    { label: 'Payment Methods', value: 'payments' },
    { label: 'Shipping Address', value: 'addresses' },
    ...(isAffiliate 
      ? [{ label: 'Affiliate Program', value: 'affiliate', hideInMenu: true }] 
      : []
    ),
    { label: 'My Shop Stats', value: 'my-stats', hideInMenu: true },
    { label: 'My Product', pattern: /\/my-shop\/product\/\d+$/, hideInMenu: true },
    { label: 'My Shop', value: 'my-shop', hideInMenu: isMobileApp() }
  ];
};