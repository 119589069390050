import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFlowProperties } from '../../actions/flow';
import { setShowGrid } from '../../actions/editor';
import { PainterContext } from '../../contexts';
import Button from './button';
import GridSizeSelector from './grid-size-selector';
import { ReactComponent as PenSVG } from '../../assets/icons/pen.svg';
import { ReactComponent as LineSVG } from '../../assets/icons/line.svg';
import { ReactComponent as BucketSVG } from '../../assets/icons/bucket.svg';
import { ReactComponent as BombSVG } from '../../assets/icons/bomb.svg';
import { ReactComponent as CircleSVG } from '../../assets/icons/circle.svg';
import { ReactComponent as SquareSVG } from '../../assets/icons/square.svg';
import { ReactComponent as UpSVG } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as DownSVG } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as LeftSVG } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as RightSVG } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as UndoSVG } from '../../assets/icons/undo.svg';
import { ReactComponent as RedoSVG } from '../../assets/icons/redo.svg';
import { ReactComponent as GridSVG } from '../../assets/icons/grid.svg';

const Tools = ({ template }) => {
  const [ activeTool, setTool ] = useState('pencil');
  const painter = useContext(PainterContext);
  const dispatch = useDispatch();
  const showGrid = useSelector(state => state.editor.showGrid);

  useEffect(() => {
    setTool('pencil');
  }, [template]);
  
  const pickTool = toolname => {
    painter.current.tool = toolname;
    setTool(toolname);
  };
  
  const doAction = (action, ...args) => {
    painter.current[action].apply(painter.current, args);
    painter.current.draw();
    // Update canvas state
    const canvasState = painter.current.freezeState();
    dispatch(setFlowProperties({ canvasState }));
  };

  const toggleGrid = () => {
    dispatch(setShowGrid(!showGrid));
  };

  const gridToggleButton = (<Button onClick={toggleGrid} 
    title="Toggle Grid" active={!showGrid} className="toggle-grid">
      <GridSVG className="invert-fill" />
    </Button>);
  
  return (<div className="tools">
    <div className="tool-group">
      <Button onClick={() => pickTool('pencil')} active={activeTool === 'pencil'} title="Pencil">
        <PenSVG />
      </Button>
      <Button onClick={() => pickTool('line')} active={activeTool === 'line'} title="Line Tool">
        <LineSVG />
      </Button>
      <Button onClick={() => pickTool('circle')} active={activeTool === 'circle'} title="Circle Tool">
        <CircleSVG />
      </Button>
      <Button onClick={() => pickTool('square')} active={activeTool === 'square'} title="Square Tool">
        <SquareSVG />
      </Button>
      <Button onClick={() => pickTool('bucket')} active={activeTool === 'bucket'} title="Paint Bucket">
        <BucketSVG className="invert-fill" />
      </Button>
      <Button onClick={() => doAction('clear')} title="Clear All">
        <BombSVG />
      </Button>
      {gridToggleButton}
    </div>
    <div className="tool-group">
      <Button onClick={() => doAction('shift', 1, 0)} title="Shift Up">
        <UpSVG />
      </Button>
      <Button onClick={() => doAction('shift', -1, 0)} title="Shift Down">
        <DownSVG />
      </Button>
      <Button onClick={() => doAction('shift', 0, 1)} title="Shift Left">
        <LeftSVG />
      </Button>
      <Button onClick={() => doAction('shift', 0, -1)} title="Shift Right">
        <RightSVG />
      </Button>
      <Button onClick={() => doAction('undo')} title="Undo">
        <UndoSVG />
      </Button>
      <Button onClick={() => doAction('redo')} title="Redo">
        <RedoSVG />
      </Button>
      <div className="tool-break"></div>
      {gridToggleButton}
      <GridSizeSelector />
    </div>
  </div>);
};

export default Tools;