import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

function onSelectChange(onChange, e) {
  onChange(e.target.value);
}

function renderOptions(options) {
  return options.map((option) => {
    return (<option value={option.value} key={option.value}>
      {option.label}
    </option>);
  });
}

const CheckoutSelectField = ({ className, options, placeholder, input, autocomplete, meta: { error, touched }}) => {
  const classes = classNames({
    [className || '']: true,
    'checkout-select-field-wrapper': true,
    'checkout-field': true,
    'checkout-field--has-error': error && touched
  });

  const { onBlur, onChange, onFocus } = input;

  const selectClassName = (input.value === '')
    ? 'checkout-select-field placeholder-value'
    : 'checkout-select-field';

  return (
    <div className={classes}>
      <select 
        className={selectClassName}
        name={input.name} 
        value={input.value}
        autoComplete={autocomplete}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={_.partial(onSelectChange, onChange)}>
        <option value="">{placeholder}</option>
        {renderOptions(options)}
      </select>
      <div className="checkout-select-field-arrow">
        <i className="icon icon-arrow-a" />
      </div>
      {error && touched && <span className="checkout-field__error">{error}</span>}
    </div>
  );
};

CheckoutSelectField.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  placeholder: PropTypes.string.isRequired,
  autocomplete: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool.isRequired
  })
};

export default CheckoutSelectField;
