import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { PainterContext } from '../../contexts';
import { getCurrentUser } from '../../reducers/user';
import { setLoginRedirect } from '../../actions/user';
import { setLoadedDesign } from '../../actions/editor';
import { allVariantsSelected, updateStaticImage } from '../../utils/editor';
import StaticPreview from './static-preview';
import { noop } from 'lodash';
import Button from './button';
import Modal from 'react-modal';
import Moose from '../../clients/moose';

const SaveImage = ({ text, template, setShowTemplateWarning=noop }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const painter = useContext(PainterContext);
  const sku = useSelector(state => state.editor.templateOptions.sku);
  const designersName = useSelector(state => state.editor.templateOptions.designersName);
  const variants = useSelector(state => state.editor.templateOptions.variants);
  const isLoading = useSelector(state => state.editor.isLoading);
  const products = useSelector(state => state.products.list.entities);
  const loadedDesign = useSelector(state => state.editor.loadedDesign);
  const { entity: user, isFetching: isFetchingUser  } = useSelector(state => getCurrentUser(state.user));
  const [ showModal, setShowModal ] = useState(history?.location?.search === '?save');
  const [ newDesign, setNewDesign ] = useState(null);
  
  const canSaveDesign = () => {
    const product = products.find(product => product.sku === sku);
    return !isLoading && !isFetchingUser && sku && allVariantsSelected(product, variants) && template && designersName;
  };

  const snapshotNewDesign = () => {
    setNewDesign({
      product: { sku },
      variants,
      designersName,
      data: painter.current.freezeState()
    });
  };

  const toggleModal = () => {
    if (!user) {
      dispatch(setLoginRedirect('/?save'));
      history.push('/sign-in');
    }
    if (showModal) {
      setShowModal(false);
    } else {
      snapshotNewDesign();
      setShowModal(true);
    }
  }

  const saveImage = () => {
    const product = products.find(product => product.sku === sku);
    Moose.savePixelDesign({
      productId: product.id,
      variants,
      designersName,
      data: painter.current.freezeState()
    }).then(design => {
      dispatch(setLoadedDesign(design));
      history.push('/account/my-shop');
    });
  };

  const updateImage = () => {
    const product = products.find(product => product.sku === sku);
    Moose.updatePixelDesign(loadedDesign.id, {
      productId: product.id,
      variants,
      designersName,
      data: painter.current.freezeState()
    }).then(design => {
      updateStaticImage(design);
      dispatch(setLoadedDesign(design));
      history.push('/account/my-shop');
    });
  };

  let modalContent;
  if (loadedDesign && loadedDesign.id) {
    // Editing an image
    modalContent = (<>
      <h1 className="header header--small">Replace Previous Design?</h1>
      <section className="save-design__body">
        <div className="save-design__preview">
          <div>
            <h3>Old Design</h3>
            <StaticPreview design={loadedDesign} maxWidth={250} stroke />
          </div>
          <div>
            <h3>New Design</h3>
            <StaticPreview design={newDesign} maxWidth={250} stroke />
          </div>
        </div>
      </section>
      <footer className="save-design__buttons">
      <button className="button plain" onClick={toggleModal}>Cancel</button>
        <button className="button primary text--light" onClick={updateImage}>Replace</button>
        <button className="button primary text--light" onClick={saveImage}>Save as Copy</button>
      </footer>
    </>);
  } else {
    // Save new image
    modalContent = (<>
      <h1 className="header header--small">Save Design</h1>
      <section className="save-design__body">
        <div className="save-design__preview">
          <StaticPreview design={newDesign} maxWidth={300} stroke />
        </div>
      </section>
      <footer className="save-design__buttons">
        <button className="button plain" onClick={toggleModal}>Cancel</button>
        <button className="button primary text--light" onClick={saveImage}>OK</button>
      </footer>
    </>);
  }

  return (<>
    <Button onClick={toggleModal} 
        className="save-design"
        onMouseOver={() => setShowTemplateWarning(!canSaveDesign())}
        onMouseOut={() => setShowTemplateWarning(false)}
        disabled={!canSaveDesign()} 
        title="Save Image" wide>
      {text && (<div>{text}</div>)}
      <i className="fa fa-floppy-o" />
    </Button>
    <Modal 
      className="modal-content modal-fullsize save-design__modal" 
      overlayClassName="modal fade in"
      onRequestClose={toggleModal}
      isOpen={showModal}>
      <button onClick={toggleModal} 
        className="close-modal"><i className="fa fa-close" /></button>
      <div className="modal-body">
        {modalContent}
      </div>
    </Modal>
  </>);
};

export default SaveImage;