export const DEFAULT_PIXEL_WIDTH = 33;
export const PIXEL_WIDTH_OPTIONS = [ 33, 66, 99 ];
export const PREVIEW_WIDTH = 180;
export const TOOLS_WIDTH = 90;

export const COLOR_BANK_SIZE = 16;
export const BACKGROUND_ENTRY = 12;
export const PALETTE_COLORS = [
  // Light
  '#e72114', '#ff6c00', '#ffce00', '#86eb2d', '#3b9d21', '#4dc5ee', '#3597f0', '#4543f1',
  '#8245ed', '#f45ddc', '#e8aa97', '#9d553e', '#ffffff', '#c9c9c9', '#7f7f7f', '#000000',
  // Darker
  '#ba1a10', '#ce5700', '#cea600', '#6cbd24', '#2f7e1a', '#3e9fc0', '#2a79c1', '#3736c2',
  '#6937bf', '#c54bb1', '#bb8979', '#7e4432', '#c6c6c6', '#a2a2a2', '#666666', '#000000',
  // Darkest
  '#8a130c', '#994000', '#997b00', '#508d1b', '#235e13', '#2e768e', '#1f5a90', '#292890',
  '#4e298e', '#923784', '#8b665a', '#5e3325', '#939393', '#787878', '#4c4c4c', '#000000'
];

export const EXPORT_WIDTH = 1188;

export const MOBILE = 'only screen and (max-width: 830px)';
export const DESKTOP = 'only screen and (min-width: 830px)';
