import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo.svg';

import { getLineItems, getGiftCertificates } from '../../reducers/cart';

const Header = createReactClass({
  propTypes: {
    useScrollLinks: PropTypes.bool.isRequired,
    numberOfLineItems: PropTypes.number.isRequired
  },

  getDefaultProps () {
    return {
      useScrollLinks: false
    };
  },

  getInitialState () {
    return {
      showMenu: false
    };
  },

  handleHamburger () {
    if (this.state.showMenu) {
      return this.handleClose();
    }

    return this.setState({
      showMenu: true
    });
  },

  handleClose () {
    this.setState({
      showMenu: false
    });
  },

  render () {

    const mainClassName = classNames({
      'navigation-bar': true,
      'navigation-bar--visible': this.state.showMenu
    });

    const menuClassName = classNames({
      'navigation-bar__menu': true,
      'navigation-bar__menu--visible': this.state.showMenu
    });

    const cartCountClasses = classNames({
      'navigation-bar__cart-count': true,
      'navigation-bar__cart-count--visible': this.props.numberOfLineItems > 0
    });

    return (
      <div className={mainClassName}>
        <div className="navigation-bar__hamburger access--background" onClick={this.handleHamburger}>
          <span className="navigation-bar__hamburger__line navigation-bar__hamburger--first" />
          <span className="navigation-bar__hamburger__line navigation-bar__hamburger--middle" />
          <span className="navigation-bar__hamburger__line navigation-bar__hamburger--last" />
        </div>

        <ul className="navigation-bar__actions">
          <li className="navigation-bar__action">
            <Link to="/cart">
              <i className="icon icon-cart" />
              <div className={cartCountClasses}>{this.props.numberOfLineItems}</div>
            </Link>
          </li>
          <li className="navigation-bar__action">
            <Link to="/account">
              <i className="icon icon-user" />
            </Link>
          </li>
        </ul>

        <ul className={menuClassName}>
          <li className="navigation-bar__close accent--color" onClick={this.handleClose} />
          <li className="navigation-bar__link accent--color"><Link to="/">Design</Link></li>
          <li className="navigation-bar__link accent--color"><Link to="/account/my-shop">My Designs</Link></li>
          <li className="navigation-bar__link accent--color"><Link to="/shops">Gallery</Link></li>
        </ul>

        <div className="navigation-bar__logo">
          <Link to="/home"><img src={Logo} /></Link>
        </div>
      </div>
    );
  }
});

function mapStateToProps (state) {
  return {
    numberOfLineItems: getLineItems(state.cart).length + getGiftCertificates(state.cart).length
  };
}

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
