import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sizeTableData } from '../../utils/size-data';
import _ from 'lodash';

import { getProductsList } from '../../reducers/products';

class SizeChart extends React.Component {

  static propTypes = {
    product: PropTypes.object,
    dollProduct: PropTypes.object,
    sku: PropTypes.string
  }

  constructor(props) {
    super(props);
    const { product } = props;
    this.state = {
      selectedType: _.get(product, 'variants[0].options[0].value'),
      selectedSize: _.get(product, 'variants[1].options[0].value')
    };
    this.renderDescription = this.renderDescription.bind(this);
    this.renderTypes = this.renderTypes.bind(this);
    this.renderSizes = this.renderSizes.bind(this);
    this.renderChart = this.renderChart.bind(this);
    this.renderBeanieSizeChart = this.renderBeanieSizeChart.bind(this);
    this.getTypes = this.getTypes.bind(this);
    this.getActiveType = this.getActiveType.bind(this);
    this.getSizes = this.getSizes.bind(this);
    this.getActiveSize = this.getActiveSize.bind(this);
  }

  handleTypeSelect(typeKey) {
    return () => {
      this.setState({ selectedType: typeKey });
    };
  }

  handleSizeSelect(sizeKey) {
    return () => {
      this.setState({ selectedSize: sizeKey });
    };
  }

  getTypes() {
    const { product } = this.props;
    return product.variants[0].options;
  }

  getActiveType() {
    const { dollProduct } = this.props;
    const { selectedType } = this.state;
    if ( selectedType === dollProduct.sku ) {
      return { value: dollProduct.sku };
    }
    return this.getTypes().find( t => t.value === selectedType ) || this.getTypes()[0];
  }

  getSizes() {
    const { product } = this.props;
    const productType = this.getActiveType();
    if (!productType) {
      return [];
    }
    return product.variants[1].options.filter( s => s.parentOptionId === productType.id );
  }

  getActiveSize() {
    const { selectedSize } = this.state;
    if (selectedSize === 'missing') {
      return { value: 'missing' };
    }
    return this.getSizes().find( t => t.value === selectedSize ) || this.getSizes()[0];
  }

  getImage(product, size) {
    const basePath = 'https://s3-us-west-2.amazonaws.com/pt-image-assets/size-chart';
    if ([ 'PTL01', 'PTL01AG' ].includes(product.sku)) {
      return 'https://picturethisclothing.com/wp-content/uploads/2019/04/leggings-1.svg';
    }
    if ( size ) {
      return `${basePath}/${product.sku}/${size.value}.jpg`;
    }
    return `${basePath}/${product.sku}.jpg`;
  }

  renderDescription() {
    const { product } = this.props;
    if (product.sku === 'PTD01') {
      return(<p className="subheading">
        Our sizes are based on average US department store sizes. With dresses, we recommend ordering the same size you wear in Target or similar department store sizes.
      </p>);
    }
    if (product.sku === 'PTS01') {
      return(<p className="subheading">
        Our t-shirts run small, we recommend sizing up one size from what you currently wear for a regular fit, size up two sizes for room to grow.
      </p>);
    }
  }

  renderTypes() {
    const { selectedType } = this.state;
    const { dollProduct } = this.props;

    const activeType = this.getActiveType();
    const productTabs = this.getTypes().map(productType => {
      const className = activeType.value === productType.value ? 'active' : null;
      let typeLabel = productType.value || '';
      if (typeLabel.match(/^Women/)) {
        typeLabel = 'Women';
      }
      if (typeLabel.match(/^Men/)) {
        typeLabel = 'Men';
      }      
      return (<div key={productType.value} className={className} role="tab" 
        onClick={this.handleTypeSelect(productType.value)}>
        {typeLabel}
      </div>);
    });

    let dollTab;
    if (dollProduct) {
      const className = selectedType === dollProduct.sku ? 'active' : null;
      dollTab = <div key={dollProduct.sku} className={className} role="tab" 
        onClick={this.handleTypeSelect(dollProduct.sku)}>
        Doll
      </div>
    }

    return [ ...productTabs, dollTab ];
  }

  renderSizeTable() {
    const { product, dollProduct } = this.props;
    const { selectedType } = this.state;
    const sizes = this.getSizes();
    const sizeTable = sizeTableData[product.sku];
    const showDollData = dollProduct && selectedType === dollProduct.sku;

    return (<table className="size-chart-table">
      <thead>
        <tr>
          <th>Size</th>
          {sizeTable.headers.map((header,i) => <th key={`header${i}`}>{header}</th>)}
        </tr>
      </thead>
      <tbody>
        {sizes.map(size => {
          const sizeData = sizeTable.sizes[size.value];
          return (<tr key={size.id}>
            <td><strong>{size.displayValue}</strong></td>
            {sizeData.map((data,i) => <td key={`size${size.id}-${i}`}>{data}</td>)}
          </tr>);
        })}
        {showDollData && (<tr>
          <td><strong>One Size</strong> (Fits American Doll Size)</td>
          {sizeTable.dollSize.map((data,i) => <td key={`dollSize-${i}`}>{data}</td>)}
        </tr>)}
      </tbody>
      <tfoot>
        <td colSpan={sizeTable.headers.length + 1}>
          <p className="size-missing">
            <b>Don't see your size?</b> Please let us know at <a href="mailto:hello@picturethisclothing.com">hello@picturethisclothing.com</a>
          </p>
        </td>
      </tfoot>
    </table>);
  }

  renderSizes() {
    const { dollProduct } = this.props;
    const { selectedType, selectedSize } = this.state;
    if ( dollProduct && selectedType === dollProduct.sku) {
      return null;
    }
    const activeSize = this.getActiveSize();
    const sizeTabs = this.getSizes().map( size => {
      const className = activeSize.value === size.value ? 'active' : null;
      return (<div key={size.id} role="tab" className={className} onClick={this.handleSizeSelect(size.value)}>
        {size.displayValue}
      </div>);
    });

    const missingClass = selectedSize === 'missing' ? 'active' : null;
    return (<div className="product-sizes" role="tablist">
      {sizeTabs}
      <div role="tab" className={missingClass} onClick={this.handleSizeSelect('missing')}>
        Don't see your size?
      </div>
    </div>);
  }

  renderChart() {
    const { product, dollProduct } = this.props;
    const { selectedType, selectedSize } = this.state;

    if (dollProduct && selectedType === dollProduct.sku) {
      const image = this.getImage( dollProduct );
      return <img src={image} alt={`Size chart for ${dollProduct.name}`} />
    }

    if (selectedSize === 'missing') {
      return (<p className="size-missing">
        Please let us know at <a href="mailto:hello@picturethisclothing.com">hello@picturethisclothing.com</a>
      </p>)
    }

    const productType = this.getActiveType();
    const productSize = this.getActiveSize();
    const showKidsDressNote = product.sku === 'PTD01' && productType.value === 'Kids';
    const image = this.getImage( product, productSize );

    return (<React.Fragment>
      <img src={image} alt={`Size chart for ${productType.displayValue} ${product.name} size ${productSize.displayValue}`} />
      {showKidsDressNote ? <p className="chart-note">
        NOTE: chest, waist and bottom width measurements are half-circumference.
        For example, chest is front panel, armpit to armpit.</p> : ''}
    </React.Fragment>);
  }

  renderBeanieSizeChart() {
    const { product: {
        variants: {
          0: { 
            options 
          }
        }
      }
    } = this.props;
    return (<div className="size-chart">
      <div className="product-type-content">
        <table className="size-chart-table">
          <thead>
            <tr>
              <th>Size</th>
              <th>Head Circumerence</th>
            </tr>
          </thead>
          <tbody>
            {options.map(option => {
              const sizeParts = option.displayValue.match(/(.*?)\s+\((.*?)\)/);
              return (<tr key={option.id}>
                <td><strong>{sizeParts[2]}</strong></td>
                <td>{sizeParts[1]}</td>
              </tr>);
            })}
          </tbody>
        </table>
        <div className="chart-image chart-image-beanie">
          <img src="https://picturethisclothing.com/wp-content/uploads/2019/10/head-circumference.svg" alt="How to measure head cirumference" />
        </div>
      </div>
    </div>);
  }


  render() {
    const { product } = this.props;
    if (product && product.sku === 'PTB01') {
      return this.renderBeanieSizeChart();
    }
    if (!product || !['PTD01', 'PTS01', 'PTL01'].includes(product.sku)) {
      return <div>Size chart not found.</div>;
    }
    return (
      <div className="size-chart">
        <h1>{product.name} Sizes</h1>
        {this.renderDescription()}
        <div className="product-types" role="tablist">
          {this.renderTypes()}
        </div>
        <div className="product-type-content" role="tabpanel">
          {sizeTableData[product.sku] ? this.renderSizeTable() : this.renderSizes()}
          <div className="chart-image">
            {this.renderChart()}
          </div>
        </div>
      </div>
    );
  }

};

function mapStateToProps (state, ownProps) {
  const { sku } = ownProps; // PTD01 PTS01 PTD01AG PTS01AG
  const { entities:products } = getProductsList(state.products);
  const product = products.find( p => p.sku === sku );
  const dollProduct = products.find( p => p.sku === `${sku}AG` );
  return {
    product,
    dollProduct
  };
}

export default connect(mapStateToProps)(SizeChart);