import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const QuantitySelector = createReactClass({
  propTypes: {
    quantity: PropTypes.number.isRequired,
    minimumQuantity: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  },

  handleAdd: function () {
    const { onChange, quantity, disabled } = this.props;
    if (!disabled) {
      const newQuantity = quantity + 1;
    
      onChange(newQuantity);
    }
  },

  handleSubtract: function () {
    const { onChange, quantity, minimumQuantity = 1 } = this.props;
    const newQuantity = Math.max(quantity - 1, minimumQuantity);

    onChange(newQuantity);
  },

  render: function () {
    const { quantity, disabled } = this.props;

    const decrementClasses = classNames({
      'quantity-selector__decrement': true,
      'quantity-selector__button': true,
      'quantity-selector__decrement--above-zero': quantity > 0
    });

    const incrementClasses = classNames({
      'quantity-selector__increment': true,
      'quantity-selector__button': true,
      'quantity-selector__increment--disabled': disabled
    });

    return (
      <div className="quantity-selector">
        <button className={decrementClasses} type="button" onClick={this.handleSubtract}>-</button>
        <span className="quantity-selector__text">{quantity}</span>
        <button className={incrementClasses} type="button" onClick={this.handleAdd}>+</button>
      </div>
    );
  }
});

export default QuantitySelector;
