import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const CheckoutField = ({ className, input, meta: { error, touched }, label, children, ...rest }) => {
  const classes = classNames({
    [className]: className ? true : false,
    'checkout-field': true,
    'checkout-field--has-error': error && touched
  });

  return (
    <div className={classes}>
      {error && touched && <span className="checkout-field__error">{error}</span>}
      <label>
        <input type="checkbox" className="checkbox" {...input} {...rest} checked={Boolean(input.value)} />
        {label || children}
      </label>
    </div>
  );
};

CheckoutField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool.isRequired
  })
};

export default CheckoutField;
