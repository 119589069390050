import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { fetchUserIfNeeded } from '../../actions/user';
import { getCurrentUser } from '../../reducers/user';
import { setProductSkuWithVariants, setFlowProperties } from '../../actions/flow';
import MyProductForm from '../../components/my-account/my-product-form';
import LoadingSpinner from '../../components/meta/loading-spinner';
import MyStoreWrapper from '../../components/my-account/my-store-wrapper';
import ProfileImage from '../../components/user-store/profile-image';
import MyProductImages from '../../components/my-account/my-product-images';
import VariantSelector from '../../components/products/variant-selector';
import ViewSizeChart from '../../components/products/view-size-chart';
import DescriptionSection from '../../components/products/description-section';
import ProductSellingToggle from '../../components/user-store/product-selling-toggle';
import { Link } from 'react-router-dom';
import Moose, { uploadBlob } from '../../clients/moose';
import { parseDescriptionLineBreaks } from '../../utils/string';
import { createBlob, getTemplate, updateStaticImage } from '../../utils/editor';
import { allVariantsSelected } from '../../utils/editor/products';
import _ from 'lodash';

const MyProductRoute = createReactClass({

  propTypes: {
    user: PropTypes.object.isRequired,
    products: PropTypes.array,
    fetchUserIfNeeded: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        productId: PropTypes.string.isRequired
      })
    })
  },

  getInitialState() {
    return {
      isLoadingProduct: false,
      isLoadingStore: false,
      design: {},
      variants: []
    }
  },

  componentDidMount() {
    const { match: { params: { productId } } } = this.props;
    this.setState({ isLoadingProduct: true });
    this.fetchShop();
    this.fetchDesign(productId);
  },

  handleVariantChange (variants) {
    this.setState({ variants });
  },

  async handleAddToCart () {
    const { design: { data: canvasState, product }, variants } = this.state;
    
    this.setState({ isLoadingProduct: true });
    const template = getTemplate(product.sku, variants);
    const blob = await createBlob(canvasState, template);
    this.props.setProductSkuWithVariants(product.sku, variants);
    const upload = await uploadBlob(blob);
    this.props.setFlowProperties({ canvasState, upload });
    this.setState({ isLoadingProduct: false });
    this.props.history.push('/product/review');
  },

  fetchShop() {
    this.setState({ isLoadingStore: true });
    Moose.fetchMyShop().then(store => {
      this.setState({ 
        isLoadingStore: false,
        store 
      });
    });
  },

  fetchDesign(id) {
    Moose.fetchPixelDesign(id).then(design => {
      this.setState({ 
        isLoadingProduct: false,
        design,
        variants: design.variants
      });
    });
  },

  toggleShowProductForm() {
    const { showProductForm } = this.state;
    this.setState({ showProductForm: !showProductForm });
  },

  handleUpdateProduct(values) {
    const { design, design: { id } } = this.state;
    Moose.updatePixelDesignProduct(id, values).then(userProduct => {
      this.setState({
        showProductForm: false,
        design: {
          ...design,
          userProduct
        }
      });
    });
  },

  handleAddImage(upload) {
    const { design: { id } } = this.state;
    Moose.addPixelDesignImage(id, upload.id).then(() => {
      this.fetchDesign(id);
    });
  },

  handleRemoveImage(imageId) {
    const { design: { id } } = this.state;
    Moose.deletePixelDesignImage(id, imageId).then(result => {
      this.fetchDesign(id);
    });
  },

  handleReorderImages(imageOrder) {
    const { design, design: { userProduct, id } } = this.state;
    // Optimistic reorder
    if (imageOrder.length > 1) {
      const orderById = _.invert(imageOrder);
      this.setState({
        design: {
          ...design,
          userProduct: {
            ...userProduct,
            imageOrder: Number(orderById['main']),
            images: (userProduct.images || []).map(image => ({
              ...image,
              imageOrder: Number(orderById[image.id])
            }))
          }
        }
      });
      Moose.reorderPixelDesignImages(id, imageOrder).then(result => {
        this.fetchDesign(id);
      });
    }
  },

  handleToggleProductSellable() {
    const { design, design: { userProduct, id } } = this.state;
    const newStatus = userProduct.status === 'selling' ? 'hidden' : 'selling';
    this.setState({
      design: {
        ...design,
        userProduct: {
          ...userProduct,
          status: newStatus,
          updating: !userProduct.updating
        }
      }
    });
    Moose.updatePixelDesignProduct(id, {
      status: newStatus
    }).then(() => {
      updateStaticImage(design);
      this.fetchDesign(id);
    });
  },

  renderProductForm() {
    const { showProductForm, design: { userProduct }, isLoadingProduct } = this.state;

    if (isLoadingProduct) {
      return (<LoadingSpinner />);
    }

    if (showProductForm) {
      return (<MyProductForm 
          initialValues={_.pick(userProduct, ['name', 'description'])}
          onSubmit={this.handleUpdateProduct} 
          onCancel={this.toggleShowProductForm} />);
    }

    return (<>
        <h3>{userProduct.name}</h3>
        {parseDescriptionLineBreaks(userProduct.description)}
        <button className="circle-icon-button" 
          onClick={this.toggleShowProductForm}>
          <i className="fa fa-pencil" />
        </button>
      </>);
  },

  render() {
    const { user, products } = this.props;
    const { 
      store, 
      design, 
      isLoadingStore, 
      isLoadingProduct,
      variants
    } = this.state;
    const storeExists = store && store.id;
    if (!store || isLoadingStore || isLoadingProduct || !products.length) {
      return (<LoadingSpinner />);    
    }

    const product = products.find(p => p.id === design.productId);
    const cannAddToCart = allVariantsSelected(product, variants);
    const profileImage = _.get(store, 'profileImage.thumbnails.profile') || store.gravatar;
    const descriptionSections = _.get(design, 'product.descriptionSections') || [];
    return (
      <MyStoreWrapper store={store} user={user} minimal>
        <div className="user-product-details my-product-details">
          <div>
            <MyProductImages 
              design={design} 
              onAddImage={this.handleAddImage}
              onRemoveImage={this.handleRemoveImage}
              onReorderImages={this.handleReorderImages}
            />
          </div>
          <div className="my-product-info-panel">
            {storeExists ? <ProductSellingToggle className="my-product-toggle" design={design} onToggle={() => this.handleToggleProductSellable(design)} /> : null}
            <div className="user-product-info">
              {this.renderProductForm()}
            </div>
            {descriptionSections.map((dS) => (<DescriptionSection key={dS.id} descriptionSection={dS} />))}
            <div className="product-description-section product-description-size-chart">
                <ViewSizeChart sku={design.product.sku}>
                  Size Chart <i className="icon icon-size" />
                </ViewSizeChart>
            </div>
            <div className="user-product-options">
                <VariantSelector
                  product={product}
                  values={variants} 
                  onVariantsChange={this.handleVariantChange} />

                  <button className="button primary" onClick={this.handleAddToCart} disabled={!cannAddToCart}>ADD TO CART</button>
            </div>
            <div className="store-owner">
              <ProfileImage image={profileImage} />
              by
              <Link to='/account/my-shop'>{store.name}</Link>
            </div>
          </div>
        </div>
      </MyStoreWrapper>
    );
  }

});

function mapStateToProps (state) {
  const { entity } = getCurrentUser(state.user);
  return {
    user: entity,
    products: state?.products?.list?.entities || []
  };
}

const mapDispatchToProps = {
  fetchUserIfNeeded,
  setFlowProperties,
  setProductSkuWithVariants
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProductRoute);