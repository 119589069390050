import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import CheckoutField from '../checkout/checkout-field';
import CheckoutSelectField from '../checkout/checkout-select-field';
import CheckoutCheckbox from '../checkout/checkout-checkbox';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import AffiliateTOS from './affiliate-tos';
import { isValidEmailIf } from '../../utils/validators';
import {
  combineValidators,
  isRequired,
  matchesField
} from 'revalidate';

const PaymentPreferenceOptions = [
  { value: 'paypal', label: 'PayPal' },
  { value: 'store-credit', label: 'Store Credit' }
];

const AffiliateSettings = createReactClass({
  propTypes: {
    affiliate: PropTypes.object,
    onSubmitTOS: PropTypes.func.isRequired,
    isFunraiser: PropTypes.bool
  },

  getInitialState() {
    return {
      termsAccepted: false
    };
  },

  handleTOSCheck(e) {
    this.setState({ termsAccepted: e.target.checked })
  },

  handleSubmitTOS() {
    this.props.onSubmitTOS();
  },

  renderTOS() {
    const { termsAccepted } = this.state
    return (<div className="affiliate-settings__tos">     
      <p>In order to participate in the program, active Picture This Clothing customers must agree to the following Terms and Conditions.</p>
      <AffiliateTOS />
      <div className="affiliate-settings__tos-controls">
        <label>
          <input type="checkbox" className="checkbox" onClick={this.handleTOSCheck} value="1" />
          I agree to the terms of service.
        </label>
        <button className="button primary" disabled={!termsAccepted} 
          onClick={this.handleSubmitTOS}>
          Continue &nbsp; <i className="fa fa-long-arrow-right" />
        </button>
      </div>
    </div>);
  },

  renderDisabled() {
    return (<p>Sorry, we are not accepting any additional affiliates at this time.</p>);
  },

  renderPaymentPreferences () {
    const { paymentPreference, isFunraiser } = this.props;
    const paymentOptions = isFunraiser 
      ? PaymentPreferenceOptions.filter(o => o.value === 'paypal')
      : PaymentPreferenceOptions;
    const isPayPal = paymentPreference === 'paypal';
    return (<div className="affiliate-settings__payments">
      <form onSubmit={this.props.handleSubmit(this.props.onSubmitSettings)}>
        <label htmlFor="paymentPreference">Payment Preference</label>
        <Field name="paymentPreference" component={CheckoutSelectField} 
          options={paymentOptions} placeholder="Payment Preference" />
        {isPayPal ? <React.Fragment>
            <label htmlFor="paypalAddress">PayPal Email Address</label>
            <Field name="paypalAddress" component={CheckoutField} type="text" placeholder="PayPal Email Address" />
            <label htmlFor="confirmPaypalAddress">Confirm PayPal Email Address</label>
            <Field name="confirmPaypalAddress" component={CheckoutField} type="text" placeholder="Confirm PayPal Email Address" />
          </React.Fragment> : null}
        <Field name="notifySales" component={CheckoutCheckbox} label="Notify me of every sale" />
        <Field name="notifyPayments" component={CheckoutCheckbox} label="Notify me of each payout" />
        <button className="button primary" type="submit">Save Settings</button>
      </form>
    </div>);
  },

  render () {
    const { affiliate } = this.props;
    const showTOS = !affiliate || !affiliate.tosAccepted;
    return (<React.Fragment>
      <div className="affiliate-settings">
        <h3 className="header">{showTOS ? 'Terms of Service' : 'Settings'}</h3>
        <div>
          {showTOS 
            ? this.renderTOS() 
            : this.renderPaymentPreferences()}
        </div>
      </div>
    </React.Fragment>);
  }
});

function mapStateToProps (state, props) {
  const selector = formValueSelector(props.form);
  return {
    paymentPreference: selector(state, 'paymentPreference')
  };
}

const mapDispatchToProps = {
  formValueSelector
};

const validate = combineValidators({
  'paymentPreference': isRequired('Payment Preference'),
  'paypalAddress': isValidEmailIf(values => values.paymentPreference === 'paypal')('PayPal Email Address'),
  'confirmPaypalAddress': matchesField('paypalAddress')({
    message: 'PayPal Email Address does not match'
  }),
});

export default reduxForm({
  enableReinitialize: true,
  form: 'affiliate-settings',
  validate,
  shouldValidate: () => true
})(connect(mapStateToProps, mapDispatchToProps)(AffiliateSettings));