import React from 'react';
import createReactClass from 'create-react-class';
import { Redirect } from 'react-router-dom';

import LoadingSpinner from '../components/meta/loading-spinner';


const HomeRedirect = createReactClass({

  componentDidMount () {
    // Staging get clobbered with production variables that aren't overriden,
    // so we have to rely on this 'fake' app env.
    if (process.env.REACT_APP_ENV==='production') {
      return window.location = process.env.REACT_APP_WORDPRESS_HOME || '/';
    }
  },

  render () {
    if (process.env.REACT_APP_ENV==='production') {
      return (<LoadingSpinner />);
    }
    return (<Redirect to="/" />);
  }
});

export default HomeRedirect;
