import { useState, useEffect } from 'react';
import { canRenderInBrowser } from './image';

export function useWaitForImage(imageUrl) {
  const [ isLoading, setIsLoading ] = useState(false);
  useEffect(() => {
    if (imageUrl && canRenderInBrowser(imageUrl)) {
      setIsLoading(true);
      let retryCount = 0;
      const tryImage = new Image();
      tryImage.onload = () => setIsLoading(false);
      tryImage.onerror = () => {
        if (retryCount < 10) {
          retryCount = retryCount + 1;
          setTimeout(function() {
            tryImage.src = `${imageUrl}?retry=${retryCount}`;
          }, 1500);
        }
      }
      tryImage.src = imageUrl;
    }
  }, [ imageUrl ]);
  return isLoading;
};