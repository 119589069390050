import React from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from './loading-spinner';

const EntityDetail = ({ render, entity, isFetching, error, alwaysShowFetching }) => {
  if (isFetching && alwaysShowFetching) {
    return <LoadingSpinner />;
  } else if (isFetching && !entity) {
    return <LoadingSpinner />;
  } else if (error && !entity) {
    return <p className="error-message">{error.message}</p>;
  }

  return (
    <div className="entity-detail">
      {entity && render()}
    </div>
  );
};

EntityDetail.propTypes = {
  render: PropTypes.func.isRequired,
  entity: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  alwaysShowFetching: PropTypes.bool
};

export default EntityDetail;
