import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import app from './app';
import cart from './cart';
import checkout from './checkout';
import flow from './flow';
import giftFlow from './gift-flow';
import products from './products';
import user from './user';
import editor from './editor';

const rootReducer = combineReducers({
  app,
  cart,
  checkout,
  flow,
  form,
  giftFlow,
  products,
  user,
  editor
});

export default rootReducer;
