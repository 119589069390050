import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { Elements } from 'react-stripe-elements';
import cx from 'classnames';

import { fetchProducts } from './actions/products';
import { trackPageView } from './utils/tracking';

import LoadingSpinner from './components/meta/loading-spinner';
import ScrollToTop from './components/structure/scroll-to-top';
import NotFound from './routes/not-found-route';
import SignIn from './routes/sign-in-route';
import LogOut from './routes/log-out-route';
import MyAccount from './routes/my-account-route';
import ForgotPassword from './routes/forgot-password-route';
import Cart from './routes/cart-route';
import Checkout from './routes/checkout-route';
import GiftSelectRoute from './routes/gift-select-route';
import Editor from './routes/editor-route';
import HomeRedirect from './routes/home-redirect';
import OrderConfirmation from './routes/order-confirmation-route';
import ProductContainer from './routes/product-container-route';
import ReviewRoute from './routes/review-route';
import UserProductRoute from './routes/user-product-route';
import UserStoreRoute from './routes/user-store-route';
import ShopsRoute from './routes/shops-route';
import DisabledRoute from './routes/disabled-route';
import { isMobileApp } from './utils/mobile-app';

import './styles/app.scss';

const SITE_DISABLED = false;

const App = createReactClass({
  propTypes: {
    rehydrated: PropTypes.bool.isRequired,
    fetchProducts: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  },

  componentDidMount () {
    this.props.fetchProducts();
  },

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      trackPageView();
    }
  },

  renderRoutes () {
    if (!this.props.rehydrated) {
      return <LoadingSpinner />;
    }

    return (
      <Switch>
        <Route path="/" exact={true} render={props => {
          return (<ProductContainer {...props}>
              <Editor />
            </ProductContainer>);          
        }} />
        <Route path="/home" exact={true} component={HomeRedirect} />
        <Route path="/product" render={(props) => {
          return (
            <ProductContainer {...props}>
              <Switch>
                <Route path="/product/review" exact={true} component={SITE_DISABLED ? DisabledRoute : ReviewRoute} />               
                <Route path="/product/gift-certificates/select" exact={true} component={GiftSelectRoute} />
              </Switch>
            </ProductContainer>
          );
        }} />

      <Route path="/shops/:filterPairs*" render={(props) => {
          return (
            <ProductContainer {...props}>
              <ShopsRoute {...props} />
            </ProductContainer>
          )}} />

      <Route path="/store/:code" render={(props) => {
        const { match: { params: { code } } } = props;
        return (
          <Redirect to={`/shop/${code}`} />
        )}} />

      <Route path="/shop/:code" render={(props) => {
          return (
            <ProductContainer {...props}>
              <UserStoreRoute {...props} />
            </ProductContainer>
          )}} />

        <Route path="/design/:code" render={(props) => {
          return (
            <ProductContainer {...props}>
              <UserProductRoute {...props} />
            </ProductContainer>
          )}} />

        <Route path="/sign-in" render={(props) => {
          return (
            <ProductContainer {...props}>
              <SignIn {...props} />
            </ProductContainer>
          );
        }} />

        <Route path="/account/log-out" render={(props) => {
          return (
            <LogOut {...props} />
          );
        }} />

        <Route path="/account" render={(props) => {
          return (
            <ProductContainer {...props}>
              <MyAccount {...props} />
            </ProductContainer>
          );
        }} />

        <Route path="/forgot-password" render={(props) => {
          return (
            <ProductContainer {...props}>
              <ForgotPassword {...props} />
            </ProductContainer>
          );
        }} />        

        <Route path="/cart" exact={true} render={(props) => {
          return (
            <ProductContainer {...props}>
              <Cart {...props} />
            </ProductContainer>
          );
        }} />

        <Route path="/checkout" exact={true} render={(props) => {
          return (
            <ProductContainer {...props}>
              <Elements>
                <Checkout {...props} />
              </Elements>
            </ProductContainer>
          );
        }} />

        <Route path="/checkout/confirmation" exact={true} render={(props) => {
          return (
            <ProductContainer {...props}>
              <OrderConfirmation {...props} />
            </ProductContainer>
          );
        }} />

        <Route component={NotFound} />
      </Switch>
    );
  },

  render () {
    const className = cx('app', 'cd-main-conent', { 'pixel-this-app': isMobileApp() });
    return (
      <div className={className}>
        <Helmet title="Pixel This Clothing" />
        <ScrollToTop />

        {this.renderRoutes()}
      </div>
    );
  }
});

function mapStateToProps (state) {
  return {
    rehydrated: state.app.rehydrated
  };
}

const mapDispatchToProps = {
  fetchProducts
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
