import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import DualPrice from './dual-price';
import ReviewLineItem from './review-line-item';

const ReviewLineItems = createReactClass({
  propTypes: {
    flow: PropTypes.object.isRequired
  },

  renderLineItems () {
    const { designersName, lineItems, upload, userDesign } = this.props.flow;

    return _.map(lineItems, (lineItem) => {
      return (
        <ReviewLineItem
          key={lineItem.uniqueId}
          designersName={designersName}
          editable={!!lineItem.parentUniqueId}
          pricing={lineItem.pricing}
          product={lineItem.product}
          quantity={lineItem.quantity}
          uniqueId={lineItem.uniqueId}
          variants={lineItem.variants}
          upload={upload} 
          userDesign={userDesign} />
      );
    });
  },

  render () {
    const { flow: { lineItems } } = this.props;
    let subtotalPrice = 0;
    let finalPrice = 0;
    lineItems.forEach(lineItem => {
      subtotalPrice += _.get(lineItem, 'pricing.subtotalPrice');
      finalPrice += _.get(lineItem, 'pricing.finalPrice');
    });

    return (
      <div className="review-line-items">
        {this.renderLineItems()}

        <div className="review-line-items__subtotal text--bold">
          <span className="review-line-items__subtotal-label">Subtotal</span>
          <span className="review-line-items__subtotal-value">
            <DualPrice subtotalPrice={subtotalPrice} finalPrice={finalPrice} />
          </span>
        </div>
      </div>
    );
  }
});

export default ReviewLineItems;
