import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { Field, FormSection } from 'redux-form';
import { Link } from 'react-router-dom';

import { getAuthentication } from '../../reducers/user';

import CheckoutBox from './checkout-box';
import CheckoutError from './checkout-error';
import CheckoutField from './checkout-field';
import SegmentedControl from '../structure/segmented-control';

const CheckoutAuthentication = createReactClass({
  propTypes: {
    authSegmentIndex: PropTypes.number.isRequired,
    onAuthTypeChange: PropTypes.func.isRequired,
    error: PropTypes.object,
    info: PropTypes.object,
    title: PropTypes.string,
    from: PropTypes.string
  },

  renderSignIn () {
    const { from } = this.props;
    const forgotPassword = {
      pathname: '/forgot-password',
      state: { from }
    };
    return (
      <div>
        <Field name="email" component={CheckoutField} type="text" className="checkout__input" placeholder="Email" autoComplete="username email" autoCapitalize="off" />
        <Field name="password" component={CheckoutField} type="password" className="checkout__input" placeholder="Password" autoComplete="current-password" autoCapitalize="off" />
        <Link to={forgotPassword} className="checkout-authentication__forgot-password-link">Forgot password?</Link>
      </div>
    );
  },

  renderSignUp () {
    return (
      <div>
        <Field name="name" component={CheckoutField} type="text" className="checkout__input" placeholder="First &amp; Last Name" autoComplete="name" />
        <Field name="email" component={CheckoutField} type="text" className="checkout__input" placeholder="Email" autoComplete="email username" />
        <Field name="password" component={CheckoutField} type="password" className="checkout__input" placeholder="Password" autoComplete="new-password" />
        <Field name="passwordConfirmation" component={CheckoutField} type="password" className="checkout__input" placeholder="Confirm Password" autoComplete="new-password" />
      </div>
    );
  },

  renderError() {
    const { error, migratedPasswordReset, forcePasswordReset } = this.props;
    if (!error) {
      return null;
    }
    if (migratedPasswordReset || forcePasswordReset) {
      const forgotPassword = {
        pathname: '/forgot-password',
        state: { }
      };
      let resetMessage = '';
      if (migratedPasswordReset) {
        resetMessage = (<p>We've been working on upgrades to our store recently and, due to some technology changes, we'll need you to reset your password. Thank you, and sorry for any inconvenience.</p>);
      }
      if (forcePasswordReset) {
        resetMessage = (<p>We've noticed an issue with your account and need you to reset your password. Thank you, and sorry for any inconvenience.</p>);
      }
      return (<div className="warning-message">
        {resetMessage}
        <Link to={forgotPassword} className="checkout-authentication__reset-password-link">Please click here to continue.</Link>
      </div>);
    } else {
      return <CheckoutError>{error.message}</CheckoutError>
    }
  },

  render () {
    const { authSegmentIndex, info, title } = this.props;

    return (
      <div className="checkout-authentication">
        <CheckoutBox title={title || "Your info"}>
          <SegmentedControl
            segments={['Create an account', 'Sign in']}
            selectedIndex={authSegmentIndex}
            onSegmentClick={this.props.onAuthTypeChange} />

          {this.renderError()}

          {info && <p className="info-message">{info.message}</p>}

          <FormSection name="signUp">
            {authSegmentIndex === 0 && this.renderSignUp()}
          </FormSection>

          <FormSection name="signIn">
            {authSegmentIndex === 1 && this.renderSignIn()}
          </FormSection>

          {this.props.children}

        </CheckoutBox>
      </div>
    );
  }
});

function mapStateToProps (state) {
  const { error, info, forcePasswordReset, migratedPasswordReset } = getAuthentication(state.user);

  return {
    error,
    info,
    forcePasswordReset,
    migratedPasswordReset
  };
}

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAuthentication);
