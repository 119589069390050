import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getProductsList } from '../reducers/products';

import LoadingSpinner from '../components/meta/loading-spinner';

const ProductContainerRoute = ({ entities, children }) => {
  if (!entities || entities.length === 0) {
    return <LoadingSpinner />;
  }

  return children;
};

ProductContainerRoute.propTypes = {
  entities: PropTypes.array.isRequired,
  children: PropTypes.node
};

function mapStateToProps (state) {
  const { entities } = getProductsList(state.products);

  return {
    entities
  };
}

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainerRoute);
