import _ from 'lodash';
import axios, { CancelToken } from 'axios';
import { CALL_API } from '@meadow/redux-api-middleware';

import {
  SET_PRODUCT_SKU,
  SET_PRODUCT_SKU_WITH_VARIANTS,
  SET_FLOW_PROPERTIES,
  SET_FLOW_LINE_ITEM_VARIANTS,
  SET_FLOW_LINE_ITEM_QUANTITY,
  ADD_FLOW_LINE_ITEM,
  REMOVE_FLOW_LINE_ITEM,
  RESET_FLOW,
  ADD_FLOW_FROM_CART,
  ADD_FLOW_FROM_ORDER_LINE_ITEM,
  ADD_FLOW_FROM_EDITOR,
  FETCH_FLOW_PRICING_REQUEST,
  FETCH_FLOW_PRICING_SUCCESS,
  FETCH_FLOW_PRICING_FAILURE
} from './';
import Moose from '../clients/moose';
import { expandedCartSelector } from '../selectors/cart';
import { expandedFlowSelector } from '../selectors/flow';

let pricingToken = null;

export function retrieveFlowPricing () {
  return (dispatch, getState) => {
    const expandedFlow = expandedFlowSelector(getState());
    const lineItems = _.map(expandedFlow.lineItems, (lineItem) => {
      let parentIndex;

      _.forEach(expandedFlow.lineItems, ({ uniqueId }, idx) => {
        if (uniqueId === lineItem.parentUniqueId) {
          parentIndex = idx;
        }
      });

      _.find(expandedFlow.lineItems, ({ uniqueId }) => {
        return uniqueId === lineItem.uniqueId;
      });

      const userProductId = _.get(expandedFlow, 'userDesign.id');

      return {
        parentIndex,
        productId: lineItem.product.id,
        ...(userProductId ? { userProductId } : {}),
        quantity: lineItem.quantity,
        uniqueId: lineItem.uniqueId,
        variants: _.map(lineItem.variants, ({ variantId, variantOptionId }) => {
          return {
            variantId,
            variantOptionId
          };
        })
      };
    });

    if (pricingToken) {
      pricingToken.cancel();
    }

    pricingToken = CancelToken.source();

    return dispatch({
      [CALL_API]: {
        types: [
          FETCH_FLOW_PRICING_REQUEST,
          FETCH_FLOW_PRICING_SUCCESS,
          FETCH_FLOW_PRICING_FAILURE
        ],
        promise: Moose.fetchPricing({
          giftCertificates: [],
          lineItems,
          source: 'tuxedo',
          cancelToken: pricingToken.token
        }).then(function (data) {
          pricingToken = null;

          return data;
        }).catch(function (err) {
          if (!axios.isCancel(err)) {
            pricingToken = null;

            throw err;
          }
        })
      }
    });
  };
}

export function setProductSku (sku) {
  return (dispatch) => {
    dispatch({
      type: SET_PRODUCT_SKU,
      payload: {
        sku
      }
    });

    dispatch(retrieveFlowPricing());
  };
}

export function setProductSkuWithVariants (sku, variants=[]) {
  return (dispatch) => {
    dispatch({
      type: SET_PRODUCT_SKU_WITH_VARIANTS,
      payload: {
        sku,
        variants
      }
    });

    dispatch(retrieveFlowPricing());
  };
}

export function setFlowProperties (properties) {
  return {
    type: SET_FLOW_PROPERTIES,
    payload: properties
  };
}

export function setFlowLineItemVariants ({ variants, uniqueId }) {
  return {
    type: SET_FLOW_LINE_ITEM_VARIANTS,
    payload: {
      variants,
      uniqueId
    }
  };
}

export function setFlowLineItemQuantity ({ quantity, uniqueId }) {
  return (dispatch) => {
    dispatch({
      type: SET_FLOW_LINE_ITEM_QUANTITY,
      payload: {
        quantity,
        uniqueId
      }
    });

    dispatch(retrieveFlowPricing());
  };
}

export function addFlowLineItem (sku) {
  return (dispatch) => {
    dispatch({
      type: ADD_FLOW_LINE_ITEM,
      payload: {
        sku
      }
    });

    dispatch(retrieveFlowPricing());
  };
}

export function removeFlowLineItem (uniqueId) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_FLOW_LINE_ITEM,
      payload: {
        uniqueId
      }
    });

    dispatch(retrieveFlowPricing());
  };
}

export function resetFlow () {
  return {
    type: RESET_FLOW
  };
}

export function addFlowFromCart ({ uniqueId }) {
  return (dispatch, getState) => {
    const lineItems = expandedCartSelector(getState());
    const parentLineItem = _.find(lineItems, (lineItem) => {
      return lineItem.uniqueId === uniqueId;
    });
    const childLineItems = _.filter(lineItems, (lineItem) => {
      return lineItem.parentUniqueId === uniqueId;
    });

    dispatch({
      type: ADD_FLOW_FROM_CART,
      payload: {
        lineItem: parentLineItem,
        childLineItems
      }
    });

    return dispatch(retrieveFlowPricing());
  };
}

export function addFlowFromEditor ({ canvasState, upload }) {
  return (dispatch, getState) => {
    // The editor can change the product type and sku
    const { editor: { templateOptions, cartUniqueId } } = getState();
    const lineItems = expandedCartSelector(getState());
    const parentLineItem = _.find(lineItems, (lineItem) => {
      return lineItem.uniqueId === cartUniqueId;
    });
    const childLineItems = cartUniqueId 
      ? _.filter(lineItems, (lineItem) => lineItem.parentUniqueId === cartUniqueId)
      : [];
    dispatch({
      type: ADD_FLOW_FROM_EDITOR,
      payload: {
        lineItem: parentLineItem,
        childLineItems,
        templateOptions,
        canvasState,
        upload
      }
    });
    return dispatch(retrieveFlowPricing());
  };
}

export function addFlowFromOrderLineItem (lineItem) {
  return (dispatch) => {
    dispatch({
      type: ADD_FLOW_FROM_ORDER_LINE_ITEM,
      payload: {
        lineItem
      }
    });
    return dispatch(retrieveFlowPricing());
  };
}