import React, { useRef, useEffect } from 'react';
import { getTemplate, applyDesign, applyTemplate } from '../../utils/editor';
import cx from 'classnames';

const StaticPreview = ({ design, maxWidth=100, stroke, image }) => {
  const { product: { sku }, variants, data: { width, height } } = design;
  const canvas = useRef();
  const pixelSize = Math.round(maxWidth / width);
  const canvasWidth = width * pixelSize;
  const canvasHeight = height * pixelSize;
  useEffect(() => {
    async function drawImage() {
      const template = getTemplate(sku, variants);
      applyDesign(design.data, canvas.current);
      await applyTemplate(template, canvas.current);
    }
    if (!image) {
      drawImage();
    }
  }, [ design ])

  if (image) {
    return (<img src={image} className={cx({ 'stroke-image': stroke })} />)
  }

  return (<>
    <canvas ref={canvas} width={canvasWidth} height={canvasHeight} className={cx({ 'stroke-image': stroke })} />
  </>);
};

export default StaticPreview;