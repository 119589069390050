import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import _ from 'lodash';

import Moose from '../../clients/moose';
import { formatPrice } from '../../utils/currency';
import { setCookie } from '../../utils/cookie';

const CartMagicCode = createReactClass({
  propTypes: {
    onAdd: PropTypes.func.isRequired,
    credits: PropTypes.array.isRequired,
    orderAmount: PropTypes.number.isRequired,
    affiliateCode: PropTypes.string
  },

  getInitialState () {
    return {
      open: false,
      showingInfo: false,
      loading: false,
      success: null,
      error: null
    };
  },

  handleCTAClick () {
    this.setState({
      open: !this.state.open
    });
  },

  handleInfoClick () {
    this.setState({
      showingInfo: !this.state.showingInfo
    });
  },

  handleSubmit (event) {
    event.preventDefault();

    const magicCode = this.refs.magicCode.value;

    if (!magicCode) {
      return;
    }

    // if (magicCode === this.props.affiliateCode) {
    //   return this.setState({
    //     loading: false,
    //     success: null,
    //     error: `Sorry, you can't use your own affiliate code.`
    //   });
    // }

    this.setState({
      loading: true,
      success: null,
      error: null
    });

    const existingGCs = this.props.credits.filter(credit => credit.type === 'gift-certificate');
    const existingCoupons = this.props.credits.filter(credit => credit.type === 'coupon');
    const existingAffiliateDiscount = this.props.credits.find(credit => credit.type === 'affiliate-discount');
    const existingExclusiveCoupon = existingCoupons.find(coupon => coupon.doNotCombine);
    const existingDiscountedGC = existingGCs.find(gc => gc.discounted);

    Moose.fetchMagicCode(magicCode).then((data) => {

      let descriptionOverride = null;
      let codeType = data.type === 'coupon' ? 'coupon' : 'gift certificate';

      if (data.type === 'affiliate-code') {
        if (this.props.credits.length > 0) {
          return this.setState({
            loading: false,
            success: null,
            error: `Sorry, you can't use an affiliate discount with another discount.`
          });
        } else {
          codeType = 'discount';
          setCookie('affiliate', [data.magicCode, data.click].join(':'), 30);
          if (data.funraiser) {
            descriptionOverride = `A funraiser code was applied to your cart and 20% will go to your cause.`
          }
        }
      }

      if (data.type === 'coupon' && existingExclusiveCoupon) {
        return this.setState({
          loading: false,
          success: null,
          error: `The magic code ${existingExclusiveCoupon.magicCode} cannot be combined with other offers.`
        });
      }

      if (data.type === 'coupon' && existingDiscountedGC) {
        return this.setState({
          loading: false,
          success: null,
          error: `The magic code ${magicCode} cannot be used with ${existingDiscountedGC.magicCode}.`
        });
      }

      if (data.type === 'gift-certificate' && data.discounted && existingCoupons.length > 0) {
        return this.setState({
          loading: false,
          success: null,
          error: `The magic code ${magicCode} cannot be used with any other discounts.`
        });
      }

      if (_.isNumber(data.minimumOrderAmount)) {
        if (this.props.orderAmount < data.minimumOrderAmount) {
          return this.setState({
            loading: false,
            success: null,
            error: `Your order does not meet the minimum amount of ${formatPrice(data.minimumOrderAmount)} for this offer.`
          });
        }
      }

      if (_.isNumber(data.maximumOrderAmount)) {
        if (this.props.orderAmount > data.maximumOrderAmount) {
          return this.setState({
            loading: false,
            success: null,
            error: `Your order exceeds the maximum amount of ${formatPrice(data.maximumOrderAmount)} for this offer.`
          });
        }
      }

      if (data.doNotCombine && existingCoupons.length > 0) {
        return this.setState({
          loading: false,
          success: null,
          error: `The magic code ${data.magicCode} cannot be combined with other offers.`
        });
      }

      const isPercentOff = data.couponType === 'percentage';
      const discountAmount = isPercentOff ? `${data.amount}% off` : formatPrice(data.amount);

      const affiliateRemoved = existingAffiliateDiscount 
        ? ' Affiliate discount was removed.'
        : '';

      this.setState({
        loading: false,
        success: descriptionOverride || `A ${codeType} of ${discountAmount} is applied to your cart.${affiliateRemoved}`,
        error: null
      });

      this.refs.magicCode.value = '';
      this.props.onAdd(data.magicCode);
    }).catch((e) => {
      this.setState({
        loading: false,
        success: null,
        error: 'That magic code has either expired or does not exist.'
      });
    });
  },

  renderInfo ({ position, targetRect, popoverRect }) {
    return (
      <ArrowContainer
        arrowColor="#eaeaea"
        position={position}
        targetRect={targetRect}
        popoverRect={popoverRect}>
        <div className="cart-magic-code__info-popover">A Magic Code is the digital code you receive when someone 
          has given you a gift certificate. Occasionally, they're promo 
          codes we share through our email newsletters and social pages.</div>
      </ArrowContainer>
    );
  },

  renderOpen () {
    if (!this.state.open) {
      return null;
    }

    return (
      <form className="cart-magic-code__form" onSubmit={this.handleSubmit}>
        <input type="text" className="input" placeholder="Magic Code" ref="magicCode" />

        {!this.state.loading && <button type="submit" className="button button--outline-primary">Apply</button>}
        {this.state.loading && <div className="cart-magic-code__loading">Applying...</div>}
      </form>
    );
  },

  render () {
    const { success, error } = this.state;

    return (
      <div className="cart-magic-code">
        <h4 className="cart-magic-code__cta">
          <div className="cart-magic-code__icon" onClick={this.handleCTAClick}/>
          <span onClick={this.handleCTAClick}>Have a magic code?</span>
          <Popover position="top" content={this.renderInfo} isOpen={this.state.showingInfo}>
            <div className="cart-magic-code__info" onClick={this.handleInfoClick}>
              <i className="fa fa-question-circle" />
            </div>
          </Popover>
        </h4>

        {this.renderOpen()}
        {success && <div className="cart-magic-code__success">{success}</div>}
        {error && <div className="cart-magic-code__error">{error}</div>}
      </div>
    );
  }
});

export default CartMagicCode;
