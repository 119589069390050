import React from 'react';

const CheckoutError = ({ children }) => {
  return (<div className="checkout-error">
    <p className="checkout-error__message">{children}</p>
    <div className="checkout-error__hint">
      Having trouble? Please let us know how we can 
      help at <a href="mailto:hello@picturethisclothing.com">hello@picturethisclothing.com</a>.
    </div>
  </div>);
};

export default CheckoutError;
