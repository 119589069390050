import React from 'react';
import { useWaitForImage } from '../../utils/hooks';
import { canRenderInBrowser } from '../../utils/image';
import StaticPreview from '../editor/static-preview';
import _ from 'lodash';

const ProductImageThumbnail = ({ image, design, children, onClick }) => {
  const imageUrl = _.get(image, 'upload.thumbnails.small') || _.get(image, 'upload.thumbnails.card') || _.get(image, 'upload.url');
  const canDisplay = canRenderInBrowser(imageUrl);
  const isLoading = useWaitForImage(imageUrl);
  const style = isLoading || !canDisplay ? {} : {
    backgroundImage: `url(${imageUrl})`
  };

  const Image = () => {
    return (<>
      {image.pixelDesignId && <StaticPreview design={design} maxWidth={128} stroke />}
      {children}
    </>);
  };

  const thumbnailContent = (<>
    {isLoading ? (<i className="fa fa-spin fa-circle-o-notch" />) : <Image />}
  </>);

  if (onClick) {
    return (<button className="user-store__product-thumbnail" style={style} onClick={onClick}>
      {thumbnailContent}
    </button>);
  } else {
    return (<div className="user-store__product-thumbnail" style={style}>
      {thumbnailContent}
    </div>);
  }
};

export default ProductImageThumbnail;