import { PALETTE_COLORS, BACKGROUND_ENTRY } from '../../config';
const GridPaint = require('gridpaint');

export const createPainter = ({ width, height, cellWidth }) => {
  const painter = new GridPaint({ 
    width, 
    height, 
    cellWidth, 
    palette: PALETTE_COLORS,
    backgroundColour: BACKGROUND_ENTRY 
  });
  painter.grid = true;
  painter.background = false;
  painter.tool = 'pencil';
  painter.colour = 0;
  painter.showCursor = false;
  return painter;
}