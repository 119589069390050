import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import Moose from '../clients/moose';
import { setLoginNote } from '../actions/user';

import Header from '../components/structure/header';
import ForgotPasswordRequest from '../components/forgot-password/forgot-password-request';
import ForgotPasswordReset from '../components/forgot-password/forgot-password-reset';
import { Redirect } from 'react-router-dom';

const ForgotPasswordRoute = createReactClass({
  propTypes: {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    resetToken: PropTypes.string
  },

  getInitialState() {
    return { 
      requestSent: false,
      errorMessage: null,
      passwordWasReset: false
    };
  },

  handleResetRequest (values) {
    const { from } = this.props.location.state || {};
    Moose.requestPasswordReset( values.email, from ).then(result => {
      this.setState({ requestSent: true });
    });
  },

  handlePasswordChange (values) {
    const { resetToken, setLoginNote } = this.props;
    Moose.resetPassword( resetToken, values.newPassword ).then(result => {
      if (result.errorMessage) {
        return this.setState({ errorMessage: result.errorMessage });
      }
      if (result.updated) {
        setLoginNote("Please log in again with your new password.");
        this.setState({ 
          passwordWasReset: true,
          resetFrom: result.from
        });
      }
    });
  },

  render () {
    const { requestSent, errorMessage, passwordWasReset, resetFrom } = this.state;
    const { resetToken } = this.props;
    if ( passwordWasReset ) {
      const pathname = { 
        'sign-in': '/sign-in', 
        'checkout': '/checkout',
        'affiliate': '/affiliate' 
      }[ resetFrom ] || '/sign-in';
      const loginUrl = {
        pathname,
        state: { existingUser: true }
      }
      return <Redirect to={loginUrl} />
    }
    return (
      <div className="forgot-password__container">
        <Header />
        <div className="forgot-password clearfix">
          {!resetToken && <ForgotPasswordRequest onSubmit={this.handleResetRequest} requestSent={requestSent} />}
          {resetToken && <ForgotPasswordReset onSubmit={this.handlePasswordChange} resetToken={resetToken} errorMessage={errorMessage} />}
        </div>
      </div>
    );
  }
});

function mapStateToProps (state, props) {
  const query = new URLSearchParams(props.location.search);
  return {
    resetToken: query.get('key')
  };
}

const mapDispatchToProps = {
  setLoginNote
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordRoute);