import React from 'react';

export const STYLES = {
  orange: {
    color1: "#FF8500", 
    color2: "#FF6F00"
  },
  green: {
    color1: "#04D554", 
    color2: "#08DD1D"
  },
  magenta: {
    color1: "#EC3177", 
    color2: "#D44380"
  },
  teal: {
    color1: "#1CDBB1", 
    color2: "#2FE7BE"
  }
}

const BackgroundOverlay = ({ styleName, styleColor, backgroundImage, isLoading, children }) => {
  const styleData = STYLES[styleName] || (styleColor ? { color1: styleColor, color2: styleColor } : STYLES['orange']);
  const style = backgroundImage && !isLoading
    ? {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center 20%'
    }
    : { 
      backgroundImage: `linear-gradient(180deg, ${styleData.color2} 0%, ${styleData.color1} 100%)` 
    };
  const shapeStyle = {
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    bottom: '-1px',
  };
  const svgStyle = {
    width: 'calc(200% + 1.3px)',
    height: '470px',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)'
  };
  const pathStyle = {
    fill: '#f7f7f7'
  };
  return (<div style={style} className="background-overlay">
    <div style={shapeStyle}>
      <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
        <path style={pathStyle} d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"></path>
      </svg>
    </div>
    {children}
  </div>);
}

export default BackgroundOverlay;