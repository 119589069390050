import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { fetchUserIfNeeded } from '../../actions/user';
import Moose from '../../clients/moose';
import { toast, updateToast } from '../../utils/toast';

import MyAccountInfo from '../../components/my-account/my-account-info';
import MyAccountPassword from '../../components/my-account/my-account-password';
import Header from '../../components/structure/header';
import MyAccountHeader from '../../components/my-account/my-account-header';
import BackgroundOverlay from '../../components/structure/background-overlay';

const DetailsRoute = createReactClass({

  propTypes: {
    user: PropTypes.object.isRequired,
    fetchUserIfNeeded: PropTypes.func.isRequired
  },

  handleUpdateInfo(values) {
    const toastId = toast('Updating your info..');
    Moose.updateUser( values ).then(res => {
      this.props.fetchUserIfNeeded().then(() => {
        updateToast(toastId, 'Your info has been updated!');
      });
    });
  },

  handlePasswordChange(values) {
    const toastId = toast('Updating your password..');
    Moose.changePassword( values ).then(res => {
      this.props.fetchUserIfNeeded().then(() => {
        updateToast(toastId, 'Your password has been updated!');
      });
    }).catch(e => {
      updateToast(toastId, e.message, {
        type: toast.TYPE.ERROR,
        autoClose: false
      });
    });
  },
  
  render() {
    const { user } = this.props;
    return (<>
      <Header />
      <BackgroundOverlay />
      <div className="my-account clearfix">
        <MyAccountHeader  />
        <div className="my-account__content">
          <div className="my-account__details clearfix">
            <MyAccountInfo user={user} onSubmit={this.handleUpdateInfo} />
            <MyAccountPassword onSubmit={this.handlePasswordChange} />
          </div>
        </div>
      </div>
    </>);
  }

});

function mapStateToProps (state) {
  return {
  };
}

const mapDispatchToProps = {
  fetchUserIfNeeded
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsRoute);
