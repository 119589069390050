import React from 'react';

const AccentStyles = ({ accentColor }) => {
  return (<style type="text/css">{`
    .user-product-details a { color: #${accentColor}; }
    .user-product-details .view-size-chart { color: #${accentColor}; }
    .user-product-details .primary { background-color: #${accentColor}; }
    .user-product-details .variant-selector .Select-control {
      border: 2px solid #${accentColor};
    }
    .user-product-details .variant-selector .Select-menu-outer {
      border: 1px solid #${accentColor};
    }
  `}</style>);
};
export default AccentStyles;