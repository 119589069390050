import React from 'react';
import Modal from 'react-modal';

const AffiliateModal = ({ children, show=false, onToggle=()=>{}}) => {
  return (<Modal 
    className="modal-content modal-fullsize affiliate__modal" 
    overlayClassName="modal fade in"
    onRequestClose={onToggle}
    isOpen={show}>
    <button onClick={onToggle} 
      className="close-modal"><i className="fa fa-close" /></button>
    <div className="modal-body">
      {children}
    </div>
  </Modal>);
};

export default AffiliateModal;
