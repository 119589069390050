export const sizeTableData = {
  'PTL01': {
    headers: [ 'Waist', 'Hip', 'Inseam' ],
    sizes: {
      'C02':  [ '20″',   '21″',   '17″'   ],
      'C03':  [ '20.5″', '22″',   '18″'   ],
      'C04':  [ '21″',   '23″',   '19″'   ],
      'C05':  [ '21.5″', '24″',   '20.5″' ],
      'C06':  [ '22″',   '25″',   '22″'   ],
      'C07':  [ '22.5″', '26.5″', '23.5″' ],
      'C08':  [ '23″',   '28″',   '24.5″' ],
      'C10':  [ '24″',   '30″',   '25.5″' ],
      'C12':  [ '25″',   '32″',   '26″'   ],
      'C14':  [ '26.5″', '34″',   '27″'   ],
      'C16':  [ '28″',   '36″',   '27.5″' ],
      'C18':  [ '30″',   '38″',   '28″'   ],
      'WXS':  [ '28″',   '34″',   '27″'   ],
      'WS':   [ '30″',   '36″',   '27″'   ],
      'WM':   [ '32″',   '38″',   '27″'   ],
      'WL':   [ '34″',   '40″',   '27″'   ],
      'WXL':  [ '36″',   '42″',   '27.1″' ],
      'W2XL': [ '38″',   '44″',   '27.1″' ],
      'W3XL': [ '40″',   '46″',   '27.3″' ],
      'W4XL': [ '42″',   '48″',   '27.3″' ],
      'W5XL': [ '44″',   '50″',   '27.5″' ]
    },
    dollSize: [ '10.5″', '13″',   '7.5″'  ]
  }
};