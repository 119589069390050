import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';

import { getCurrentUser } from '../reducers/user';
import { firstNameFromUser, getAffiliateLinkForUser, getAffiliateCodeForUser } from '../utils/users';

import Footer from '../components/structure/footer';
import Header from '../components/structure/header';

import icon from '../assets/logo-icon.png';

const OrderConfirmationRoute = createReactClass({
  propTypes: {
    entity: PropTypes.object
  },

  render () {
    const { user } = this.props;
    const customerName = user ? firstNameFromUser(user) : 'new friend';
    return (
      <div className="order-confirmation">
        <Header />

        <div className="order-confirmation__container clearfix">
          <h2 className="order-confirmation__header header">Thank You! We've received your order.</h2>

          <div className="order-confirmation__message">
            <p>Thank you so much, {customerName}!</p>
            <br />
            <p>We've sent an order confirmation email to you, summary of what you purchased is in there, along with our contact info in case you need to reach us for anything.</p>
            <br />
            <h3>🎨 Hang On To Your Artwork</h3>
            <p>Sometimes the images uploaded with your order don't meet our image requirements and we need to ask you for photo re-takes. Part of the magical experience for kids is seeing the finished product side-by-side with the paper template, so hang onto it for now!</p>
            <br />
            <h3>⏱ How Long Does It Take?</h3>
            <p>If your image meets our image requirements, orders typically ship inside of 10 days. If we need photo re-takes, we reach out via email within the first 2-4 days (keep an eye on your spam/junk boxes, just in case!)</p>
            <br />
            <p>With Care &amp; Imagination,</p>
            <p><strong>The Team at Picture This Clothing</strong></p>
          </div>

          <div className="order-confirmation__icon">
            <img src={icon} alt="Paintbrush and magic wand" />
          </div>

        </div>

        <div className="order-confirmation__quote">
          <blockquote>
            “Every child is an artist, the problem is staying an artist when you grow up”
          </blockquote>
          <cite>
          – Pablo Picasso
          </cite>
        </div>

        <Footer />
      </div>
    );
  }
});

function mapStateToProps (state) {
  const { entity } = getCurrentUser(state.user);

  return {
    user: entity,
    affiliateLink: getAffiliateLinkForUser(entity),
    affiliateCode: getAffiliateCodeForUser(entity)
  };
}

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationRoute);
