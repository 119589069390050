import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';

import { addFlowToCart } from '../actions/cart';
import { addFlowLineItem, retrieveFlowPricing } from '../actions/flow';
import { getFlowPricing } from '../reducers/flow';
import { getProductsList } from '../reducers/products';
import { expandedFlowSelector } from '../selectors/flow';
import { firstNameFromDesignersName } from '../utils/products';
import { isMobileApp, sendAppMessage } from '../utils/mobile-app';

import ContinueBar from '../components/products/continue-bar';
import ReviewAddition from '../components/products/review-addition';
import ReviewLineItems from '../components/products/review-line-items';
import HeaderBar from '../components/structure/header-bar';


import heroImage from '../assets/products/review-hero.png';

const ReviewRoute = createReactClass({
  propTypes: {
    history: PropTypes.object.isRequired,
    flow: PropTypes.object.isRequired,
    pricing: PropTypes.object,
    products: PropTypes.array.isRequired,
    addFlowLineItem: PropTypes.func.isRequired,
    addFlowToCart: PropTypes.func.isRequired,
    retrieveFlowPricing: PropTypes.func.isRequired
  },

  componentDidMount () {
    const { designersName, lineItems, upload } = this.props.flow;

    if (!designersName || !upload || lineItems[0].variants.length === 0) {
      this.props.history.replace('/product/upload');
    }

    this.props.retrieveFlowPricing();
  },

  handleBackClick () {
    this.props.history.goBack();
  },

  handleContinueClick () {
    this.props.addFlowToCart();
    if (isMobileApp()) {
      sendAppMessage('goToCart');
    } else {
      this.props.history.push('/cart');
    }
  },

  handleAddProduct (sku) {
    this.props.addFlowLineItem(sku);
  },

  render () {
    const { designersName, lineItems, userDesign } = this.props.flow;
    const { product } = lineItems[0];

    let actionEnabled = true;

    _.forEach(lineItems, (lineItem) => {
      if (lineItem.variants.length !== lineItem.product.variants.length) {
        actionEnabled = false;
      }
    });

    const showAdditions = product && ['PTD01', 'PTS01', 'PTB01', 'PTL01', 'PTFC01'].includes(product.sku);

    return (
      <div className="review">
        <HeaderBar />

        <div className="review__content">
          <div className="review__hero">
            <div className="review__hero__image">
              <img src={heroImage} alt="Review Product" />
            </div>

            <div className="review__hero__text">
              {userDesign 
                ? (<h2 className="header header--main review__title">Your selections are locked in!<span role="img" aria-label="*clapping*">🙌</span></h2>) 
                : (<h2 className="header header--main review__title">{firstNameFromDesignersName(designersName)}'s design is uploaded! <span role="img" aria-label="*clapping*">🙌</span></h2>)}

              {showAdditions
                ? (<p className="review__hero__descriptor">Add on some matching products and save!</p>)
                : (<p className="review__hero__descriptor">Let's make sure everything looks correct!</p>)}
            </div>
          </div>

          <ReviewAddition
            parentProduct={product}
            products={this.props.products}
            onAddProduct={this.handleAddProduct} />

          <ReviewLineItems flow={this.props.flow} />
        </div>

        <ContinueBar
          actionText="Add to cart!"
          actionEnabled={actionEnabled}
          onBackClick={this.handleBackClick}
          onContinueClick={this.handleContinueClick} />
      </div>
    );
  }
});

function mapStateToProps (state) {
  const { entities: products } = getProductsList(state.products);
  const flow = expandedFlowSelector(state);

  return {
    flow,
    pricing: getFlowPricing(state.flow).entity,
    products
  };
}

const mapDispatchToProps = {
  addFlowLineItem,
  addFlowToCart,
  retrieveFlowPricing
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewRoute);
