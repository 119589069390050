import _ from 'lodash';
import { combineReducers } from 'redux';
import { updateProductData } from '../utils/tracking';

import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE
} from '../actions';

const listInitialState = {
  entities: [],
  isFetching: false,
  error: null
};

const list = function (state = listInitialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };

    case FETCH_PRODUCTS_SUCCESS:
      updateProductData(action.payload);
      return {
        ...state,
        entities: action.payload,
        isFetching: false,
        error: null
      };

    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default combineReducers({
  list
});

export function getProductsList (state) {
  return state.list;
}

export function getProductById (state, productId) {
  return _.find(state.list.entities, function (product) {
    return product.id === productId;
  });
}

export function getProductBySku (state, sku) {
  return _.find(state.list.entities, function (product) {
    return product.sku === sku;
  });
}
