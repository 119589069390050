import _ from 'lodash';
import { createSelector } from 'reselect';

const lineItemsSelector = state => state.cart.lineItems;
const pricingSelector = state => state.cart.pricing;
const productsSelector = state => state.products.list.entities;

export const expandedCartSelector = createSelector(
  lineItemsSelector,
  pricingSelector,
  productsSelector,
  (lineItems, pricing, products) => {
    const clonedLineItems = _.cloneDeep(lineItems);

    return _.map(clonedLineItems, (lineItem) => {
      const product = _.find(products, ({ id }) => {
        return lineItem.productId === id;
      });

      const { entity } = pricing;
      const lineItemData = {
        ...lineItem,
        product
      };

      if (product) {
        _.forEach(lineItem.variants, function (variantItem) {
          const { variantId, variantOptionId } = variantItem;

          variantItem.variant = _.find(product.variants, function ({ id }) {
            return id === variantId;
          });

          variantItem.variantOption = _.find(variantItem.variant.options, function ({ id }) {
            return id === variantOptionId;
          });
        });
      }

      if (entity) {
        lineItemData.pricing = _.find(entity.lineItems, function ({ uniqueId }) {
          return lineItem.uniqueId === uniqueId;
        });
      }
      return lineItemData;
    });
  }
);

