import { createStore, applyMiddleware, compose } from 'redux';
import immutableInvariant from 'redux-immutable-state-invariant';
import { createLogger } from 'redux-logger';
import { persistStore, autoRehydrate } from 'redux-persist';
// import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import createFilter from 'redux-persist-transform-filter';
import thunkMiddleware from 'redux-thunk';

import apiMiddleware from '@meadow/redux-api-middleware';
import createEnsureFSAMiddleware from '@meadow/redux-ensure-fsa';

import { setRehydrated } from './actions/app';
import { retrievePricingIfNeeded } from './actions/cart';
import { fetchUserIfNeeded } from './actions/user';
import reducer from './reducers';

// const stateSyncMiddleware = createStateSyncMiddleware({
//   whitelist: [ 
//     'RESET_FLOW', 
//     'FETCH_PRICING_SUCCESS', 
//     'ADD_PRODUCT_TO_CART', 
//     'ADD_FLOW_FROM_CART',
//     'CLEAR_CART'
//   ]
// });

let middleware = [thunkMiddleware, apiMiddleware ];

if (process.env.NODE_ENV !== 'production') {
  const loggerMiddleware = createLogger({
    collapsed: true
  });

  const ensureFSAMiddleware = createEnsureFSAMiddleware();

  middleware = [immutableInvariant(), ...middleware, ensureFSAMiddleware, loggerMiddleware];
}

export default function configureStore (initialState) {
  const store = createStore(
    reducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      autoRehydrate(),
      window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV !== 'production'
        ? window.__REDUX_DEVTOOLS_EXTENSION__() 
        : (a) => a
    )
  );


  const persistConfig = {
    whitelist: ['cart', 'flow', 'giftFlow', 'user', 'contest', 'editor', 'app'],
    transforms: [
      createFilter('app', []),
      createFilter('cart', ['giftCertificates', 'lineItems', 'magicCodes', 'unappliedStoreCredit']),
      createFilter('flow', ['data']),
      createFilter('user', ['token']),
      createFilter('contest', ['entry']),
      createFilter('editor', ['pixelWidth', 'showGrid', 'templateOptions', 'canvasState', 'loadedDesign', 'cartUniqueId'])
    ]
  };

  // initStateWithPrevTab(store);
  

  const persistor = persistStore(store, persistConfig, function () {
    // initMessageListener(store);
    store.dispatch(fetchUserIfNeeded());
    store.dispatch(setRehydrated());
    store.dispatch(retrievePricingIfNeeded());
  });

  return store;
}
