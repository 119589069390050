import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setColor, setColorBank } from '../../actions/editor';
import { PALETTE_COLORS, COLOR_BANK_SIZE } from '../../config';
import { ReactComponent as NextSVG } from '../../assets/icons/arrowb-right.svg';
import { ReactComponent as PrevSVG } from '../../assets/icons/arrowb-left.svg';
import Button from './button';

const ColorBankButtons = ({ next, prev }) => {
  const dispatch = useDispatch();
  const currentBank = useSelector(state => state.editor.colorBank);
  const activeColor = useSelector(state => state.editor.color);
  const numBanks = Math.ceil(PALETTE_COLORS.length / COLOR_BANK_SIZE);
  const nextBank = () => {
    dispatch(setColorBank(currentBank + 1));
    dispatch(setColor(activeColor + COLOR_BANK_SIZE));
  }
  const prevBank = () => {
    dispatch(setColorBank(currentBank - 1));
    dispatch(setColor(activeColor - COLOR_BANK_SIZE));
  }
  return (<>
    {prev && (<Button className="color-bank-button" onClick={prevBank} disabled={currentBank === 0}>
      <PrevSVG />
    </Button>)}
    {next && (<Button className="color-bank-button" onClick={nextBank} disabled={currentBank >= numBanks - 1}>
      <NextSVG />
    </Button>)}
  </>);
};

export default ColorBankButtons;