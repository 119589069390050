import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import MobileLink from '../../components/mobile-app/Link';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import { formatPrice } from '../../utils/currency';
import { beforeTaxCredits, afterTaxCredits } from '../../utils/checkout';

const CartPricing = createReactClass({

  propTypes: {
    pricing: PropTypes.shape({
      credits: PropTypes.array.isRequired,
      shippingDescription: PropTypes.string.isRequired,
      shippingPrice: PropTypes.number.isRequired,
      subtotalPrice: PropTypes.number.isRequired,
      finalPrice: PropTypes.number.isRequired
    }),
    onRemoveCode: PropTypes.func,
    onApplyStoreCredit: PropTypes.func
  },

  getInitialState () {
    return {
      showingFunraiserInfo: false,
    };
  },

  handleFunraiserInfoClick() {
    this.setState({
      showingFunraiserInfo: !this.state.showingFunraiserInfo
    });
  },

  renderFunraiserInfo({ position, targetRect, popoverRect }) {
    return (
      <ArrowContainer
        arrowColor="#eaeaea"
        position={position}
        targetRect={targetRect}
        popoverRect={popoverRect}>
        <div className="cart-magic-code__info-popover">This Fun-Raiser code does not give you a discount, but 20% of this purchase will go toward a cause.</div>
      </ArrowContainer>
    );
  },

  renderCredit(credit, idx) {
    const { onRemoveCode } = this.props;
    let creditNote = null;
    let label = credit.magicCode || credit.description;
    if (credit.type === 'affiliate-discount') {
      if (credit.funraiser) {
        creditNote = (<Popover 
          position="top" 
          content={this.renderFunraiserInfo} 
          isOpen={this.state.showingFunraiserInfo}>
            <i className="fa fa-question-circle funraiser-info" onClick={this.handleFunraiserInfoClick} />
        </Popover>);
      } else {
        label = `${credit.magicCode} (${credit.percentOff}%)`;
      }
    }
    return (
      <div className="cart-pricing__line cart-pricing__line-credit" key={`c${idx}`}>
        <span className="cart-pricing__line-label">
          {label} {creditNote}
        </span>
        <button type="button" className="button--reset cart-pricing__line-action" 
            onClick={_.partial(onRemoveCode, credit)}><i className="icon icon-delete" /></button>
        <span className="cart-pricing__line-value">-{formatPrice(credit.amountUsed)}</span>
      </div>
    );
  },

  render () {
    const { pricing, onApplyStoreCredit } = this.props;
    const { credits, availableCredits, finalPrice, shippingDescription, shippingPrice, subtotalPrice, salesTax, lineItems } = pricing;

    let storeCreditInfo = null;
    if (availableCredits.length > 0) {
      const availableCreditAmount = availableCredits.reduce((total, credit) => {
        return total + credit.amountUsed;
      }, 0);
      storeCreditInfo = (<div className="cart-pricing__store-credit-alert">
      <button type="button" className="button--reset" 
        onClick={onApplyStoreCredit}><i className="icon icon-attention" /> Apply {formatPrice(availableCreditAmount)} in available store credit?</button>
      </div>);
    }
  
    const showShipping = lineItems && lineItems.length > 0;
  
    return (
      <div className="cart-pricing">
        <div className="cart-pricing__line">
          <span className="cart-pricing__line-label">Subtotal</span>
          <span className="cart-pricing__line-value">{formatPrice(subtotalPrice)}</span>
        </div>
  
        {beforeTaxCredits(credits).map(this.renderCredit)}

        {salesTax ? <div className="cart-pricing__line">
          <span className="cart-pricing__line-label">Sales Tax</span>
          <span className="cart-pricing__line-value">{formatPrice(salesTax)}</span>
        </div> : null}
  
        {showShipping ? <div className="cart-pricing__line">
          <span className="cart-pricing__line-label">{shippingDescription}</span>
          <span className="cart-pricing__line-value">{formatPrice(shippingPrice)}</span>
        </div> : null}

        {afterTaxCredits(credits).map(this.renderCredit)}
  
        <div className="cart-pricing__line cart-pricing__total">
          <span className="cart-pricing__line-label">Total</span>
          <span className="cart-pricing__line-value">{formatPrice(finalPrice)}</span>
        </div>
  
         {storeCreditInfo}
  
        <MobileLink to="/checkout" className="button primary cart__checkout">Checkout</MobileLink>
        <a href="/home" className="button white cart-pricing__continue">Continue Shopping</a>
      </div>
    );
  }

});

export default CartPricing;
