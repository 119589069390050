import _ from 'lodash';

export function flattenedLineItems (order) {
  const lineItems = [];

  _.forEach(order.lineItems, (lineItem) => {
    lineItems.push(lineItem);

    _.forEach(lineItem.bundledLineItems, (bundledLineItem) => {
      const newLineItem = Object.assign({
        isAddon: true,
        upload: lineItem.upload
      }, bundledLineItem);
      lineItems.push(newLineItem);
    });
  });

  return lineItems;
}

export function creditDisplayName (credit) {
  switch (credit.type) {
    case 'gift-certificate':
      return 'Gift Certificate';
    case 'store-credit':
      return 'Store Credit';
    case 'affiliate-payment':
      return 'Affiliate Credit';
    case 'order-refund':
      return 'Order Credit'; 
    case 'coupon':
      return 'Coupon';
    case 'affiliate-discount':
      return 'Affiliate Discount';
    default:
      return 'Discount';
  }
}

export function canReorderLineItem (lineItem) {
  const { isAddon } = lineItem;
  return !isAddon;
}