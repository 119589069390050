import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PIXEL_WIDTH_OPTIONS } from '../../config';
import { setPixelWidth } from '../../actions/editor';
import cx from 'classnames';
import Button from './button';

const GridSizeSelector = ({ wide=false }) => {
  const dispatch = useDispatch();
  const pixelWidth = useSelector(state => state.editor.pixelWidth);

  const toggleSize = () => {
    let nextOption = PIXEL_WIDTH_OPTIONS.indexOf(pixelWidth) + 1;
    if (nextOption >= PIXEL_WIDTH_OPTIONS.length) nextOption = 0;
    dispatch(setPixelWidth(PIXEL_WIDTH_OPTIONS[nextOption]));
  };

  const setSize = (widthOption) => dispatch(setPixelWidth(widthOption));

  return wide 
    ? (<div className="grid-size-selector is-wide">
        {PIXEL_WIDTH_OPTIONS.map(widthOption => (
          <Button onClick={() => setSize(widthOption)} title={`${widthOption}px`} key={widthOption} 
           className={cx({ 'is-active': widthOption === pixelWidth})}>
           {widthOption}
          </Button>))}
      </div>)
    : (<div className="grid-size-selector">
        <Button onClick={() => toggleSize()} title="Toggle Size">
          {pixelWidth}
        </Button>
      </div>);
};

export default GridSizeSelector;