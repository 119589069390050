import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { reduxForm } from 'redux-form';
import {
  combineValidators,
  isRequired,
  isRequiredIf,
} from 'revalidate';

import CheckoutBilling from './checkout-billing';
import CheckoutShipping from './checkout-shipping';
import CheckoutInternational from './checkout-international';
import CheckoutTotals from './checkout-totals';
import LoadingSpinner from '../meta/loading-spinner';
import _ from 'lodash';

const CheckoutForm = createReactClass({
  propTypes: {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    user: PropTypes.object,
    billingError: PropTypes.string,
    stripeComplete: PropTypes.bool,
    stripeSources: PropTypes.array.isRequired,
    onStripeChange: PropTypes.func.isRequired,
    showBilling: PropTypes.bool.isRequired,
    showShipping: PropTypes.bool.isRequired,
    isInternational: PropTypes.bool
  },

  renderOverlay () {
    if (this.props.submitting) {
      return (
        <div className="checkout__overlay">
          <div className="checkout__logo" />

          <p className="checkout__overlay-message">Processing Order...</p>

          <LoadingSpinner />
        </div>
      );
    }
  },

  render () {
    const { handleSubmit, billingError, stripeComplete, stripeSources, showBilling, showShipping, isInternational } = this.props;
    
    return (
      <form className="checkout-form" onSubmit={handleSubmit}>

        {showShipping && <CheckoutShipping />}

        <CheckoutTotals />

        {showBilling && <CheckoutBilling 
          error={billingError}
          stripeComplete={stripeComplete} 
          onStripeChange={this.props.onStripeChange} 
          stripeSources={stripeSources} />}

        {isInternational && <CheckoutInternational />}

        <button className="button primary text--light checkout__purchase" type="submit">Complete Purchase</button>

        {this.renderOverlay()}
      </form>
    );
  }
});

const validate = combineValidators({
  'shipping.name': isRequired('Name'),
  'shipping.street1': isRequired('Street'),
  'shipping.city': isRequired('City'),
  'shipping.postalCode': isRequired('Postal code'),
  'shipping.country': isRequired('Country'),
  'shipping.phone': isRequired('Phone'),
  'internationalDisclaimer': isRequiredIf(values => values && _.get(values, 'shipping.country') !== 'US')({ 
    message: 'Please acknowledge the international shipping agreement.' 
  })
});

export default reduxForm({
  enableReinitialize: true,
  form: 'checkout',
  onSubmitFail: () => {
    window.scrollTo(0, 0);
  },
  validate,
  shouldValidate: () => true
})(CheckoutForm);
