import React from 'react';
import Nav from '../../components/my-account/my-account-nav';
import Header from '../../components/structure/header';
import MyAccountHeader from '../../components/my-account/my-account-header';
import BackgroundOverlay from '../../components/structure/background-overlay';

const IndexRoute = ({ user }) => {
  return (<>
    <Header />
    <BackgroundOverlay />
    <div className="my-account clearfix">
      <MyAccountHeader title="My Account" />
      <div className="my-account__content my-account__menu">
        <Nav user={user} />
      </div>
    </div>
  </>);
}

export default IndexRoute;