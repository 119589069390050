import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const ReportTable = createReactClass({
  propTypes: {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    columns: PropTypes.array,
    cellFilter: PropTypes.func,
    data: PropTypes.array
  },

  getInitialState (props) {
    return {
    };
  },

  renderColumns() {
    const { columns } = this.props;
    return columns.map((col,i) => {
      return <th key={i}>{col}</th>
    })
  },

  renderData() {
    const { data, cellFilter } = this.props;
    return data && data.map((rows,i) => {
      return (<tr key={i}>
        {rows.map((col,j) => {
          const value = cellFilter ? cellFilter(i,j,col) : col;
          return (<td key={j}>{value}</td>)
        })
        }
      </tr>);
    })
  },

  render () {
    const { title, data } = this.props;
    const className = ['report-table', this.props.className].join(' ');
    if (!data || data.length === 0) {
      return null;
    }
    return (
      <div className={className}>
        <h3 className="header">{title}</h3>
        <table>
          <thead>
            <tr>{this.renderColumns()}</tr>
          </thead>
          <tbody>
            {this.renderData()}
          </tbody>
        </table>
      </div>
    );
  }
});

export default ReportTable;
