import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import SizeChart from './size-chart';

Modal.setAppElement('#root');
export const HAS_SIZE_CHART = ['PTD01', 'PTS01', 'PTL01', 'PTB01'];

class ViewSizeChart extends React.Component {

  static propTypes = {
    sku: PropTypes.string
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal () {
    this.setState({ isOpen: true });
  }

  closeModal () {
    this.setState({ isOpen: false });
  }

  render() {
    const { className='view-size-chart', sku } = this.props;

    const buttonClass = [ 'button', className, 'text--light' ].join(' ');

    if (!HAS_SIZE_CHART.includes(sku)) {
      return null;
    }

    return (
      <React.Fragment>
        <button className={buttonClass} type="button" onClick={this.openModal}>
          {this.props.children || (<><i className="icon icon-size" /> View Size Chart</>)}
        </button>
        <Modal
          className="modal-content modal-fullsize"
          overlayClassName="modal fade in"
          isOpen={this.state.isOpen}
          onRequestClose={this.closeModal}>
          <button onClick={this.closeModal} 
            className="close-modal"><i className="fa fa-close" /></button>
          <div className="modal-body">
            <SizeChart sku={sku} />
          </div>
        </Modal>
      </React.Fragment>
    );
  }

};

export default ViewSizeChart;
