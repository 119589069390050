import React from 'react';
import OnOffSwitch from '../structure/on-off-switch';
import cx from 'classnames';
import _ from 'lodash';

const ProductSellingToggle = ({ design: { userProduct }={}, className, onToggle=_.noop, disabled=false }) => {
  if (userProduct.status === 'rejected') {
    return (<button className={cx(className, 'product-selling-toggle__disabled')} disabled>
      This product has been disabled.
    </button>);
  }
  return (<button className={cx(className)} onClick={onToggle} disabled={disabled}>
    Available for Sale 
    <OnOffSwitch value={userProduct.status === 'selling'} updating={userProduct.updating} />
  </button>);
}

export default ProductSellingToggle;