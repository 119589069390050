import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { fetchUserIfNeeded } from '../../actions/user';
import { getCurrentUser } from '../../reducers/user';
import { dateOnly } from '../../utils/datetime';
import { formatPrice, formatNumber } from '../../utils/currency';
import { toast, updateToast } from '../../utils/toast';
import { getAffiliateLinkForUser, getAffiliateCodeForUser, userIsInFunraiserMode } from '../../utils/users';
import LoadingSpinner from '../../components/meta/loading-spinner';
import AffiliateSettings from '../../components/affiliate/affiliate-settings';
import Header from '../../components/structure/header';
import MyAccountHeader from '../../components/my-account/my-account-header';
import BackgroundOverlay from '../../components/structure/background-overlay';
import ReportTable from '../../components/meta/report-table';
import Tooltip from 'react-tooltip-lite';
import Moose from '../../clients/moose';
import _ from 'lodash';

const MyStatsRoute = createReactClass({

  propTypes: {
    user: PropTypes.object.isRequired,
    fetchUserIfNeeded: PropTypes.func.isRequired,
    noHeader: PropTypes.bool
  },

  getInitialState() {
    return {
      isFetchingAffiliateInfo: false,
      customizeAffiliateCode: false,
    }
  },

  componentDidMount() {
    this.isMounted = true;
    this.fetchAffiliateInfo();
  },

  componentWillUnmount() {
    this.isMounted = false;
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.id !== this.props.user.id) {
      this.fetchAffiliateInfo();
    }
  },

  handleSubmitTOS() {
    const toastId = toast('Submitting TOS...');
    Moose.acceptAffiliateTOS().then(() => {
      this.props.fetchUserIfNeeded();
      this.fetchAffiliateInfo(() => {
        updateToast(toastId, 'Welcome to our affiliate program!');
      });
    });
  },

  handleAffiliateSettings(values) {
    const toastId = toast('Saving settings...');
    Moose.updateAffiliateSettings(values).then(() => {
      this.fetchAffiliateInfo(() => {
        updateToast(toastId, 'Settings saved!');
      });
    });
  },

  fetchAffiliateInfo(onDone) {
    this.setState({ isFetchingAffiliateInfo: true });
    Moose.fetchAffiliateInfo().then((affiliateInfo) => {
      if (this.isMounted) {
        this.setState({ affiliateInfo, isFetchingAffiliateInfo: false }, () => {
          if (onDone) {
            onDone();
          }
        });
      }
    }).catch(e => {
      this.setState({ isFetchingAffiliateInfo: false });
    });
  },

  renderStats() {
    const { affiliateInfo } = this.state;
    return (<div className="affiliate-stats">
      <h3 className="header">Stats</h3>
      <div className="affiliate-stats-boxes">
        <div>
          <h4>Total Orders</h4>
          <p>{formatNumber(affiliateInfo.totalSales || 0)}</p>
        </div>
      </div>
    </div>);
  },

  renderTables() {
    const { affiliateInfo } = this.state;
    const salesData = affiliateInfo && affiliateInfo.sales.map(sale => {
      return [
        dateOnly(sale.date),
        sale.orderId,
        `${formatPrice(sale.reward)} (${sale.percentage}%)`,
        sale.status
      ]
    });

    const paymentData = affiliateInfo && affiliateInfo.payments.map(payment => {
      const paymentTypes = {
        'store-credit': 'store credit',
        'paypal': 'paypal'
      };
      return [
        dateOnly(payment.createdAt),
        formatPrice(payment.amount),
        payment.paidAt
          ? paymentTypes[payment.type] || 'other'
          : 'processing'
      ];
    });

    return (<React.Fragment>
      <ReportTable className="my-account__affiliate-sales"
        title="Your Sales"
        columns={[
          'Date',
          'Order',
          'Your Commission',
          'Status'
        ]}
        cellFilter={(row,col,val,rows) => {
          if (col === 3) {
            if (val === 'pending') {
              return (<Tooltip className="affiliate-status-tooltip" content="Pending status means a sale was made using your code, but payment can't be released until it's confirmed as complete/non-returned/non-refunded. This may take 30-45 business days.">
              {val} <i className="fa fa-question-circle" />
              </Tooltip>);
            }
            if (val === 'void') {
              return (<Tooltip className="affiliate-status-tooltip" content="Void means this commission credit was disqualified due to order cancelation, refund, return, bank dispute or other reason the sale was not completed.">
              {val} <i className="fa fa-question-circle" />
              </Tooltip>);
            }
          }
          return val;
        }}
        data={salesData}/>

        
      <ReportTable className="my-account__affiliate-payouts"
        title="Monthly Payouts"
        columns={[
          'Date',
          'Payment Amount',
          'Paid Via'
        ]} 
        data={paymentData}/>
    </React.Fragment>)
  },

  render() {
    const { isFetchingAffiliateInfo, affiliateInfo } = this.state;
    const { user } = this.props;
    if (!affiliateInfo && isFetchingAffiliateInfo) {
      return <LoadingSpinner />;
    }

    const acceptedTOS = affiliateInfo && _.get(affiliateInfo, 'affiliate.tosAccepted');
    const isDisabled = _.get(user, 'affiliate.disabled');
    if (isDisabled) {
      return (<div className="my-account__affiliate clearfix">
        <div className="my-account__affiliate-disabled">
          <h3>Account Disabled.</h3>
          <p>If you think this was a mistake, please get in touch with us at <a href="mailto:hello@picturethisclothing.com">hello@picturethisclothing.com</a></p>
        </div>
      </div>);
    }

    return (
      <>
        <Header />
        <BackgroundOverlay />
        <div className="my-account clearfix">
          <MyAccountHeader user={user} />
          <div className="my-account__content">
            <div className="my-account__affiliate clearfix">

              {acceptedTOS ? this.renderStats() : null}

              {acceptedTOS ? this.renderTables() : null}

              <AffiliateSettings 
                onSubmitSettings={this.handleAffiliateSettings}
                onSubmitTOS={this.handleSubmitTOS}
                initialValues={affiliateInfo && {
                  ...affiliateInfo.affiliate,
                  confirmPaypalAddress: _.get(affiliateInfo, 'affiliate.paypalAddress')
                }}
                affiliate={affiliateInfo && affiliateInfo.affiliate} />

              <a href="https://picturethisclothing.com/terms-and-conditions/" className="view-affiliate-tos text--light">Program Terms &amp; Conditions</a>
            </div>
          </div>
        </div>
      </>
    );
  }

});

function mapStateToProps (state) {
  const { entity } = getCurrentUser(state.user);
  return {
    user: entity,
    affiliateLink: getAffiliateLinkForUser(entity),
    affiliateCode: getAffiliateCodeForUser(entity),
    isFunraiser: userIsInFunraiserMode(entity)
  };
}

const mapDispatchToProps = {
  fetchUserIfNeeded
};

export default connect(mapStateToProps, mapDispatchToProps)(MyStatsRoute);