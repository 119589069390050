import _ from 'lodash';
import { DEFAULT_PIXEL_WIDTH, BACKGROUND_ENTRY } from '../config';

import {
  SET_IS_LOADING,
  SET_CANVAS_WIDTH,
  SET_COLOR,
  SET_COLOR_BANK,
  SET_PIXEL_WIDTH,
  SET_SHOW_GRID,
  SET_TEMPLATE_OPTIONS,
  SET_CANVAS_STATE,
  SET_LOADED_DESIGN,
  ADD_FLOW_FROM_CART,
  RESET_FLOW
} from '../actions';

const initialState = {
  isLoading: false,
  canvasWidth: 528,
  pixelWidth: DEFAULT_PIXEL_WIDTH,
  color: 0,
  colorBank: 0,
  showGrid: true,
  cartUniqueId: null,
  templateOptions: {
    sku: 'PTS01',
    variants: [],
    designersName: ''
  },
  canvasUpdateCount: 0,
  canvasState: {
    width: DEFAULT_PIXEL_WIDTH,
    height: DEFAULT_PIXEL_WIDTH,
    painting: Array(DEFAULT_PIXEL_WIDTH).fill(Array(DEFAULT_PIXEL_WIDTH).fill(BACKGROUND_ENTRY))
  }
};

const editor = function (state = initialState, action) {
  switch (action.type) {

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    case SET_CANVAS_WIDTH:
      return {
        ...state,
        canvasWidth: action.payload
      };

    case SET_COLOR:
      return {
        ...state,
        color: action.payload
      };

    case SET_COLOR_BANK:
      return {
        ...state,
        colorBank: action.payload
      };

    case SET_PIXEL_WIDTH:
      return {
        ...state,
        pixelWidth: action.payload
      };

    case SET_SHOW_GRID:
      return {
        ...state,
        showGrid: action.payload
      };

    case SET_TEMPLATE_OPTIONS:
      return {
        ...state,
        templateOptions: action.payload
      };

    case SET_CANVAS_STATE:
      return {
        ...state,
        canvasUpdateCount: state.canvasUpdateCount + 1,
        canvasState: action.payload
      };

    case SET_LOADED_DESIGN:
      return {
        ...state,
        loadedDesign: action.payload,
        cartUniqueId: null
      };

    case RESET_FLOW:
      return {
        ...initialState
      };

    case ADD_FLOW_FROM_CART: {
      const { 
        lineItem: {
          uniqueId: cartUniqueId, 
          canvasState,
          designersName,
          product: { sku },
          variants
        },
      } = action.payload;
      if (!canvasState) {
        return state;
      }
      return { 
        ...state,
        canvasState,
        cartUniqueId,
        templateOptions: {
          ...state.templateOptions,
          sku,
          variants,
          designersName
        }
      };
    }

    default:
      return state;
  }
};

export default editor;

