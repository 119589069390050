export function setCookie(name,value,days=100) {
  var date = new Date();
  date.setTime(date.getTime()+(days*24*60*60*1000));
  var expires = "; expires="+date.toUTCString();
  const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
  var domain = cookieDomain ? `; domain=${cookieDomain}` : '';
  document.cookie = name + "=" + value + expires + "; path=/" + domain;
}

export function deleteCookie(name) { 
  setCookie(name, '', -1); 
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      /* eslint-disable eqeqeq */
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      /* eslint-enable eqeqeq */
  }
  return null;
}