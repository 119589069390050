import _ from 'lodash';
import axios, { CancelToken } from 'axios';
import { CALL_API } from '@meadow/redux-api-middleware';
import { formValueSelector } from 'redux-form';
import { getCookie, deleteCookie } from '../utils/cookie';
import { trackAddProductToCart, trackAddGCToCart } from '../utils/tracking';

import {
  ADD_PRODUCT_TO_CART,
  REMOVE_PRODUCT_FROM_CART,
  UPDATE_QUANTITY_IN_CART,
  CLEAR_CART,
  ADD_GIFT_CERTIFICATE_TO_CART,
  REMOVE_GIFT_CERTIFICATE_FROM_CART,
  ADD_MAGIC_CODE_TO_CART,
  REMOVE_MAGIC_CODE_FROM_CART,
  REMOVE_STORE_CREDIT_FROM_CART,
  RESET_STORE_CREDIT_IN_CART,
  ADD_STORE_CREDIT_TO_CART,
  FETCH_PRICING_REQUEST,
  FETCH_PRICING_SUCCESS,
  FETCH_PRICING_FAILURE
} from './';
import { resetFlow } from './flow';
import { resetGiftFlow } from './gift-flow';
import Moose from '../clients/moose';
import { getLineItems, getGiftCertificates, getMagicCodes, getUnappliedStoreCredit } from '../reducers/cart';
import { getFlowData } from '../reducers/flow';
import { getProductBySku } from '../reducers/products';
import { expandedCartSelector } from '../selectors/cart';

let pricingToken = null;

export function retrievePricing () {
  return (dispatch, getState) => {
    const country = formValueSelector('checkout')(getState(), 'shipping.country');
    const zip = formValueSelector('checkout')(getState(), 'shipping.postalCode');
    const state = formValueSelector('checkout')(getState(), 'shipping.state');
    const expandedLineItems = expandedCartSelector(getState());
    const giftCertificates = getGiftCertificates(getState().cart);
    const magicCodes = getMagicCodes(getState().cart);
    const unappliedStoreCredit = getUnappliedStoreCredit(getState().cart);
    const lineItems = _.map(expandedLineItems, (lineItem) => {
      let parentIndex;

      _.forEach(expandedLineItems, ({ uniqueId }, idx) => {
        if (uniqueId === lineItem.parentUniqueId) {
          parentIndex = idx;
        }
      });

      const userProductId = _.get(lineItem, 'userDesign.id');

      return {
        parentIndex,
        productId: lineItem.productId,
        ...(userProductId ? { userProductId } : {}),
        quantity: lineItem.quantity,
        uniqueId: lineItem.uniqueId,
        variants: _.map(lineItem.variants, ({ variantId, variantOptionId }) => {
          return {
            variantId,
            variantOptionId
          };
        })
      };
    });

    if (pricingToken) {
      pricingToken.cancel();
    }

    pricingToken = CancelToken.source();
    const affiliate = getCookie('affiliate');

    return dispatch({
      [CALL_API]: {
        types: [
          FETCH_PRICING_REQUEST,
          FETCH_PRICING_SUCCESS,
          FETCH_PRICING_FAILURE
        ],
        promise: Moose.fetchPricing({
          country,
          zip,
          state,
          giftCertificates,
          lineItems,
          magicCodes,
          unappliedStoreCredit,
          ...(affiliate ? { affiliate } : {}),
          source: 'tuxedo',
          cancelToken: pricingToken.token
        }).then(function (data) {
          pricingToken = null;

          return data;
        }).catch(function (err) {
          if (!axios.isCancel(err)) {
            pricingToken = null;

            throw err;
          }
        })
      }
    });
  };
}

export function retrievePricingIfNeeded () {
  return (dispatch, getState) => {
    const giftCertificates = getGiftCertificates(getState().cart);
    const lineItems = getLineItems(getState().cart);

    if (
      (!lineItems && !giftCertificates) ||
      (lineItems.length === 0 && giftCertificates.length === 0) ||
      pricingToken
    ) {
      return null;
    }

    return dispatch(retrievePricing());
  };
}

export function retrievePricingIfNotInFlight () {
  return (dispatch) => {
    if (pricingToken) {
      return null;
    }

    return dispatch(retrievePricing());
  };
}

function addProduct ({ productId, quantity, variants, designersName, uniqueId, parentUniqueId, upload, userDesign, canvasState }) {
  return {
    type: ADD_PRODUCT_TO_CART,
    payload: {
      productId,
      quantity,
      variants,
      designersName,
      uniqueId,
      parentUniqueId,
      upload,
      userDesign,
      canvasState
    }
  };
}

export function addFlowToCart () {
  return (dispatch, getState) => {
    const { designersName, lineItems, upload, userDesign, canvasState } = getFlowData(getState().flow);

    _.forEach(lineItems, function ({ quantity, sku, variants, uniqueId, parentUniqueId }) {
      const product = getProductBySku(getState().products, sku);

      dispatch(addProduct({
        productId: product.id,
        quantity,
        variants,
        designersName,
        uniqueId,
        parentUniqueId,
        upload,
        userDesign,
        canvasState
      }));
    });
    dispatch(resetFlow());
    setTimeout(() => {
      try {
        trackAddProductToCart(lineItems);
      } catch(e) {
        console.error(e);
      };
    }, 0);
    return dispatch(retrievePricing());
  };
}

export function addGiftFlowToCart () {
  return (dispatch, getState) => {
    const { lineItems } = getState().giftFlow;
    for (const lineItem of lineItems) {
      const giftCertificate = _.pick(lineItem, [
        'amount', 'to', 'from','message'
      ]);
      dispatch({
        type: ADD_GIFT_CERTIFICATE_TO_CART,
        payload: giftCertificate
      });
      setTimeout(() => {
        try {
          trackAddGCToCart(giftCertificate);
        } catch(e) {
          console.error(e);
        };
      }, 0);
    }
    dispatch(resetGiftFlow());
    return dispatch(retrievePricing());
  };
}

export function addMagicCodeToCart (magicCode) {
  return (dispatch) => {
    dispatch({
      type: ADD_MAGIC_CODE_TO_CART,
      payload: magicCode
    });

    return dispatch(retrievePricing());
  };
}

export function addStoreCreditToCart (codeId) {
  return (dispatch) => {
    dispatch({
      type: ADD_STORE_CREDIT_TO_CART,
      payload: codeId
    });

    return dispatch(retrievePricing());
  };
}

export function resetStoreCreditInCart () {
  return (dispatch) => {
    dispatch({
      type: RESET_STORE_CREDIT_IN_CART
    });
    return dispatch(retrievePricing());
  };
}

export function removeProductFromCart ({ uniqueId }) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_PRODUCT_FROM_CART,
      payload: {
        uniqueId
      }
    });

    return dispatch(retrievePricing());
  };
}

export function removeGiftCertificateFromCart ({ uniqueId }) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_GIFT_CERTIFICATE_FROM_CART,
      payload: {
        uniqueId
      }
    });

    return dispatch(retrievePricing());
  };
}

export function removeMagicCodeFromCart (magicCode) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_MAGIC_CODE_FROM_CART,
      payload: magicCode
    });

    return dispatch(retrievePricing());
  };
}

export function removeAffiliateCookie () {
  return (dispatch) => {
    deleteCookie('affiliate');
    return dispatch(retrievePricing());
  };
}

export function removeStoreCreditFromCart (codeId) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_STORE_CREDIT_FROM_CART,
      payload: codeId
    });

    return dispatch(retrievePricing());
  };
}

export function updateQuantityInCart ({ quantity, uniqueId }) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_QUANTITY_IN_CART,
      payload: {
        quantity,
        uniqueId
      }
    });

    return dispatch(retrievePricing());
  };
}

export function clearCart () {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CART
    });

    return dispatch(retrievePricing());
  };
}
