import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { reduxForm, Field } from 'redux-form';
import {
  combineValidators,
  composeValidators,
  isRequired,
  hasLengthGreaterThan,
  matchesField
} from 'revalidate';
import Moose from '../../clients/moose';

import { Link } from 'react-router-dom';
import CheckoutBox from '../checkout/checkout-box';
import CheckoutField from '../checkout/checkout-field';
import LoadingSpinner from '../meta/loading-spinner';

const ForgotPasswordReset = createReactClass({
  propTypes: {
    onSubmit: PropTypes.func.isRequired,
    resetToken: PropTypes.string.isRequired,
    errorMessage: PropTypes.string
  },

  getInitialState() {
    return {
      isFetching: true,
      isValid: false
    };
  },

  componentWillMount() {
    const { resetToken } = this.props;
    Moose.validatePasswordToken(resetToken).then(token => {
      this.setState({
        isFetching: false,
        isValid: token.valid,
        resetFrom: token.from
      });
    });
  },

  renderErrorMessage(title, message) {
    const { resetFrom } = this.state;
    const forgotPassword = {
      pathname: '/forgot-password',
      state: { from: (resetFrom || 'invalid-token') }
    };
    return (<CheckoutBox title={title}>
    {message}
    <br /><br />
    <Link to={ forgotPassword } className="checkout-authentication__forgot-password-link">Would you like to try again?</Link>
  </CheckoutBox>)
  },

  render () {
    const { isFetching, isValid } = this.state;
    const { handleSubmit, onSubmit, errorMessage } = this.props;
    if (isFetching) {
      return <LoadingSpinner />
    }
    if (errorMessage) {
      return this.renderErrorMessage('Error', errorMessage);
    }
    if (isValid) {
      return (
        <CheckoutBox title="Resetting Your Password">
          <form className="forgot-password-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="forgot-password__instructions">
              <p>Please enter a new password below.</p>
            </div>
            <div className="my-account__new-password-fields">
              <Field name="newPassword" component={CheckoutField} type="password" className="checkout__input my-account__new-password" placeholder="New Password" />

              <Field name="newPasswordConfirmation" component={CheckoutField} type="password" className="checkout__input my-account__confirm-password" placeholder="Confirm New Password" />
            </div>
            <button className="button primary text--light forgot-password__button" type="submit">Update Password</button>
          </form>
        </CheckoutBox>
      );  
    } else {
      return this.renderErrorMessage('Invalid Token', 'The link you have followed is either expired or invalid.');
    }
  }
});

const validate = combineValidators({
  'newPassword': composeValidators(
    isRequired,
    hasLengthGreaterThan(7)
  )('New password'),
  'newPasswordConfirmation': matchesField('newPassword')({
    message: 'New password confirmation does not match'
  })
});

export default reduxForm({
  form: 'forgot-password-reset',
  onSubmitFail: () => {
    window.scrollTo(0, 0);
  },
  validate,
  shouldValidate: () => true
})(ForgotPasswordReset);
