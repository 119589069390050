import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { isValidEmailTest } from '../utils/validators';
import { scroller } from 'react-scroll';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { 
  addGiftFlowLineItem,
  removeGiftFlowLineItem,
  setGiftFlowLineItemProperties
} from '../actions/gift-flow';
import { addGiftFlowToCart } from '../actions/cart';
import { trackViewProduct } from '../utils/tracking';

import Header from '../components/structure/header';
import ContinueBar from '../components/products/continue-bar';
import GiftSelectForm from '../components/products/gift-select-form';

const GiftSelectRoute = createReactClass({
  propTypes: {
    history: PropTypes.object.isRequired,
    giftFlowLineItems: PropTypes.array.isRequired,
    addGiftFlowLineItem: PropTypes.func.isRequired,
    removeGiftFlowLineItem: PropTypes.func.isRequired,
    setGiftFlowLineItemProperties: PropTypes.func.isRequired
  },

  getInitialState() {
    return {
      prevScrollY: 0
    };
  },

  componentDidMount() {
    trackViewProduct('GC');
  },

  componentDidUpdate(prevProps) {
    if (this.props.giftFlowLineItems.length > prevProps.giftFlowLineItems.length) {
      // Scroll to next GC
      const { prevScrollY } = this.state;
      window.scrollTo(0, prevScrollY);
      const lastLineItem = this.props.giftFlowLineItems.slice(-1)[0];
      scroller.scrollTo(lastLineItem.uniqueId, {
        duration: 300,
        smooth: true
      });
    }
  },

  handleBackClick () {
    this.props.history.goBack();
  },

  validateLineItems () {
    const { giftFlowLineItems, setGiftFlowLineItemProperties } = this.props;
    let isValid = true;
    let firstLineItemWithError = null;
    for (const lineItem of giftFlowLineItems) {
      const { amount, to } = lineItem;
      const priceError = !amount || amount < 1;
      const emailError = !to || !isValidEmailTest(to);
      setGiftFlowLineItemProperties(lineItem, {
        priceError,
        emailError
      });
      if (priceError || emailError) {
        if (!firstLineItemWithError) {
          firstLineItemWithError = lineItem;
        }
        isValid = false;
      }
    }
    if (firstLineItemWithError) {
      scroller.scrollTo(firstLineItemWithError.uniqueId, {
        duration: 300,
        smooth: true,
        offset: -10
      });
    }
    return isValid;
  },

  handleContinueClick () {
    if (this.validateLineItems()) {
      this.props.addGiftFlowToCart();
      this.props.history.push('/cart');
    };
  },

  handleRemoveGiftCertficiate (lineItem) {
    this.props.removeGiftFlowLineItem(lineItem);
  },

  handleAddGiftCertficiate() {
    this.setState({ prevScrollY: window.scrollY });
    this.props.addGiftFlowLineItem();
  },

  render () {
    const { 
      giftFlowLineItems, 
      setGiftFlowLineItemProperties 
    } = this.props;
    const canRemove = giftFlowLineItems.length > 1;

    return (
      <div className="gift-select">
        <Header />

        <div className="gift-select__content">
          <h2 className="header header--main gift-select__title">Purchase a Gift Certificate</h2>

          <TransitionGroup>
            {giftFlowLineItems.map((lineItem) => 
              <CSSTransition key={lineItem.uniqueId} timeout={300} classNames="transition">
                <GiftSelectForm
                  key={lineItem.uniqueId}
                  lineItem={lineItem} 
                  setGiftFlowLineItemProperties={setGiftFlowLineItemProperties}
                  canRemove={canRemove} 
                  onRemoveClick={this.handleRemoveGiftCertficiate} />
              </CSSTransition>
            )}
          </TransitionGroup>

          <button className="gift-select__add rounded-box" onClick={this.handleAddGiftCertficiate}>
            <i className="icon icon-plus" />
            <div>Create Another Gift Certificate</div>
          </button>

        </div>

        <ContinueBar
          actionText="Add to Cart"
          actionEnabled={true}
          onBackClick={this.handleBackClick}
          onContinueClick={this.handleContinueClick} />
      </div>
    );
  }
});

function mapStateToProps (state) {
  const { lineItems } = state.giftFlow;
  return {
    giftFlowLineItems: lineItems
  };
}

const mapDispatchToProps = {
  addGiftFlowLineItem,
  removeGiftFlowLineItem,
  setGiftFlowLineItemProperties,
  addGiftFlowToCart
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftSelectRoute);
