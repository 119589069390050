import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import QuantitySelector from './quantity-selector';
import { formatPriceShort } from '../../utils/currency';
import { giftShippingOptions, giftProductOptions } from '../../utils/gift-options';

Modal.setAppElement('#root');

class GiftPriceCalculator extends React.Component {

  static propTypes = {
    quantities: PropTypes.object,
    calculatedAmount: PropTypes.number,
    onChangeQuantity: PropTypes.func,
    onChangePrice: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onClickOK = this.onClickOK.bind(this);
  }

  openModal () {
    this.setState({ isOpen: true });
  }

  closeModal () {
    this.setState({ isOpen: false });
  }

  onClickOK () {
    const { calculatedAmount, onChangePrice } = this.props;
    onChangePrice( formatPriceShort(calculatedAmount) );
    this.closeModal();
  }

  render() {
    const { isOpen } = this.state;
    const { quantities, calculatedAmount } = this.props;

    const priceFmt = (price) => {
      if (price === 0) {
        return 'FREE';
      }
      if (price) {
        return formatPriceShort(price);
      } else {
        return (<a href="mailto:hello@picturethisclothing.com">Contact us</a>)
      }
    }

    const updateQuantityFor = (id) => {
      return (qty) => {
        this.props.onChangeQuantity(id, qty);
      }
    }

    const productRow = (data, i) => {
      if (data.id) {
        return (<tr key={data.id}>
          <td colSpan="2" className="gift-price-calculator__product-cell">
            {data.size}
          </td>
          <td className="gift-price-calculator__quantity-cell">
            <div>{priceFmt(data.price)}</div>
            {data.price !== undefined && <QuantitySelector 
              minimumQuantity={0}
              quantity={quantities[data.id] || 0} 
              onChange={updateQuantityFor(data.id)} />}
          </td>
        </tr>);
      } else {
        return (<tr key={`title${i}`}>
          <td colSpan="2"  className="gift-price-calculator__title-cell">
            <img src={data.icon} alt={data.name} />
            <span>{data.title}</span>
          </td>
          <td className="gift-price-calculator__quantity-cell">
            &nbsp;
          </td>
        </tr>);
      }
    }

    const shippingRow = (data, i) => {
      return (<tr key={data.id}>
        <td>
          <img src={data.icon} alt={data.name} />
          <span>{data.name}</span>
        </td>
        <td>
          {data.size}
        </td>
        <td className="gift-price-calculator__quantity-cell">
          <div>{priceFmt(data.price)}</div>
          {data.price !== undefined && <QuantitySelector 
            minimumQuantity={0}
            quantity={quantities[data.id] || 0} 
            onChange={updateQuantityFor(data.id)} />}
        </td>
      </tr>);
    }

    return (
      <span className="gift-price-calculator">
        <button className="gift-price-calculator__button" onClick={this.openModal}>
          Price Calculator
        </button>
        <Modal
          className="modal-content modal-fullsize gift-price-calculator__modal"
          overlayClassName="modal fade in"
          isOpen={isOpen}
          onRequestClose={this.closeModal}>
          <button onClick={this.closeModal} 
            className="close-modal"><i className="fa fa-close" /></button>
          <div className="modal-body">
            <h1 className="header header--small">Price Calculator</h1>
            <table className="gift-price-calculator__table">
              <tbody>
                <tr>
                  <th colSpan="2">Product</th>
                  <th>Price</th>
                </tr>
                {giftProductOptions.map(productRow)}
                <tr>
                  <th>Shipping</th>
                  <th>Quantity</th>
                  <th>Price</th>
                </tr>
                {giftShippingOptions.map(shippingRow)}
                <tr>
                  <td className="gift-price-calculator__total-label">Total</td>
                  <td className="gift-price-calculator__tax-note">
                    Taxes applied at checkout when applicable
                  </td>
                  <td className="gift-price-calculator__total">
                    {formatPriceShort(calculatedAmount)}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="gift-price-calculator__buttons">
              <button className="button plain" onClick={this.closeModal}>
                Cancel
              </button>
              <button className="button primary text--light" onClick={this.onClickOK}>
                Apply
              </button>
            </div>
          </div>
        </Modal>
      </span>
    );

  }

}

export default GiftPriceCalculator;
