import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';

import Moose from '../clients/moose';

import Header from '../components/structure/header';
import Footer from '../components/structure/footer';
import UserStoreWrapper from '../components/user-store/user-store-wrapper';
import UserProduct from '../components/user-store/product';
import LoadingSpinner from '../components/meta/loading-spinner';

const UserStoreRoute = createReactClass({
  propTypes: {
    match: PropTypes.shape({
      params: PropTypes.shape({
        code: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    storeCode: PropTypes.string.isRequired
  },

  componentDidMount() {
    const { storeCode } = this.props;
    this.loadUserStore(storeCode);
  },

  componentDidUpdate(prevProps) {
    const { storeCode } = this.props;
    if (storeCode !== prevProps.storeCode) {
      this.loadUserStore(storeCode);
    }
  },

  getInitialState() {
    return {
      isLoading: false
    }
  },

  loadUserStore(code) {
    this.setState({ isLoading: true });
    Moose.fetchUserStore(code).then(userStore => {
      this.setState({ isLoading: false, userStore });
    }).catch(e => {
      window.location = process.env.REACT_APP_WORDPRESS_HOME || '/';
    });
  },

  renderUserProducts() {
    const { products } = this.props;
    const { userStore: { products: userProducts } } = this.state;
    if (!userProducts || !userProducts.length) {
      return null;
    }
    const className = userProducts.length < 3 
      ? 'user-store-products user-store-products-center' 
      : 'user-store-products';
    return (
      <div className={className}>
        {userProducts.map(userProduct => <UserProduct 
          key={userProduct.code} 
          userProduct={userProduct} 
          productData={products}
          linkTo={`/design/${userProduct.code}`} />)}
      </div>
    );
  },

  render () {
    const { isLoading, userStore } = this.state;

    if (isLoading || !userStore) {
      return (<LoadingSpinner />);
    }

    return (<div className="user-store__container">
      <Header styleColor={`#${userStore.accentColor || 'ff6f00'}`}/>
      <UserStoreWrapper store={userStore}>
        <div className="user-store-info">
          <h2>{userStore.name}</h2>
          <p>{userStore.description}</p>
        </div>
        {this.renderUserProducts()}
      </UserStoreWrapper>
      <Footer />
    </div>);
  }
});

function mapStateToProps (state, props) {
  const storeCode = props.match.params.code;
  return {
    storeCode,
    products: state.products.list.entities,
  };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(UserStoreRoute);
