import React from 'react';
import _ from 'lodash';
import LoadingSpinner from '../meta/loading-spinner';
import { canRenderInBrowser } from '../../utils/image';
import { useWaitForImage } from '../../utils/hooks';

const UploadImage = ({ image, children, useCssPreviewImage }) => {
  const imageUrl = _.get(image, 'thumbnails.web') || image.url;
  const isProcessing = useWaitForImage(imageUrl);

  let style = {};
  let className = '';
  let imageElement;

  if (isProcessing) {
    className = 'upload-input upload-input--processing';
    imageElement = (<LoadingSpinner />);
  } else {
    if (canRenderInBrowser(imageUrl)) {
      if (!useCssPreviewImage) {
        className = 'upload-input upload-input--has-image';
        imageElement = (<img className="upload-input__image" src={imageUrl} alt="Your Upload" />);
      } else {
        className = 'upload-input upload-input--css-image';
        style = { backgroundImage: `url('${imageUrl}')` };
      }
    } else {
      const placeholderText = image.originalFilename || `${image.mime} file`;
      imageElement = (<div className="upload-input__image-placeholder"><i className="fa fa-picture-o" /> {placeholderText}</div>);
    }
  }

  return (<div className={className} style={style}>
    {imageElement}
    {!isProcessing && children}
  </div>);
};

export default UploadImage;