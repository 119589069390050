import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';

import { createUser, signInUser, setLoginRedirect } from '../actions/user';
import { getCurrentUser, getAuthentication } from '../reducers/user';
import { isMobileApp } from '../utils/mobile-app';

import Header from '../components/structure/header';
import AuthenticationForm from '../components/checkout/authentication-form';

const SignInRoute = createReactClass({
  propTypes: {
    history: PropTypes.object.isRequired,
    entity: PropTypes.object,
    isFetchingUser: PropTypes.bool.isRequired,
    signInUser: PropTypes.func.isRequired
  },

  componentDidMount() {
    const { location: { hash }={} } = this.props;
    if (hash === '#create') {
      this.setState({ authSegmentIndex: 0 });
     }
  },

  getInitialState () {
    return {
      authSegmentIndex: 1
    };
  },

  handleAuthTypeChange (authSegmentIndex) {
    this.setState({
      authSegmentIndex
    });
  },

  onSubmit (values) {
    let signInPromise;
    if (this.state.authSegmentIndex === 0) {
      const { signUp: { email, name, password }} = values;
      signInPromise = this.props.createUser({
        email,
        name,
        password
      });
    } else {
      const { signIn: { email, password }} = values;
      signInPromise = this.props.signInUser({
        email,
        password
      });
    }
    return signInPromise.then((user) => {
      if (isMobileApp()) {
        return;
      }
      if (this.props.loginRedirect) {
        const redirectTo = this.props.loginRedirect; 
        this.props.setLoginRedirect(null);
        this.props.history.replace(redirectTo);
      } else {
        this.props.history.replace('/account');
      }
    });
  },

  render () {
    return (
      <div className="sign-in__container">
        <Header />
        <div className="sign-in clearfix">
          <AuthenticationForm
            from="sign-in"
            authSegmentIndex={this.state.authSegmentIndex}
            onAuthTypeChange={this.handleAuthTypeChange}
            onSubmit={this.onSubmit} />            
        </div>
      </div>
    );
  }
});

function mapStateToProps (state) {
  const { entity, isFetching: isFetchingUser } = getCurrentUser(state.user);
  const { loginRedirect } = getAuthentication(state.user);

  return {
    entity,
    isFetchingUser,
    loginRedirect
  };
}

const mapDispatchToProps = {
  createUser,
  signInUser,
  setLoginRedirect
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInRoute);
