import React from 'react';
import PropTypes from 'prop-types';

const CheckoutBox = ({ children, title, className }) => {
  return (
    <div className={`checkout-box ${className}`}>
      <h3 className="checkout-box__title header">{title}</h3>
      <div className="checkout-box__children">
        {children}
      </div>
    </div>
  );
};

CheckoutBox.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default CheckoutBox;
