import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { signOut } from '../../actions/user'; 
import { getMyAccountLinks } from '../../utils/users';
import classNames from 'classnames';

import { withRouter } from 'react-router-dom';
import MobileLink from '../mobile-app/Link';

const MyAccountNav = createReactClass({
  
  propTypes: {
    signOut: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  },

  render() {
    const { user } = this.props;
    const accountLinks = getMyAccountLinks(user).filter(link => !link.hideInMenu).map(link => {
      const className = classNames({
        'my-account__nav-link': true,
        'my-account__nav-affiliate-link': link.value === 'affiliate' 
      });
      return (<MobileLink
        key={link.value}
        to={`/account/${link.value}`}
        title={link.label}
        className={className}>
          {link.label}
      </MobileLink>);
    });
    return (
      <div className="my-account__nav clearfix">
        {accountLinks}
        <div className="my-account__nav-link my-account__log-out" onClick={this.props.signOut}>Log Out</div>
      </div>
    );
  }

});

const mapDispatchToProps = {
  signOut
};

export default withRouter(connect(null, mapDispatchToProps)(MyAccountNav));
