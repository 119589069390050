import _ from 'lodash';
import { CALL_API } from '@meadow/redux-api-middleware';

import {
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE
} from './';
import { clearCart } from './cart';
import Moose from '../clients/moose';
import { getGiftCertificates, getMagicCodes, getUnappliedStoreCredit } from '../reducers/cart';
import { expandedCartSelector } from '../selectors/cart';
import { getCookie } from '../utils/cookie';
import { trackPurchase } from '../utils/tracking';

export function createOrder ({ shippingAddress, billing, stripeToken }) {
  return (dispatch, getState) => {
    const expandedLineItems = expandedCartSelector(getState());
    const giftCertificates = getGiftCertificates(getState().cart);
    const magicCodes = getMagicCodes(getState().cart);
    const unappliedStoreCredit = getUnappliedStoreCredit(getState().cart);
    const lineItems = _.map(expandedLineItems, (lineItem) => {
      let parentIndex;

      _.forEach(expandedLineItems, ({ uniqueId }, idx) => {
        if (uniqueId === lineItem.parentUniqueId) {
          parentIndex = idx;
        }
      });

      return {
        parentIndex,
        designersName: lineItem.designersName,
        productId: lineItem.productId,
        quantity: lineItem.quantity,
        uniqueId: lineItem.uniqueId,
        uploadId: lineItem.upload.id,
        ...(lineItem.userDesign ? { userProductId: lineItem.userDesign.id } : {}),
        variants: _.map(lineItem.variants, ({ variantId, variantOptionId }) => {
          return {
            variantId,
            variantOptionId
          };
        })
      };
    });

    const affiliate = getCookie('affiliate');

    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_ORDER_REQUEST,
          CREATE_ORDER_SUCCESS,
          CREATE_ORDER_FAILURE
        ],
        promise: Moose.createOrder({
          giftCertificates,
          lineItems,
          magicCodes,
          unappliedStoreCredit,
          billingAddress: shippingAddress,
          shippingAddress,
          billing,
          source: 'tuxedo',
          stripeToken,
          ...(affiliate ? { affiliate } : {})
        })
      }
    }).then(function (order) {
      if (order) {
        try {
          trackPurchase({ order, lineItems, giftCertificates });
        } catch(e) {
          console.error(e);
        }
      }
      dispatch(clearCart());
    });
  };
}
