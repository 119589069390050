import React, { useRef, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { PainterContext } from '../../contexts';
import { PREVIEW_WIDTH, DESKTOP } from '../../config';
import { getPreviewHeight, createImage } from '../../utils/editor';

const Preview = ({ template, width, showTemplateWarning }) => {
  const isDesktop = useMediaQuery({ query: DESKTOP }); 
  const canvas = useRef();
  const painter = useContext(PainterContext);
  const { svgPath } = template || {};
  const previewWidth = width || (isDesktop ? PREVIEW_WIDTH - 30 : 100);
  const previewHeight = getPreviewHeight(template, previewWidth);
  const OverlaySVG = template ? template.svgComponent : null;

  useEffect(() => {
    const image = template ? createImage(svgPath, previewWidth) : null;
    const ctx = canvas.current.getContext('2d');
    const updateTimer = setInterval(() => {
      ctx.clearRect(0,0,previewWidth,previewHeight);
      if (painter.current) {
        const previewScale = previewWidth / painter.current.canvas.clientWidth;
        painter.current.drawPainting(ctx, previewScale);
      }
      if (image) {
        ctx.globalCompositeOperation = 'destination-in';
        ctx.drawImage(image, 0, 0, previewWidth, previewHeight);
        ctx.globalCompositeOperation = 'source-over';
      }
    }, 50);
    return function cleanUp() { 
      clearInterval(updateTimer);
    }
  }, [ painter, template, previewHeight, svgPath ]);
  const style = {
    width: `${previewWidth}px`
  };
  return (<div className="preview-frame">
    <div className="preview-canvas-container" style={style}>
      <canvas ref={canvas} width={previewWidth} height={previewHeight} />
      {OverlaySVG && <OverlaySVG />}
    </div>
    {showTemplateWarning && (<div className="template-warning">
        <div>Pick a template</div>
        <i className="fa fa-hand-o-down" />
      </div>)}
  </div>);
};

export default Preview;