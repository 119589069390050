import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const CheckoutField = ({ className, input, meta: { error, touched }, ...rest }) => {
  const classes = classNames({
    [className]: className ? true : false,
    'checkout-field': true,
    'checkout-field--has-error': error && touched
  });
  const { type } = rest;

  return (
    <div className={classes}>
      {error && touched && <span className="checkout-field__error">{error}</span>}
      {type === 'textarea' 
        ? <textarea className="input" {...input} {...rest} />
        : <input className="input" {...input} {...rest} />}
    </div>
  );
};

CheckoutField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool.isRequired
  })
};

export default CheckoutField;
