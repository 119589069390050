import React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';

const Button = ({ className, children, active, wide, disabled, onClick=noop, onMouseOver=noop, onMouseOut=noop, title }) => {
  const classNames = cx('editor-button', className, { 'is-active': active, 'is-wide': wide });
  return (<button className={classNames} disabled={disabled} onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut} title={title}>
    {children}
  </button>);
}

export default Button;