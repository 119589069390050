import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { Field, FormSection, formValueSelector } from 'redux-form';
import _ from 'lodash';

import { retrievePricing } from '../../actions/cart';
import { getCheckoutOrder } from '../../reducers/checkout';
import { countryOptions, stateOptions } from '../../utils/checkout';

import CheckoutBox from './checkout-box';
import CheckoutField from './checkout-field';
import CheckoutSelectField from './checkout-select-field';
import CheckoutCheckbox from './checkout-checkbox';
import CheckoutError from './checkout-error';

const CheckoutShipping = createReactClass({
  propTypes: {
    error: PropTypes.object,
    retrievePricing: PropTypes.func.isRequired,
    country: PropTypes.string
  },

  componentDidMount() {
    this.handleShippingChange();
  },

  handleShippingChange () {
    setTimeout(() => {
      this.props.retrievePricing();
    }, 0);
  },

  handleShippingInput: _.debounce(function() {
    this.handleShippingChange();
  }, 300),

  renderStateField() {
    const { country } = this.props;
    if (country === 'US') {
      return (<Field
          name="state"
          component={CheckoutSelectField}
          options={stateOptions()}
          placeholder="State"
          className="checkout__input checkout__dropdown"
          onChange={this.handleShippingChange} 
          autoComplete="address-level1" />);
    } else {
      return (<Field name="state" component={CheckoutField} type="text" placeholder="State or Province"  className="checkout__input" onChange={this.handleShippingChange} autoComplete="address-level1" />);
    }
  },

  render () {
    const { error } = this.props;

    return (
      <CheckoutBox title="Shipping info">
        <div className="checkout-shipping">
          {error && <CheckoutError>{error.message}</CheckoutError>}

          <FormSection name="shipping" className="checkout-shipping__section">

            <Field name="country" component={CheckoutSelectField} options={countryOptions()}
              className="checkout__input checkout-shipping__country" placeholder="Country" onChange={this.handleShippingChange} autoComplete="country" />
            <Field name="name" component={CheckoutField} type="text" className="checkout__input checkout-shipping__name" placeholder="First &amp; Last Name" autoComplete="name" />
            <Field name="street1" component={CheckoutField} type="text" className="checkout__input" placeholder="Street address" autoComplete="address-line1" />
            <Field name="street2" component={CheckoutField} type="text" className="checkout__input" placeholder="Apartment, suit, unit, etc (Optional)" autoComplete="address-line2" />
            <Field name="city" component={CheckoutField} type="text" className="checkout__input" 
            placeholder="City" autoComplete="address-level2" />
            {this.renderStateField()}
            <Field name="postalCode" component={CheckoutField} type="text" className="checkout__input" placeholder="Postal Code / Zip" autoComplete="postal-code" onChange={this.handleShippingInput} />
            <Field name="phone" component={CheckoutField} type="text" className="checkout__input" placeholder="Phone" autoComplete="tel" />
            
            <Field name="saveForLater" component={CheckoutCheckbox} className="checkout__input checkout-shipping__save" label="Save this address for future orders" />

          </FormSection>
        </div>
      </CheckoutBox>
    );
  }
});

function mapStateToProps (state) {
  const { error } = getCheckoutOrder(state.checkout);
  const selector = formValueSelector('checkout');

  return {
    error,
    country: selector(state, 'shipping.country')
  };
}

const mapDispatchToProps = {
  retrievePricing,
  formValueSelector
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutShipping);
