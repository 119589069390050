import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import VariantDropdown from './variant-dropdown';

const VariantSelector = createReactClass({
  propTypes: {
    layout: PropTypes.oneOf(['normal', 'inline']).isRequired,
    product: PropTypes.object.isRequired,
    values: PropTypes.array.isRequired,
    onVariantsChange: PropTypes.func.isRequired
  },

  getDefaultProps () {
    return {
      layout: 'normal'
    };
  },

  handleVariantChange (value) {
    let newValues = _.filter(this.props.values, function ({ variantId }) {
      return variantId !== value.variantId;
    });

    newValues.push(value);

    /*
     * Make sure to remove any child variants if the parent changes
     */

    newValues = _.filter(newValues, ({ variantId }) => {
      const variantData = _.find(this.props.product.variants, (variant) => {
        return variant.id === variantId;
      });

      if (variantData.parentId === value.variantId) {
        return false;
      }

      return true;
    });

    this.props.onVariantsChange(newValues);
  },

  renderParentVariants () {
    const parentVariants = _.filter(this.props.product.variants, ({ parentId }) => {
      return !parentId;
    });

    return _.map(parentVariants, (variant) => {
      const value = _.find(this.props.values, ({ variantId }) => {
        return variantId === variant.id;
      });

      return (
        <div key={variant.id} className="variant-selector__variant">
          <VariantDropdown layout={this.props.layout} variant={variant} value={value} onChange={this.handleVariantChange} />
        </div>
      );
    });
  },

  renderChildVariants () {
    const childVariants = _.filter(this.props.product.variants, ({ parentId }) => {
      return !!parentId;
    });

    return _.map(childVariants, (variant) => {
      const parentValue = _.find(this.props.values, ({ variantId }) => {
        return variantId === variant.parentId;
      });

      if (!parentValue) {
        return null;
      }

      const value = _.find(this.props.values, ({ variantId }) => {
        return variantId === variant.id;
      });

      return (
        <div key={variant.id} className="variant-selector__variant">
          <VariantDropdown layout={this.props.layout} variant={variant} value={value} parentValue={parentValue} onChange={this.handleVariantChange} />
        </div>
      );
    });
  },

  render () {
    const className = classNames({
      'variant-selector': true,
      [`variant-selector--${this.props.layout}`]: true
    });

    return (
      <div className={className}>
        {this.renderParentVariants()}
        {this.renderChildVariants()}
      </div>
    );
  }
});

export default VariantSelector;
