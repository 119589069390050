import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import CheckoutBox from '../checkout/checkout-box';
import CheckoutError from '../checkout/checkout-error';
import CheckoutField from '../checkout/checkout-field';
import CheckoutSelectField from '../checkout/checkout-select-field';
import { countryOptions, stateOptions } from '../../utils/checkout';

import {
  combineValidators,
  isRequired
} from 'revalidate';

const MyAccountAddresses = createReactClass({

  propTypes: {
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    country: PropTypes.string
  },

  renderStateField() {
    const { country } = this.props;
    if (country === 'US') {
      return (<Field
        name="state"
        component={CheckoutSelectField}
        options={stateOptions()}
        placeholder="State *"
        className="my-account__selectfield" />);
    } else {
      return (<Field name="state" component={CheckoutField} type="text" placeholder="State or Province"  className="my-account__input" />);
    }
  },
  
  render() {
    const { handleSubmit, error } = this.props;

    return (
      <CheckoutBox title="Default Shipping Address">
        <form className="my-account-addresses-form" onSubmit={handleSubmit(this.props.onSubmit)}>
          <div className="checkout-shipping">
            {error && <CheckoutError>{error.message}</CheckoutError>}

            <Field name="country" component={CheckoutSelectField} options={countryOptions()}
              placeholder="Country" className="my-account__selectfield" autoComplete="country" />
            <Field name="name" component={CheckoutField} type="text" className="my-account__input" placeholder="First &amp; Last Name" autoComplete="name" />
            <Field name="street1" component={CheckoutField} type="text" className="my-account__input" placeholder="Street address" autoComplete="address-line1" />
            <Field name="street2" component={CheckoutField} type="text" className="my-account__input" placeholder="Apartment, suit, unit, etc (Optional)" autoComplete="address-line2" />
            <Field name="city" component={CheckoutField} type="text" className="my-account__input" placeholder="City" autoComplete="address-level2" />       
            {this.renderStateField()}
            <Field name="postalCode" component={CheckoutField} type="text" className="my-account__input" placeholder="Postal Code / Zip" autoComplete="postal-code" />
            <Field name="phone" component={CheckoutField} type="text" className="my-account__input" placeholder="Phone" autoComplete="tel" />

            <button className="button primary text--light" type="submit">Save</button>

            <button className="button delete-button" type="button" onClick={this.props.onDelete}>Clear Saved Address Info</button>
            
          </div>
        </form>
      </CheckoutBox>
    );
  }

});

function mapStateToProps (state, props) {
  const selector = formValueSelector(props.form);
  return {
    country: selector(state, 'country')
  };
}

const mapDispatchToProps = {
  formValueSelector
};

const validate = combineValidators({
  'name': isRequired('Name'),
  'street1': isRequired('Street'),
  'city': isRequired('City'),
  'postalCode': isRequired('Postal code'),
  'country': isRequired('Country'),
  'phone': isRequired('Phone')
});

export default reduxForm({
  form: 'my-account-addresses',
  onSubmitFail: () => {
    window.scrollTo(0, 0);
  },
  validate,
  shouldValidate: () => true
})(connect(mapStateToProps, mapDispatchToProps)(MyAccountAddresses));