import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';

import App from './app';
import configureStore from './store';

if (window.Bugsnag) {
  window.Bugsnag.releaseStage = process.env.NODE_ENV;
  window.Bugsnag.notifyReleaseStages = ['production'];
}

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StripeProvider>
  </Provider>,
  document.getElementById('root')
);
