import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';

import { 
  retrievePricingIfNotInFlight, 
  addMagicCodeToCart, 
  removeMagicCodeFromCart, 
  resetStoreCreditInCart, 
  removeStoreCreditFromCart, 
  removeAffiliateCookie 
} from '../actions/cart';
import { resetFlow } from '../actions/flow';
import { getGiftCertificates, getPricing } from '../reducers/cart';
import { getCurrentUserAffiliateCode } from '../reducers/user';
import { expandedCartSelector } from '../selectors/cart';

import CartEmpty from '../components/cart/cart-empty';
import CartGiftCertificates from '../components/cart/cart-gift-certificates';
import CartLineItems from '../components/cart/cart-line-items';
import CartMagicCode from '../components/cart/cart-magic-code';
import CartShippingNote from '../components/cart/cart-shipping-note';
import CartPricing from '../components/cart/cart-pricing';
import EntityDetail from '../components/meta/entity-detail';
import Header from '../components/structure/header';
import MobileLink from '../components/mobile-app/Link';

const CartRoute = createReactClass({
  propTypes: {
    history: PropTypes.object.isRequired,
    giftCertificates: PropTypes.array.isRequired,
    lineItems: PropTypes.array.isRequired,
    entity: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.object,
    retrievePricingIfNotInFlight: PropTypes.func.isRequired,
    addMagicCodeToCart: PropTypes.func.isRequired,
    removeMagicCodeFromCart: PropTypes.func.isRequired,
    resetStoreCreditInCart: PropTypes.func.isRequired,
    removeStoreCreditFromCart: PropTypes.func.isRequired,
    removeAffiliateCookie: PropTypes.func.isRequired,
    resetFlow: PropTypes.func.isRequired
  },

  componentDidMount () {
    this.props.retrievePricingIfNotInFlight();
  },

  handleMagicCodeAdd (magicCodeStr) {
    this.props.addMagicCodeToCart(magicCodeStr);
  },

  handleStoreCreditAdd () {
    this.props.resetStoreCreditInCart();
  },

  handleMagicCodeRemove (magicCodeObj) {
    const magicCodeStr = magicCodeObj.magicCode;
    const userCreditId = magicCodeObj.id;
    const isAffiliateDiscount = magicCodeObj.type === 'affiliate-discount';
    if (userCreditId) {
      if (window.confirm('Are you sure you would like to remove this store credit?')) {
        this.props.removeStoreCreditFromCart(userCreditId);
      }
    } else if (isAffiliateDiscount) {
      if (window.confirm('Are you sure you would like to remove this affiliate discount? The affiliate will also not be credited.')) {
        this.props.removeAffiliateCookie();
      }
    } else {
      if (window.confirm('Are you sure you would like to remove this magic code?')) {
        this.props.removeMagicCodeFromCart(magicCodeStr);
      }
    }
  },

  render () {
    const { giftCertificates, lineItems, entity, isFetching, error } = this.props;
    const credits = (entity && entity.credits) || [];
    const orderAmount = (entity && entity.subtotalPrice) || 0;
    const { detectedCountry } = entity || {};

    const showShippingNote = lineItems && lineItems.length > 0 && detectedCountry && detectedCountry !== 'US';

    return (
      <div className="cart__container">
        <Header />

        <div className="cart clearfix">
          <h2 className="cart__header header">Cart</h2>

          <EntityDetail
            entity={entity}
            isFetching={isFetching}
            error={error}
            alwaysShowFetching={false}
            render={() => {
              if (lineItems.length === 0 && giftCertificates.length === 0) {
                return <CartEmpty />;
              }

              return (
                <div>
                  <div className="cart__left">
                    <CartLineItems history={this.props.history} lineItems={lineItems} />
                    <CartGiftCertificates giftCertificates={giftCertificates} />
                    <MobileLink to="/" onClick={() => this.props.resetFlow()} className="cart-line-items__cta">
                      <div className="cart-line-items__cta-button">
                        <i className="icon icon-add" />
                      </div>
                      Add another product using different artwork!
                    </MobileLink>
                  </div>

                  <div className="cart__right">
                    <CartPricing pricing={entity} 
                      onRemoveCode={this.handleMagicCodeRemove}  
                      onApplyStoreCredit={this.handleStoreCreditAdd} />
                    <CartMagicCode onAdd={this.handleMagicCodeAdd} 
                      affiliateCode={this.props.affiliateCode}
                      credits={credits} 
                      orderAmount={orderAmount} />
                    {showShippingNote && <CartShippingNote />}
                  </div>
                </div>
              );
            }} />
        </div>
      </div>
    );
  }
});

function mapStateToProps (state) {
  const affiliateCode = getCurrentUserAffiliateCode(state.user);
  const giftCertificates = getGiftCertificates(state.cart);
  const lineItems = expandedCartSelector(state);
  const { entity, isFetching, error } = getPricing(state.cart);

  return {
    giftCertificates,
    affiliateCode,
    lineItems,
    entity,
    isFetching,
    error
  };
}

const mapDispatchToProps = {
  retrievePricingIfNotInFlight,
  addMagicCodeToCart,
  removeMagicCodeFromCart,
  resetStoreCreditInCart,
  removeStoreCreditFromCart,
  removeAffiliateCookie,
  resetFlow
};

export default connect(mapStateToProps, mapDispatchToProps)(CartRoute);
