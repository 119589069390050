import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';

import { removeProductFromCart, updateQuantityInCart } from '../../actions/cart';
import { isMobileApp, sendAppMessage } from '../../utils/mobile-app';
import { addFlowFromCart } from '../../actions/flow';

import CartLineItemRow from './cart-line-item-row';

const CartLineItems = createReactClass({
  propTypes: {
    history: PropTypes.object.isRequired,
    lineItems: PropTypes.array.isRequired,
    addFlowFromCart: PropTypes.func.isRequired,
    removeProductFromCart: PropTypes.func.isRequired,
    updateQuantityInCart: PropTypes.func.isRequired
  },

  handleCallToAction (uniqueId) {
    this.props.addFlowFromCart({
      uniqueId
    });

    if (isMobileApp()) {
      sendAppMessage('reviewCartAddon');
    } else {
      this.props.history.push('/product/review');
    }
  },

  handleEditClick (lineItem) {
    this.props.addFlowFromCart({
      uniqueId: lineItem.parentUniqueId || lineItem.uniqueId
    });

    if (isMobileApp()) {
      if (lineItem.parentUniqueId) {
        sendAppMessage('reviewCartAddon');
      } else {
        sendAppMessage('showEditor');
      }
    } else {
      if (lineItem.parentUniqueId) {
        this.props.history.push('/product/review');
      } else {
        if (lineItem.userDesign) {
          this.props.history.push(`/design/${lineItem.userDesign.code}`);    
        } else {
          this.props.history.push('/');
        }
      }
    }
  },

  handleQuantityChange (uniqueId, quantity) {
    this.props.updateQuantityInCart({
      quantity,
      uniqueId
    });
  },

  handleRemoveClick (uniqueId) {
    if (window.confirm('Are you sure you would like to remove this item from your cart?')) {
      this.props.removeProductFromCart({ uniqueId });
    }
  },

  renderLineItems () {
    const parentLineItems = _.filter(this.props.lineItems, (lineItem) => {
      return !lineItem.parentUniqueId;
    });

    return _.map(parentLineItems, (lineItem) => {
      const childLineItems = _.filter(this.props.lineItems, (childLineItem) => {
        return childLineItem.parentUniqueId === lineItem.uniqueId;
      });

      return (
        <div key={`parent${lineItem.uniqueId}`} className="cart-line-items__group">
          <CartLineItemRow
            key={`lineItem${lineItem.uniqueId}`}
            lineItem={lineItem}
            onEditClick={_.partial(this.handleEditClick, lineItem)}
            onQuantityChange={_.partial(this.handleQuantityChange, lineItem.uniqueId)}
            onRemoveClick={_.partial(this.handleRemoveClick, lineItem.uniqueId)} />

          {_.map(childLineItems, (childLineItem) => (
            <CartLineItemRow
              key={`lineItem${childLineItem.uniqueId}`}
              lineItem={childLineItem}
              onEditClick={_.partial(this.handleEditClick, childLineItem)}
              onQuantityChange={_.partial(this.handleQuantityChange, childLineItem.uniqueId)}
              onRemoveClick={_.partial(this.handleRemoveClick, childLineItem.uniqueId)} />
          ))}

          <div className="cart-line-items__group__cta" onClick={_.partial(this.handleCallToAction, lineItem.uniqueId)}>
            <i className="icon icon-copy" /> <span>Add another product</span> using the exact same artwork and save!
          </div>
        </div>
      );
    });
  },

  render () {
    return (
      <div className="cart-line-items">
        {this.renderLineItems()}
      </div>
    );
  }
});

function mapStateToProps () {
  return {

  };
}

const mapDispatchToProps = {
  addFlowFromCart,
  removeProductFromCart,
  updateQuantityInCart
};

export default connect(mapStateToProps, mapDispatchToProps)(CartLineItems);
