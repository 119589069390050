import { toast } from 'react-toastify';

export { toast };

export function updateToast (toastId, message, options={}) {
  toast.update(toastId, {
    type: toast.TYPE.SUCCESS,
    render: message,
    hideProgressBar: true,
    autoClose: 1500,
    ...options
  });
}