import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setIsLoading, setTemplateOptions } from '../../actions/editor';
import { allVariantsSelected, getTemplate, getOptionsName } from '../../utils/editor/products';
import ProductPicker from './product-picker';
import UploadVariants from '../../components/products/upload-variants';
import Button from './button';
import Modal from 'react-modal';
import { ReactComponent as ExpandSVG } from '../../assets/icons/arrowb-down.svg';

Modal.setAppElement('#root');

const TemplatePicker = ({ onChange }) => {
  const dispatch = useDispatch();
  const sku = useSelector(state => state.editor.templateOptions.sku);
  const variants = useSelector(state => state.editor.templateOptions.variants);
  const designersName = useSelector(state => state.editor.templateOptions.designersName);
  const products = useSelector(state => state.products.list.entities);
  
  const [ newDesignersName, setNewDesignersName ] = useState(designersName);
  const [ newSku, setNewSku ] = useState(sku);
  const [ newVariants, setNewVariants ] = useState(variants);

  const [ showModal, setShowModal ] = useState(false);
  const product = products.find(product => product.sku === sku);
  const newProduct = products.find(product => product.sku === newSku);
  
  const loadTemplate = async template => {
    if (template) {
      dispatch(setIsLoading(true));
      const { default: svgPath } = await import(`../../assets/templates/${template.filename}`);
      const { default: svgComponent } = await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/templates/${template.filename}`);
      onChange({ ...template, svgComponent, svgPath });
      dispatch(setIsLoading(false));
    } else {
      onChange(null);
    }
  }

  useEffect(() => {
    const template = getTemplate(sku, variants);
    loadTemplate(template);
  }, [ sku, variants ]);

  const applyChanges = () => {
    dispatch(setTemplateOptions({ 
      sku: newSku,
      variants: newVariants,
      designersName: newDesignersName
    }));
    const template = getTemplate(newSku, newVariants);
    loadTemplate(template);
    setShowModal(false);
  }

  const toggleModal = () => {
    if (showModal) {
      setShowModal(false);
    } else {
      // Initialize Local State from store
      setNewSku(sku);
      setNewVariants(variants);
      setNewDesignersName(designersName);
      setShowModal(true);
    }
  }

  const handleNewSku = sku => {
    if (sku !== newSku) {
      setNewSku(sku);
      setNewVariants([]);
    }
  }

  const handleDesignersNameChange = name => {
    setNewDesignersName(name);
  }

  const canApplyChanges = () => {
    if (!allVariantsSelected(newProduct, newVariants)) {
      return false;
    }
    if (!newDesignersName) {
      return false;
    }
    return true;
  }

  let buttonLabel = 'Select Product';
  if (allVariantsSelected(product, variants)) {
    buttonLabel = getOptionsName(product, variants) || buttonLabel;
  }

  return (<>
    <Button onClick={toggleModal} className="template-button" wide>
      <div>{buttonLabel}</div>
      <ExpandSVG />
      <label>Template</label>
    </Button>
    <Modal 
      className="modal-content modal-fullsize template-picker__modal" 
      overlayClassName="modal fade in"
      onRequestClose={toggleModal}
      isOpen={showModal}>
      <button onClick={toggleModal} 
        className="close-modal"><i className="fa fa-close" /></button>
      <div className="modal-body">
        <h1 className="header header--small">Choose a Template</h1>
        <section className="template-picker__body">
          <ProductPicker onChange={handleNewSku} value={newSku} />
          <UploadVariants
            product={newProduct}
            values={newVariants}
            designersName={newDesignersName}
            onVariantsChange={setNewVariants} 
            onDesignersNameChange={handleDesignersNameChange} />
        </section>
        <footer className="template-picker__buttons">
          <button className="button plain" onClick={toggleModal}>Cancel</button>
          <button className="button primary text--light" onClick={applyChanges} disabled={!canApplyChanges()}>OK</button>
        </footer>
      </div>
    </Modal>
  </>);
};

export default TemplatePicker;