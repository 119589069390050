import _ from 'lodash';

const FILTER_KEYS = ['category', 'type', 'sort'];

export function toFilterObject(filterPath) {
  if (!filterPath) return {};
  return _.pick(_.fromPairs(_.chunk(filterPath.split('/'), 2)), FILTER_KEYS);
}

export function toFilterPath(filterObject, baseUrl) {
  if (!filterObject) return '';
  const filterPath = [];
  FILTER_KEYS.forEach(key => {
    if (filterObject[key]) {
      filterPath.push(key, filterObject[key]);
    }
  });
  if (baseUrl) {
    filterPath.unshift(baseUrl);
  }
  return filterPath.join('/');
}