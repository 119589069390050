import React from 'react';
import { ReactComponent as DressSVG } from '../../assets/templates/PTD01_C05.svg';
import { ReactComponent as ShirtSVG } from '../../assets/templates/PTS01_C.svg';
import { ReactComponent as BeanieSVG } from '../../assets/templates/PTB01_all.svg';

const PRODUCT_OPTIONS = [
  {
    sku: 'PTD01', 
    name: 'Dress',
    svg:  DressSVG
  },
  {
    sku: 'PTS01', 
    name: 'T-shirt',
    svg: ShirtSVG
  },
  {
    sku: 'PTB01', 
    name: 'Beanie',
    svg: BeanieSVG 
  }
];
 
const ProductPicker = ({ onChange, value }) => {
    return (<div className="product-picker">
    {PRODUCT_OPTIONS.map(product => {
      const SvgImage = product.svg;
      const isActive = product.sku === value;
      return (<button className={isActive ? 'active' : ''}
        sku={product.sku} 
        key={product.sku} 
        onClick={() => onChange(product.sku)}>
          <SvgImage />
          {product.name}
          {isActive && <div>
            <i className="fa fa-lg fa-check-circle" />
          </div>}
        </button>)
    })}
  </div>);
};

export default ProductPicker;