import React, { useState } from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';

const ColorPickerPresets = [
  'ffc100', 'ff6f00', 'ff0000',
  'f200a7', 'a600e1', '0055ff',
  '1ddbb1', '00bd1e', '272727'
];

const ColorPicker = ({ value, onChange }) => {
  const [ isOpen, setOpen ] = useState(false);
  const buttonStyle = value 
    ? { backgroundColor: `#${value}`}
    : {};
  return (
    <Popover
      containerClassName="color-picker__container"
      position="bottom"
      isOpen={isOpen}
      content={(po) => (<ArrowContainer
        arrowColor="white"
        position={po.position}
        targetRect={po.targetRect}
        popoverRect={po.popoverRect}>
        <div className="color-picker">
          {ColorPickerPresets.map((color) => {
            const swatchStyle = { backgroundColor: `#${color}` };
            return (<button 
              onClick={() => {
                setOpen(false);
                onChange(color);
              }}
              key={color} 
              style={swatchStyle} 
              className="color-picker__swatch">
                {value === color && (<i className="fa fa-check" />)}
            </button>);
          })}
        </div>
      </ArrowContainer>)}
    >
      <button 
        onClick={() => setOpen(!isOpen)}
        style={buttonStyle} 
        className="circle-icon-button color-picker__button" >
        <i className="fa fa-eyedropper" />
      </button>
    </Popover>
  );
};

export default ColorPicker;