import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { reduxForm, Field } from 'redux-form';
import CheckoutField from '../checkout/checkout-field';
import {
  combineValidators,
  isRequired
} from 'revalidate';

const MyProductForm = createReactClass({

  propTypes: {
    onSubmit: PropTypes.func.isRequired
  },

  render() {
    const { handleSubmit, onCancel } = this.props;
    return (
      <form className="user-store-form" onSubmit={handleSubmit(this.props.onSubmit)}>

        <label htmlFor="name">Product Name</label>
        <Field name="name" component={CheckoutField} type="text" className="checkout__input" placeholder="Product Name" maxLength="45" />

        <label htmlFor="description">Product Description</label>
        <Field name="description" component={CheckoutField} type="textarea" className="checkout__input" placeholder="Product Description" maxLength="500" />

        <div className="user-store-form__buttons">
          <button className="button primary" type="submit"><i className="fa fa-check" /> Save</button>
          <button className="button plain" onClick={onCancel}>Cancel</button>
        </div>
      </form>
    );
  }
});

const validate = combineValidators({
  'name': isRequired('Product Name')
});

export default reduxForm({
  form: 'user-store-product-form',
  validate,
  shouldValidate: () => true
})(MyProductForm);