import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { canRenderInBrowser } from '../../utils/image';
import _ from 'lodash';

const UploadThumbnail = createReactClass({
  
  propTypes: {
    upload: PropTypes.object.isRequired,
    userDesign: PropTypes.object,
    retries: PropTypes.number
  },

  getInitialState() {
    return {
      isLoading: true,
      thumbnailUrl: null
    }
  },

  componentDidMount() {
    const { retries, userDesign } = this.props;
    let retryCount = 0;
    let thumbnailUrl = _.get(this.props.upload, 'thumbnails.icon') || _.get(this.props.upload, 'thumbnails.small');
    if (userDesign) {
      const mainImage = (_.get(userDesign, 'images') || []).find(image => image.imageOrder === 0);
      thumbnailUrl = _.get(mainImage, 'upload.thumbnails.small') // User-selected image
        || _.get(userDesign, 'upload.thumbnails.small') // UserProduct
        || _.get(userDesign, 'upload.thumbnails.card') // Old product?
        || thumbnailUrl; // Use the upload image (generated on demand)
    }
    if (thumbnailUrl && canRenderInBrowser(thumbnailUrl)) {
      const image = new Image();
      image.onload = () => {
        this.setState({ 
          thumbnailUrl,
          isLoading: false
        });
      }
      image.onerror = () => {
        if (retries && retryCount < retries) {
          retryCount = retryCount + 1;
          setTimeout(function() {
            image.src = thumbnailUrl + '?retry=' + retryCount;
          }, 1000);
        } else {
          // Give up and use default SKU thumbnail
          this.setState({ 
            isLoading: false 
          });
        }
      }
      image.src = thumbnailUrl;
    } else {
      // No thumbnail available
      this.setState({ 
        isLoading: false 
      });
    }
  },

  render() {
    const { thumbnailUrl, isLoading } = this.state;
    
    if (isLoading) {
      return (
        <div className="review-line-item__thumbnail-loading"><i className="fa fa-spin fa-circle-o-notch" /></div>
      );
    }

    let thumbnailStyle = {};
    if (thumbnailUrl) {
      thumbnailStyle = {
        backgroundImage: `url(${thumbnailUrl})`,
        backgroundSize: 'cover'
      }
    }

    return (<div className="review-line-item__thumbnail" style={thumbnailStyle}></div>);
  }

});

export default UploadThumbnail;
