import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ContinueBar = ({ actionText, checkboxText, actionEnabled, onBackClick, onContinueClick, 
  onDisabledClick, onCheckboxCheck }) => {

  const fakeDisabled = !actionEnabled && onDisabledClick;

  const continueClassName = classNames({
    'button': true,
    'primary': true,
    'continue-bar__action': true,
    'continue-bar__action-disabled': fakeDisabled
  });

  return (
    <div className="continue-bar">
      <div className="continue-bar__content">
        <button className="button continue-bar__back" onClick={onBackClick}>Back</button>
        {checkboxText ? <label className="continue-bar__checkbox">
          <input type="checkbox" className="checkbox" onClick={onCheckboxCheck} value="1" />
          {checkboxText}
        </label> : null}
        <button className={continueClassName} disabled={!actionEnabled && !fakeDisabled} 
          onClick={fakeDisabled ? onDisabledClick : onContinueClick}>
          {actionText}
          <i className="icon icon-arrow-b" />
        </button>
      </div>
    </div>
  );
};

ContinueBar.propTypes = {
  actionText: PropTypes.string.isRequired,
  checkboxText: PropTypes.node,
  actionEnabled: PropTypes.bool.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onContinueClick: PropTypes.func.isRequired,
  onDisabledClick: PropTypes.func,
  onCheckboxCheck: PropTypes.func
};

export default ContinueBar;
