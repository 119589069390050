import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import VariantSelector from './variant-selector';
import ViewSizeChart from './view-size-chart';

const UploadVariants = createReactClass({
  propTypes: {
    product: PropTypes.object.isRequired,
    designersName: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
    onVariantsChange: PropTypes.func.isRequired,
    onDesignersNameChange: PropTypes.func.isRequired,
    hideSizeChart: PropTypes.bool
  },

  handleDesignersNameChange (event) {
    this.props.onDesignersNameChange(event.target.value);
  },

  render () {
    return (
      <div className="upload-variants">
        <VariantSelector
          product={this.props.product}
          values={this.props.values}
          onVariantsChange={this.props.onVariantsChange} />

        {!this.props.hideSizeChart && (<ViewSizeChart className="variant-selector__size" sku={this.props.product.sku} />)}

        <input
          className="variant-selector__input variant-selector__designer text--light"
          maxLength={20}
          placeholder="Please enter designer's name..."
          value={this.props.designersName}
          onChange={this.handleDesignersNameChange} />
      </div>
    );
  }
});

export default UploadVariants;
