import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { injectStripe } from 'react-stripe-elements';
import { formValueSelector } from 'redux-form';

import { createOrder } from '../actions/checkout';
import { createUser, signInUser, fetchUserIfNeeded } from '../actions/user';
import { getGiftCertificates, getPricing } from '../reducers/cart';
import { getCurrentUser } from '../reducers/user';
import { expandedCartSelector } from '../selectors/cart';
import Moose from '../clients/moose';
import { trackInitiateCheckout } from '../utils/tracking';

import CheckoutForm from '../components/checkout/checkout-form';
import AuthenticationForm from '../components/checkout/authentication-form';
import CheckoutError from '../components/checkout/checkout-error';
import LoadingSpinner from '../components/meta/loading-spinner';

const CheckoutRoute = createReactClass({
  propTypes: {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    stripe: PropTypes.object.isRequired,
    giftCertificates: PropTypes.array.isRequired,
    lineItems: PropTypes.array.isRequired,
    user: PropTypes.object,
    isFetchingUser: PropTypes.bool.isRequired,
    createOrder: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    signInUser: PropTypes.func.isRequired,
    showBilling: PropTypes.bool.isRequired
  },

  getInitialState () {
    const { existingUser } = this.props.location.state || {};
    return {
      authSegmentIndex: existingUser ? 1 : 0,
      showUserFetching: this.props.isFetchingUser,
      stripeComplete: false,
      billingError: null,
      stripeSources: [],
      tokenError: null,
      otherError: null,
      showBillingError: false,
      isFetchingPayments: false
    };
  },

  fetchPaymentSources() {
    if (this.props.user) {
      this.setState({ isFetchingPayments: true });
      Moose.fetchPaymentMethods().then((payment) => {
        this.setState({ stripeSources: payment.sources, isFetchingPayments: false });
      });
    }
  },

  componentDidMount () {
    const { lineItems, giftCertificates } = this.props;
    if (lineItems.length === 0 && giftCertificates.length === 0) {
      this.props.history.push('/cart');
    }
    this.fetchPaymentSources();
    trackInitiateCheckout({ lineItems, giftCertificates });
  },

  componentDidUpdate (prevProps, prevState) {
    if (this.props.user && (!prevProps.user || prevProps.user.id !== this.props.user.id)) {
      this.fetchPaymentSources();
    }
  },

  componentWillReceiveProps (nextProps) {
    if (!nextProps.isFetchingUser) {
      this.setState({
        showUserFetching: false
      });
    }
  },

  handleAuthTypeChange (authSegmentIndex) {
    this.setState({
      authSegmentIndex
    });
  },

  handleStripeChange ({ error, complete }) {
    this.setState({
      stripeComplete: complete,
      billingError: error
    });
  },

  handleSignIn(values) {
    this.setState({
      otherError: null
    });
    if (this.state.authSegmentIndex === 0) {
      const { signUp: { email, name, password }} = values;
      return this.props.createUser({
        email,
        name,
        password
      });
    } else {
      const { signIn: { email, password }} = values;
      return this.props.signInUser({
        email,
        password
      });
    }
  },

  handleSubmit (values) {
    window.scrollTo(0, 0);

    this.setState({
      otherError: null,
      tokenError: null
    });

    /*
     * Check for billing completion
     */

    const { showBilling } = this.props;
    const { stripeComplete, billingError } = this.state;
    const stripeSource = _.get(values, 'billing.stripeSource');
    const billingComplete = (stripeSource === 'new' && stripeComplete) ||
      (stripeSource && stripeSource !== 'new');

    if (showBilling && (!billingComplete || billingError)) {
      this.setState({
        showBillingError: true
      });

      return null;
    }

    /*
     * Start the authentication/ordering process
     */

    let orderPromise = Promise.resolve();

    if (!this.props.user) {
      this.setState({ 
        otherError: 'Please create an account or sign in to check out.'
      });
      return Promise.reject();
    }

    return orderPromise.then(() => {
      if (!showBilling || stripeSource !== 'new') return {};

      return this.props.stripe.createToken({
        // This definitely needs to go through this.props or it will
        // not be available when the user gets signed in.
        name: this.props.user.name
      });
    }).then(({ token, error }) => {

      if (error) {
        this.setState({
          tokenError: error,
          showBillingError: true
        });

        throw error;
      }
      return this.props.createOrder({
        shippingAddress: values.shipping,
        billing: values.billing,
        stripeToken: token
      });
    }).then(() => {
      this.props.fetchUserIfNeeded();
      this.props.history.replace('/checkout/confirmation');
    }).catch(error => {
      this.setState({ 
        otherError: 'Sorry, there was a problem placing your order. Please try again.'
      });
      Moose.debugLog( error );
      throw error;
    });
  },

  render () {
    if (this.state.showUserFetching) {
      return <LoadingSpinner />;
    }

    const { user } = this.props;
    const { stripeComplete, billingError, showBillingError, tokenError, stripeSources, otherError } = this.state;

    let billingErrorMessage = null;

    if (!stripeComplete) {
      billingErrorMessage = 'Please enter your billing information.';
    }

    if (tokenError) {
      billingErrorMessage = tokenError.message;
    }

    if (billingError) {
      billingErrorMessage = billingError.message;
    }

    if (!showBillingError) {
      billingErrorMessage = null;
    }

    const initialValues = {
      shipping: { country: 'US', saveForLater: true },
      billing: { saveForLater: false, stripeSource: 'new' }
    };

    if (user && user.savedShippingAddress) {
      initialValues.shipping = _.pick(user.savedShippingAddress, [
        'name', 'street1', 'street2', 'city', 'state', 'postalCode', 'country', 'phone'
      ]);
    }

    return (
      <div className="checkout">
        <div className="checkout__content">
          <div className="checkout__logo" />
          <h2 className="checkout__header header">Checkout</h2>

          {otherError && <CheckoutError>{otherError}</CheckoutError>}

          {!user && <AuthenticationForm
            title="Your Info"
            from="checkout"
            authSegmentIndex={this.state.authSegmentIndex}
            onAuthTypeChange={this.handleAuthTypeChange}
            onSubmit={this.handleSignIn} />}

          <CheckoutForm
            initialValues={initialValues}
            billingError={billingErrorMessage}
            stripeComplete={stripeComplete}
            stripeSources={stripeSources}
            onStripeChange={this.handleStripeChange}
            showBilling={this.props.showBilling}
            showShipping={this.props.showShipping}
            isInternational={this.props.isInternational}
            user={this.props.user}
            onSubmit={this.handleSubmit} />

        </div>
      </div>
    );
  }
});

function mapStateToProps (state) {
  const giftCertificates = getGiftCertificates(state.cart);
  const { entity: pricing } = getPricing(state.cart);
  const lineItems = expandedCartSelector(state);
  const { entity: user, isFetching: isFetchingUser } = getCurrentUser(state.user);
  const selector = formValueSelector('checkout');
  const shippingCountry = selector(state, 'shipping.country');

  return {
    giftCertificates,
    pricing,
    lineItems,
    isInternational: shippingCountry && shippingCountry !== 'US',
    user,
    isFetchingUser,
    showBilling: pricing ? pricing.finalPrice > 0 : true,
    showShipping: lineItems.length > 0
  };
}

const mapDispatchToProps = {
  createOrder,
  createUser,
  signInUser,
  fetchUserIfNeeded
};

export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(CheckoutRoute));
