import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo.svg';

const HeaderBar = ({}) => {
  return (
    <div className="navigation-bar">
      <div className="navigation-bar__logo">
        <Link to="/home"><img src={Logo} /></Link>
      </div>
    </div>
  );
};

export default HeaderBar;
