import React from 'react';
import PropTypes from 'prop-types';

import { formatPriceShort } from '../../utils/currency';

const DualPrice = ({ subtotalPrice, finalPrice }) => {
  if (!subtotalPrice || !finalPrice) {
    return <div className="dual-price">--</div>;
  }

  return (
    <div className="dual-price">
      {subtotalPrice !== finalPrice && <span className="dual-price__subtotal">{formatPriceShort(subtotalPrice)}</span>}
      {formatPriceShort(finalPrice)}
    </div>
  );
};

DualPrice.propTypes = {
  subtotalPrice: PropTypes.number,
  finalPrice: PropTypes.number
};

export default DualPrice;
