import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { reduxForm, Field } from 'redux-form';
import CheckoutField from '../checkout/checkout-field';
import CheckoutCheckbox from '../checkout/checkout-checkbox';
import AffiliateTOS from '../affiliate/affiliate-tos';
import AffiliateModal from '../affiliate/affiliate-modal';
import { isValidCode, isChecked } from '../../utils/validators';
import {
  combineValidators,
  composeValidators,
  isRequired
} from 'revalidate';

const MyStoreForm = createReactClass({

  propTypes: {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    storeCode: PropTypes.string,
    errorMessage: PropTypes.string,
    storeExists: PropTypes.bool
  },

  getInitialState() {
    return {
      showTOS: false
    };
  },

  handleShowTOS(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showTOS: !this.state.showTOS });
  },

  render() {
    const { handleSubmit, errorMessage, storeExists, user={} } = this.props;
    return (
      <form className="user-store-form" onSubmit={handleSubmit(this.props.onSubmit)}>

        {errorMessage ? (<div className="error-message">
          {errorMessage}
        </div>) : null}

        <label htmlFor="name">Store Name</label>
        <Field name="name" component={CheckoutField} type="text" className="checkout__input" placeholder="Store Name" maxLength="50" />

        <label htmlFor="name">Store Description</label>
        <Field name="description" component={CheckoutField} type="textarea" className="checkout__input" placeholder="Store Description" maxLength="500" />

        <div className="user-store-url">
          <div className="user-store-url-prefix">
            {process.env.REACT_APP_URL}/shop/
          </div>
          <Field name="code" component={CheckoutField} type="text" className="checkout__input" placeholder="Store URL" maxLength="40" minLength="5" />
        </div>

        {!user.affiliate && (<p>
          <Field name="termsAccepted" component={CheckoutCheckbox}>
            I agree to the <button className="button plain" 
              onClick={this.handleShowTOS}>terms of service</button>.
              <AffiliateModal show={this.state.showTOS} onToggle={this.handleShowTOS}>
                <AffiliateTOS />
              </AffiliateModal>
          </Field>
        </p>)}

        <br />

        <div className="user-store-form__buttons">
          {storeExists 
            ? (<>
              <button className="button primary" type="submit"><i className="fa fa-check" /> Save</button>
              <button className="button plain" onClick={this.props.onCancel}>Cancel</button>            
            </>)
            : (
              <button className="button primary" type="submit"><i className="fa fa-check" /> Create My Shop</button>
            )}

        </div>
        
      </form>
    );
  }

});

const validate = combineValidators({
  'code': composeValidators(
    isRequired,
    isValidCode
  )('Store URL'),
  'termsAccepted': isChecked('Terms of Service'),
  'name': isRequired('Store Name'),
});

export default reduxForm({
  form: 'user-store-form',
  validate,
  shouldValidate: () => true
})(MyStoreForm);