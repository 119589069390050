export const createCanvas = (width,height) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas;
}

export const createImage = (src, width) => {
  const img = new Image();
  img.src = src;
  if (width) {
    img.width = width;
  }
  img.crossOrigin = 'anonymous';
  return img;
}