import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';

import Moose, { uploadBlob } from '../clients/moose';
import { setFlowLineItemVariants, retrieveFlowPricing, setFlowProperties, setProductSku, resetFlow } from '../actions/flow';
import { getFlowData, getFlowPricing } from '../reducers/flow';
import { getProductBySku } from '../reducers/products';
import { formatPriceShort } from '../utils/currency';
import { createBlob, getTemplate } from '../utils/editor';
import { parseDescriptionLineBreaks } from '../utils/string';

import Header from '../components/structure/header';
import Footer from '../components/structure/footer';
import VariantSelector from '../components/products/variant-selector';
import ViewSizeChart from '../components/products/view-size-chart';
import UserStoreWrapper from '../components/user-store/user-store-wrapper';
import ProfileImage from '../components/user-store/profile-image';
import ProductImages from '../components/user-store/product-images';
import UserProduct from '../components/user-store/product';
import LoadingSpinner from '../components/meta/loading-spinner';
import DescriptionSection from '../components/products/description-section';
import { Link } from 'react-router-dom';
import MobileLink from '../components/mobile-app/Link';
import _ from 'lodash';

const UserProductRoute = createReactClass({
  propTypes: {
    match: PropTypes.shape({
      params: PropTypes.shape({
        code: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    designCode: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    designersName: PropTypes.string.isRequired,
    uniqueId: PropTypes.string.isRequired,
    upload: PropTypes.object,
    userDesign: PropTypes.object,
    variants: PropTypes.array.isRequired,
    setFlowLineItemVariants: PropTypes.func.isRequired,
    setFlowProperties: PropTypes.func.isRequired,
    setProductSku: PropTypes.func.isRequired
  },

  componentDidMount() {
    const { designCode } = this.props;
    this.loadUserDesign(designCode);
  },

  componentDidUpdate(prevProps) {
    const { designCode } = this.props;
    if (designCode !== prevProps.designCode) {
      this.loadUserDesign(designCode);
    }
  },

  loadUserDesign(code) {
    const { userDesign } = this.props;
    const isNewProduct = !userDesign || userDesign.code !== code;
    this.setState({ isLoading: true });
    Moose.fetchUserDesign(code).then(userDesign => {
      if (isNewProduct) {
        this.props.resetFlow();
        this.props.setProductSku(userDesign.product.sku);
      }
      this.props.setFlowProperties({ 
        userDesign,
        designersName: userDesign.designersName || userDesign.store.name,
        upload: userDesign.upload
      });
      this.props.retrieveFlowPricing();
      this.setState({ isLoading: false, isError: false });
    }).catch(e => {
      this.setState({ isLoading: false, isError: true });
    });
  },

  getInitialState() {
    return {
      isLoading: false,
      isError: false
    }
  },

  handleContinueClick () {
    const { userDesign, history } = this.props;
    this.setState({ isLoading: true });
    if (userDesign.pixelDesign) {
      const { product: { sku }, variants, data } = userDesign.pixelDesign;
      const template = getTemplate(sku, variants);
      createBlob(data, template).then(async (blob) => {
        const upload = await uploadBlob(blob);
        this.props.setFlowProperties({ upload });
        this.setState({ isLoading: false });
        history.push('/product/review');
      });
    }
  },

  handleProductSelect (sku) {
    this.props.setProductSku(sku);
  },

  handleVariantChange (variants) {
    this.props.setFlowLineItemVariants({ uniqueId: this.props.uniqueId, variants });
    this.props.retrieveFlowPricing();
  },

  renderNotFound() {
    return (<div className="design">
      <Header />
      <div className="design__content design__not-found">
        <h2 className="header header--main design__title">Oops!</h2>
        <p>Sorry, we couldn't find that user design. Either the designer or we may have removed it for various reasons.</p>
        <p>Would you like to make your own design instead?</p>
        <br />
        <Link className="button primary" to="/">Design a Product</Link>
      </div>
    </div>);
  },

  render () {
    const { userDesign, product, products, unitPrice } = this.props;
    const { isLoading, isError } = this.state;

    if (isLoading) {
      return (<LoadingSpinner />);
    }

    if (!userDesign || isError) {
      return this.renderNotFound();
    }

    let actionEnabled = false;
    if (this.props.variants.length === this.props.product.variants.length) {
      actionEnabled = true;
    }

    const storeName = _.get(userDesign, 'store.name');
    const accentColor = _.get(userDesign, 'store.accentColor');
    const profileImage = _.get(userDesign, 'store.profileImage.thumbnails.profile') || _.get(userDesign, 'store.gravatar');
    const otherProducts = _.get(userDesign, 'store.otherProducts') || [];
    const descriptionSections = _.get(userDesign, 'product.descriptionSections') || [];
    const productPrice = unitPrice || product.price;

    return (
      <div className="user-store__container">
        <Header styleColor={`#${accentColor || 'ff6f00'}`}/>
        <UserStoreWrapper store={userDesign.store} minimal>
          <div className="user-product-details">
            <div>
              <ProductImages product={userDesign} />
            </div>
            <div>
              <div className="user-product-info">
                <h3>{userDesign.name}</h3>
                {parseDescriptionLineBreaks(userDesign.description)}
                <div className="user-product-price">{formatPriceShort(productPrice)}</div>
              </div>
              {descriptionSections.map((dS) => (<DescriptionSection key={dS.id} descriptionSection={dS} />))}
              <div className="product-description-section">
                <ViewSizeChart sku={this.props.product.sku}>
                  Size Chart <i className="icon icon-size" />
                </ViewSizeChart>
              </div>
              <div className="user-product-options">
                <VariantSelector
                  product={this.props.product}
                  values={this.props.variants}
                  onVariantsChange={this.handleVariantChange} />

                  <button className="button primary" disabled={!actionEnabled} onClick={this.handleContinueClick}>ADD TO CART</button>
              </div>
              <div className="store-owner">
                <ProfileImage image={profileImage} />
                by
                <MobileLink to={`/shop/${_.get(userDesign, 'store.code')}`} title={storeName}>{storeName}</MobileLink>
              </div>
            </div>
          </div>
          {otherProducts.length > 0 && (<div className="other-designs-by-user">
            <h2>Other designs by {storeName}</h2>
            <div className="user-store-products user-store-products-center">
            {otherProducts.map(userProduct => <UserProduct 
              key={userProduct.code} 
              userProduct={userProduct} 
              productData={products}
              linkTo={`/design/${userProduct.code}`} />)}
            </div>
          </div>)}
      </UserStoreWrapper>
      <Footer />
    </div>);
  }
});

function mapStateToProps (state, props) {
  const { designersName, lineItems, upload, userDesign } = getFlowData(state.flow);
  const lineItemsPricing = _.get(getFlowPricing(state.flow), 'entity.lineItems') || [];
  const currentItemPricing = lineItemsPricing.find(li => li.uniqueId === lineItems[0].uniqueId);
  const product = getProductBySku(state.products, lineItems[0].sku);
  const designCode = props.match.params.code;
  
  return {
    designCode,
    product,
    unitPrice: _.get(currentItemPricing, 'unitPrice'), 
    products: state.products.list.entities,
    designersName,
    uniqueId: lineItems[0].uniqueId,
    upload,
    variants: lineItems[0].variants,
    userDesign
  };
}

const mapDispatchToProps = {
  setFlowLineItemVariants,
  retrieveFlowPricing,
  setFlowProperties,
  setProductSku,
  resetFlow
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProductRoute);
