import React from 'react';
import { connect } from 'react-redux';
import { toFilterObject, toFilterPath } from '../utils/path';
import Moose from '../clients/moose';

import Header from '../components/structure/header';
import Footer from '../components/structure/footer';
import UserProduct from '../components/user-store/product';
import LoadingSpinner from '../components/meta/loading-spinner';
import FilterDropdown from '../components/structure/filter-dropdown';
import { Waypoint } from 'react-waypoint';
import _ from 'lodash';

const PRODUCT_FILTER_OPTIONS = [
  ['all', 'All'],
  ['PTD01', 'Dresses'],
  ['PTS01', 'T-Shirts'],
  ['PTB01', 'Beanies'],
];

const SORT_FILTER_OPTIONS = [
  ['popular', 'Popular'],
  ['newest', 'Newest']
];

class ShopsRoute extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoadingPage: false,
      userDesigns: []
    };
    this.updateFilters = this.updateFilters.bind(this);
    this.onScrollEnd = this.onScrollEnd.bind(this);
  }

  componentDidMount() {
    const { filters } = this.props;
    this.getDesigns(filters);
  }
  
  componentDidUpdate(prevProps) {
    const { match: { url }, filters } = this.props;
    const { match: { url: oldUrl } } = prevProps;
    if (url !== oldUrl) {
      this.getDesigns(filters);
    }
  }

  getDesigns(filters) {
    this.setState({ isLoading: true, page: 1 });
    Moose.exploreDesigns({ ...filters, pixelDesigns: true }).then(userDesigns => {
      this.setState({ isLoading: false, userDesigns });
    }).catch(e => {
      this.setState({ isLoading: false, userDesigns: [], error: true });
    });
  }

  getNextPage(filters) {
    const page = this.state.page + 1;
    this.setState({ isLoadingPage: true });
    Moose.exploreDesigns({ ...filters, pixelDesigns: true, page }).then(userDesigns => {
      if (userDesigns && userDesigns.length > 0) {
        this.setState({ isLoadingPage: false, userDesigns: [ ...this.state.userDesigns, ...userDesigns ], page });
      } else {
        this.setState({ isLoadingPage: false });
      }
    }).catch(e => {
      this.setState({ isLoadingPage: false, error: true });
    });
  }

  updateFilters(name, value) {
    const { history, filters } = this.props;
    const nextPath = toFilterPath({ ...filters, [name]: value }, '/shops');
    history.push(nextPath);
  }

  onScrollEnd() {
    const { isLoading, isLoadingPage } = this.state;
    const { filters } = this.props;
    if (!isLoading && !isLoadingPage) {
      this.getNextPage(filters);
    }
  }

  render () {
    const { isLoading, isLoadingPage, userDesigns } = this.state;
    const { products, filters } = this.props;

    const className = userDesigns.length < 3 
      ? 'user-store-products user-store-products-center' 
      : 'user-store-products';

    return (<div className="user-store__container">
      <Header />
      <div className="user-store__hero"></div>
      <div className="my-account user-store-wrapper clearfix">
        <div className="shops-controls">
          <h3 className="header">Filter Products</h3>
          <FilterDropdown 
            labelPrefix="Products: "
            value={filters['type']} 
            options={PRODUCT_FILTER_OPTIONS} 
            onChange={_.partial(this.updateFilters, 'type')} />
          <FilterDropdown 
            value={filters['sort']}
            options={SORT_FILTER_OPTIONS} 
            onChange={_.partial(this.updateFilters, 'sort')} />
        </div>
        <div className={className}>
          {isLoading
            ? <LoadingSpinner />
            : userDesigns.map(userProduct => <UserProduct 
              key={userProduct.code} 
              userProduct={userProduct} 
              productData={products}
              linkTo={`/design/${userProduct.code}`} 
              showDesigner />)}
          {isLoadingPage && <LoadingSpinner mini />}
          <Waypoint onEnter={this.onScrollEnd} />
        </div>
      </div>
      <Footer />
    </div>);
  }
};

function mapStateToProps (state, props) {
  const { 
    match: { 
      params: { 
        filterPairs 
      }  
    } 
  } = props;
  return {
    products: state.products.list.entities,
    filters: toFilterObject(filterPairs)
  };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopsRoute);
