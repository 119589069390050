import React from 'react';

const TemplateOverlay = ({ template, canvasWidth }) => {
  if (!template) {
    return null;
  }
  const OverlaySVG = template.svgComponent;
  const style = {
    width: canvasWidth
  };
  return (<div className="template-overlay" style={style}>
    <OverlaySVG />
  </div>);
};

export default TemplateOverlay;