import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import Moose from '../../clients/moose';

import ErrorBox from '../meta/error-box';
import LoadingSpinner from '../meta/loading-spinner';
import UploadImage from './upload-image';
import _ from 'lodash';

const UploadInput = createReactClass({
  propTypes: {
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func,
    minWidthHeight: PropTypes.number,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    className: PropTypes.string,
    uploadIcon: PropTypes.string,
    useCssPreviewImage: PropTypes.bool,
    buttonText: PropTypes.string,
    uploadType: PropTypes.string,
    rejectHeic: PropTypes.bool,
    render: PropTypes.func
  },

  getInitialState () {
    return {
      photo: null,
      saving: false,
      errors: []
    };
  },

  componentDidUpdate(prevProps, prevState) {
    const { onError } = this.props;
    if (onError && _.isFunction(onError)) {
      if (!_.isEqual(this.state.errors, prevState.errors)) {
        onError(this.state.errors);
      }
    }
  },

  handleInputChange () {
    const { minWidthHeight, minWidth, minHeight, rejectHeic } = this.props;
    const files = this.refs.fileInput.files;

    if (files.length > 0) {
      this.setState({
        saving: true,
        errors: []
      });

      const fileToUse = files[0];

      if (fileToUse.type === 'application/pdf') {
        this.setState({
          saving: false,
          errors: ['We\'re unable to use scanned images. Please upload a .jpg or a .png using a smartphone to take the photo.']
        });

        return;
      }

      if (fileToUse.name && fileToUse.name.match(/\.heic$/i)) {
        // Browsers don't support heic at time of this code, but we want to accept them
        // Can't validate anything about them here, but let's cross our fingers and let
        // it through.
        if (rejectHeic) {
          this.setState({
            saving: false,
            errors: ['We\'re unable to use HEIC images because they don\'t display in browsers. Please upload a .jpg or a .png.']
          });
  
          return;
        } else {
          return this.uploadPhoto(fileToUse, { mimeType: 'image/heic' });
        }
      }

      /*
       * Check if the image is below the required dimensions
       */

      try {
        const photo = new Image();
        photo.onload = () => {
          this.setState({ photo });
          if (minWidthHeight && (photo.width < minWidthHeight || photo.height < minWidthHeight)) {
            this.setState({
              saving: false,
              errors: [`The image you're uploading is too small. Please upload a photo that is at least ${minWidthHeight}px wide and tall.`]
            });
          } else if (minWidth && photo.width < minWidth) {
            this.setState({
              saving: false,
              errors: [`The image you're uploading is too small. Please upload a photo that is at least ${minWidth}px wide.`]
            });
          } else if (minHeight && photo.height < minHeight) {
            this.setState({
              saving: false,
              errors: [`The image you're uploading is too small. Please upload a photo that is at least ${minHeight}px tall.`]
            });
          } else {
            this.uploadPhoto(fileToUse);
          }
        };
        photo.onerror = (e) => {
          this.setState({
            saving: false,
            errors: ['Could not recognize the file you are uploading as a photo.']
          });
        };
        photo.src = window.URL.createObjectURL(fileToUse);
      } catch (err) {
        this.setState({ photo: null });
        this.uploadPhoto(fileToUse);
      }
    }
  },

  uploadPhoto (fileToUse, options={}) {
    const { photo } = this.state;
    const mimeType = options.mimeType || fileToUse.type;
    Moose.createUploadSignature({ 
      mime: mimeType,
      uploadType: this.props.uploadType 
    }).then((data) => {
      return axios.put(data.signature, fileToUse, {
        headers: {
          'Content-Type': mimeType
        }
      }).then(() => {
        return Moose.createUpload({ url: data.url, mime: mimeType });
      });
    }).then((data) => {
      this.props.onChange({ 
        ...data, 
        originalFilename: fileToUse.name, 
        width: photo && photo.width,
        height: photo && photo.height 
      });
    }).catch((error) => {
      this.setState({
        errors: [error.message]
      });
    }).then(() => {
      this.setState({
        saving: false
      });
    });
  },

  handleRemoveClick () {
    this.setState({
      saving: false,
      errors: []
    });

    this.props.onChange(null);
  },

  renderLoading () {
    if (this.state.saving) {
      return (
        <div className="upload-input__loading">
          <LoadingSpinner />
        </div>
      );
    }

    return null;
  },

  renderUpload () {
    const image = this.props.value;
    return (
      <div className={this.props.className}>
        <UploadImage image={image} useCssPreviewImage={this.props.useCssPreviewImage}>
          <button className="button upload-input__remove" type="button" onClick={this.handleRemoveClick}><i className="icon icon-delete" /> <span>Remove Picture</span></button>
        </UploadImage>
      </div>
    );
  },

  render () {
    if (this.props.render) {
      return (<label className="custom-upload-input">
        {this.props.render(this.props, this.state)}
        {!this.state.saving && <input type="file" name="fileInput" ref="fileInput" onChange={this.handleInputChange} />}
      </label>);
    }
    if (this.props.value) {
      return this.renderUpload();
    }
    const classNameProp = this.props.className 
      ? { className: this.props.className } 
      : {};
    const uploadIconClass = this.props.uploadIcon 
      ? `upload-input__icon ${this.props.uploadIcon}` 
      : 'upload-input__icon';
    const buttonText = this.props.buttonText || 'Upload Your Picture';

    return (
      <div {...classNameProp}>
        <ErrorBox errors={this.state.errors} />

        <div className="upload-input">
          <label>
            <div className={uploadIconClass} title={buttonText} />
            <div className="button primary">{buttonText}</div>

            <input type="file" name="fileInput" ref="fileInput" onChange={this.handleInputChange} />
          </label>

          {this.renderLoading()}
        </div>
      </div>
    );
  }
});

export default UploadInput;
