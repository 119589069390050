import _ from 'lodash';
import { combineReducers } from 'redux';

import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  SIGN_IN_USER_REQUEST,
  SIGN_IN_USER_SUCCESS,
  SIGN_IN_USER_FAILURE,
  SET_LOGIN_NOTE,
  SET_LOGIN_REDIRECT,
  SIGN_OUT_USER
} from '../actions';

const currentUserInitialState = {
  entity: null,
  isFetching: false,
  error: null
};

const authenticationInitialState = {
  isFetching: false,
  error: null,
  info: null,
  loginRedirect: null,
  forcePasswordReset: false,
  migratedPasswordReset: false
};

const token = function (state = null, action) {
  switch (action.type) {
    case CREATE_USER_SUCCESS:
      return _.get(action.payload, 'token.token') || null;

    case SIGN_IN_USER_SUCCESS:
      return _.get(action.payload, 'data.token') || null;

    case SIGN_OUT_USER:
      return null;

    default:
      return state;
  }
};

const currentUser = function (state = currentUserInitialState, action) {
  switch (action.type) {

    case SIGN_OUT_USER:
      return currentUserInitialState;
    
    case FETCH_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };

    case FETCH_USER_SUCCESS:
      return {
        ...state,
        entity: action.payload,
        isFetching: false,
        error: null
      };

    case FETCH_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};

const authentication = function (state = authenticationInitialState, action) {
  switch (action.type) {

    case SET_LOGIN_NOTE:
      return {
        ...state,
        info: action.payload
      };

    case SET_LOGIN_REDIRECT:
      return {
        ...state,
        loginRedirect: action.payload
      };

    case CREATE_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        info: null
      };

    case CREATE_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        info: null
      };

    case CREATE_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        info: null
      };

    case SIGN_IN_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        info: null
      };

    case SIGN_IN_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        info: null
      };

    case SIGN_IN_USER_FAILURE:
      const errorCode = _.get(action, 'payload.errorCode');
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        migratedPasswordReset: errorCode === 'MIGRATED_PASSWORD_RESET',
        forcePasswordReset: errorCode === 'FORCE_PASSWORD_RESET',
        info: null
      };

    default:
      return state;
  }
};

export default combineReducers({
  authentication,
  currentUser,
  token
});

export function getAuthentication (state) {
  return state.authentication;
}

export function getCurrentUser (state) {
  return state.currentUser;
}

export function getCurrentUserAffiliateCode (state) {
  return _.get(state, 'currentUser.entity.affiliate.activeCode.code');
}

export function getToken (state) {
  return state.token;
}
