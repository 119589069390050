import _ from 'lodash';
import axios from 'axios';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/vnd.picturethis+json; version=1',
    'X-Subsite': 'pixelthis'
  },
  timeout: 20000
});

client.interceptors.request.use(function (config) {
  if (client._mooseToken) {
    config.headers['Authorization'] = `token ${client._mooseToken}`;
  }

  return config;
});

client.interceptors.response.use(function (response) {
  if (_.get(response, 'data.token') || _.get(response, 'data.meta')) {
    return response.data;
  }

  return response.data.data;
}, function (error) {
  if (axios.isCancel(error)) {
    throw error;
  }

  const message = _.get(error, 'response.data.error.message', 'An unknown error has occurred. Please try again.');
  const errorCode =  _.get(error, 'response.data.error.code');
  const newError = new Error(message);
  newError.axiosError = error;
  if (errorCode) {
    newError.errorCode = errorCode;
  }

  throw newError;
});

/*
 * Public API
 */

client.setAuthenticationToken = function (token) {
  client._mooseToken = token;
};

client.clearAuthenticationToken = function () {
  client._mooseToken = null;
};

/*
 * API Requests
 */

client.listProducts = function () {
  return client.get('/products');
};

client.fetchPricing = function ({ country = 'US', zip, state, giftCertificates, lineItems, magicCodes, unappliedStoreCredit, affiliate, source, cancelToken }) {
  const body = {
    country,
    zip,
    state,
    giftCertificates,
    lineItems,
    magicCodes,
    unappliedStoreCredit,
    affiliate,
    source
  };

  return client.post('/pricing', body, {
    cancelToken
  });
};

client.createOrder = function ({ giftCertificates, lineItems, magicCodes, unappliedStoreCredit, billingAddress, billing, shippingAddress, source, stripeToken, affiliate }) {
  const body = {
    giftCertificates,
    lineItems,
    magicCodes,
    unappliedStoreCredit,
    billingAddress,
    billing,
    shippingAddress,
    source,
    stripeToken: (stripeToken && stripeToken.id),
    affiliate
  };

  return client.post('/orders', body);
};

client.fetchMagicCode = function (magicCode) {
  return client.get(`/magic-codes/${magicCode}`);
};

client.registerAffiliateClick = function (code) {
  return client.post(`/affiliates/click`, {
    code,
    referrer: document.referrer
  });
};

client.createUploadSignature = function ({ mime, uploadType }) {
  const body = {
    mime,
    uploadType
  };

  return client.post('/uploads/signatures', body);
};

client.createUpload = function ({ url, mime, pixelDesignId }) {
  const body = {
    url,
    mime,
    pixelDesignId
  };

  return client.post('/uploads', body);
};

client.subscribeEmail = function(email) {
  return client.post('/mailing-list', { email });
};

client.createUser = function ({ email, name, password }) {
  const body = {
    email,
    name,
    password,
    source: 'tuxedo'
  };

  return client.post('/users', body);
};

client.updateUser = function ({ email, name }) {
  const body = {
    email,
    name,
    source: 'tuxedo'
  };
  return client.put(`users/me`, body);
};

client.changePassword = function ({ currentPassword, newPassword }) {
  const body = {
    currentPassword,
    newPassword,
    source: 'tuxedo'
  };
  return client.put(`users/me`, body);
};

client.updateSavedShippingAddress = function(savedShippingAddress) {
  const body = {
    savedShippingAddress,
    source: 'tuxedo'
  };
  return client.put(`users/me`, body);
};

client.deleteSavedShippingAddress = function() {
  const body = {
    savedShippingAddressId: null,
    source: 'tuxedo'
  };
  return client.put(`users/me`, body);
};

client.signIn = function ({ email, password }) {
  const body = {
    email,
    password
  };

  return client.put('/users/tokens', body);
};

client.fetchCurrentUser = function () {
  return client.get('/users/me');
};

client.fetchAffiliateInfo = function () {
  return client.get('/affiliates/me');
};

client.acceptAffiliateTOS = function () {
  return client.post('/affiliate-tos');
};

client.updateAffiliateSettings = function ({ paymentPreference, paypalAddress, notifySales, notifyPayments }) {
  return client.post('/affiliate-settings', { 
    paymentPreference,
    paypalAddress,
    notifySales,
    notifyPayments
  });
};

client.fetchMyOrders = function () {
  return client.get('/my-orders');
};

client.fetchPaymentMethods = function () {
  return client.get('/payment-methods');
};

client.removePaymentMethod = function (id) {
  return client.delete(`/payment-methods/${id}`);
};

client.requestPasswordReset = function (email, from) {
  return client.put('/users/request-password-reset', { email, from });
};

client.validatePasswordToken = function (token) {
  return client.put('/users/validate-password-token', { token });
};

client.resetPassword = function (token, password) {
  return client.put('/users/reset-password', { token, password });
};

client.fetchContest = function () {
  return client.get('/contest');
};

client.submitContestEntry = function ({ uploadId, designersName }) {
  return client.post('/contest/entries', { uploadId, designersName });
};

client.fetchMyProducts = function () {
  return client.get('/my-products');
};

client.fetchPixelDesigns = function () {
  return client.get('/pixel-designs');
};

client.fetchPixelDesign = function (id) {
  return client.get(`/pixel-designs/${id}`);
};

client.savePixelDesign = function ({ data, productId, variants, designersName }) {
  return client.post('/pixel-designs', { data, productId, variants, designersName });
};

client.updatePixelDesign = function (id, { data, productId, variants, designersName }) {
  return client.put(`/pixel-designs/${id}`, { data, productId, variants, designersName });
};

client.deletePixelDesign = function (id) {
  return client.delete(`/pixel-designs/${id}`);
};

client.addPixelDesignImage = function (pixelDesignId, uploadId) {
  const body = { uploadId };
  return client.post(`/pixel-designs/${pixelDesignId}/images`, body);
};

client.deletePixelDesignImage = function (pixelDesignId, imageId) {
  return client.delete(`/pixel-designs/${pixelDesignId}/images/${imageId}`);
};

client.reorderPixelDesignImages = function (pixelDesignId, imageOrder) {
  const body = { imageOrder };
  return client.post(`/pixel-designs/${pixelDesignId}/image-order`, body);
};

client.updatePixelDesignProduct = function (id, { name, description, status }) {
  const body = { name, description, status };
  return client.post(`/pixel-designs/${id}/product`, body);
};

client.fetchMyProduct = function (id) {
  return client.get(`/my-products/${id}`);
};

client.fetchUserDesign = function (code) {
  return client.get(`/design/${code}`);
};

client.exploreDesigns = function (filters={}) {
  return client.get(`/design-explorer`, { params: filters });
};

client.fetchUserStore = function (code) {
  return client.get(`/shop/${code}`);
};

client.fetchMyShop = function () {
  return client.get('/my-shop');
};

client.updateMyProduct = function (id, { name, description, status }) {
  const body = {
    name,
    description,
    status
  };
  return client.post(`/my-products/${id}`, body);
};

client.addMyProductImage = function (orderLineItemId, uploadId) {
  const body = {
    uploadId
  };
  return client.post(`/my-products/${orderLineItemId}/images`, body);
};

client.deleteMyProductImage = function (orderLineItemId, imageId) {
  return client.delete(`/my-products/${orderLineItemId}/images/${imageId}`);
};

client.reorderMyProductImages = function (orderLineItemId, imageOrder) {
  const body = {
    imageOrder
  };
  return client.post(`/my-products/${orderLineItemId}/image-order`, body);
};

client.updateMyShop = function ({ name, description, code, headerImage, profileImage, accentColor, termsAccepted }) {
  const body = {
    name,
    description,
    code,
    accentColor,
    profileImageId: profileImage && profileImage.id,
    headerImageId: headerImage && headerImage.id,
    termsAccepted
  };
  return client.post('/my-shop', body);
};

client.debugLog = function (message) {
  if (message instanceof Error) {
    const errorObj = {};
    Object.getOwnPropertyNames(message).forEach(function(key) {
      errorObj[key] = message[key];
    });
    return client.post('/debugLog', { data: errorObj });
  } else {
    return client.post('/debugLog', { data: message });
  }
};

export const uploadBlob = async (blob, options={}) => {
  const mimeType = options.mimeType || 'image/png'; 
  const extraParams = options.pixelDesignId 
    ? { pixelDesignId: options.pixelDesignId }
    : {};
  const signatureResponse = await client.post('/uploads/signatures', {
    mime: mimeType,
    uploadType: options.uploadType || 'pixelThis',
    ...extraParams,
  });
  if (signatureResponse && signatureResponse.url) {
    await axios.put(signatureResponse.signature, blob, {
      headers: {
        'Content-Type': mimeType
      }
    });
    const upload = await client.createUpload({ 
      url: signatureResponse.url, 
      mime: mimeType,
      ...extraParams
    });
    return upload;
  }
};

export default client;
