import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { reduxForm, Field } from 'redux-form';
import CheckoutBox from '../checkout/checkout-box';
import CheckoutField from '../checkout/checkout-field';
import {
  combineValidators,
  composeValidators,
  isRequired,
  hasLengthGreaterThan,
  matchesField
} from 'revalidate';

const MyAccountPassword = createReactClass({

  propTypes: {
    onSubmit: PropTypes.func.isRequired
  },
  
  render() {
    const { handleSubmit } = this.props;
    return (
      <CheckoutBox title="Password Change">
        <form className="my-account-password-form" onSubmit={handleSubmit(this.props.onSubmit)}>

          <Field name="currentPassword" component={CheckoutField} type="password" className="checkout__input my-account__current-password" placeholder="Current Password" />

          <div className="my-account__new-password-fields">
            <Field name="newPassword" component={CheckoutField} type="password" className="checkout__input my-account__new-password" placeholder="New Password" />

            <Field name="newPasswordConfirmation" component={CheckoutField} type="password" className="checkout__input my-account__confirm-password" placeholder="Confirm New Password" />
          </div>

          <button className="button primary text--light" type="submit">Save</button>
        </form>
      </CheckoutBox>
    );
  }

});

const validate = combineValidators({
  'currentPassword': isRequired('Current password'),
  'newPassword': composeValidators(
    isRequired,
    hasLengthGreaterThan(7)
  )('New password'),
  'newPasswordConfirmation': matchesField('newPassword')({
    message: 'New password confirmation does not match'
  })
});

export default reduxForm({
  form: 'my-account-password',
  validate,
  shouldValidate: () => true
})(MyAccountPassword);