import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Select from 'react-select';
import { shortNameForVariant } from '../../utils/products';

const VariantDropdown = createReactClass({
  propTypes: {
    layout: PropTypes.oneOf(['normal', 'inline']).isRequired,
    variant: PropTypes.object.isRequired,
    value: PropTypes.object,
    parentValue: PropTypes.object,
    onChange: PropTypes.func.isRequired
  },

  getDefaultProps () {
    return {
      layout: 'normal'
    };
  },

  handleChange (value) {
    this.props.onChange({
      variantId: this.props.variant.id,
      variantOptionId: value.variantOptionId
    });
  },

  renderArrow () {
    return <i className="icon icon-arrow-a" />;
  },

  renderValue ({ label }) {
    const { layout, variant } = this.props;
    return <span>{layout === 'inline' ? shortNameForVariant(variant) : variant.name}: {label}</span>;
  },

  render () {
    const { layout, variant } = this.props;
    const filteredVariantOptions = _.filter(variant.options, ({ parentOptionId }) => {
      if (this.props.parentValue) {
        return this.props.parentValue.variantOptionId === parentOptionId;
      }

      return true;
    });

    const options = _.map(filteredVariantOptions, ({ id, value, displayValue }) => {
      return {
        label: displayValue,
        value,
        variantOptionId: id
      };
    });

    const currentValue = _.find(options, (option) => {
      return option.variantOptionId === _.get(this.props.value, 'variantOptionId');
    });

    const placeholder = layout === 'inline' ? variant.name : `Please select your ${_.lowerCase(variant.name)}`;

    return (
      <div className="variant-dropdown">
        <Select
          arrowRenderer={this.renderArrow}
          className="variant-selector__select text--light"
          clearable={false}
          name={`variant-${variant.id}`}
          options={options}
          placeholder={placeholder}
          searchable={false}
          value={currentValue}
          valueRenderer={this.renderValue}
          onChange={this.handleChange} />
      </div>
    );
  }
});

export default VariantDropdown;
