import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setColor } from '../../actions/editor';
import { PainterContext } from '../../contexts';
import { PALETTE_COLORS, COLOR_BANK_SIZE } from '../../config';

const COLOR_DATA = PALETTE_COLORS.map((color,index) => ({
  color,
  index,
  bank: Math.floor(index/COLOR_BANK_SIZE)
}));

const ColorPalette = () => {
  const dispatch = useDispatch();
  const currentBank = useSelector(state => state.editor.colorBank);
  const activeColor = useSelector(state => state.editor.color);
  const painter = useContext(PainterContext);
  useEffect(() => {
    if (painter.current) {
      painter.current.colour = activeColor;
      painter.current.draw();
    }
  }, [ activeColor ]);
  const currentColors = COLOR_DATA.filter(color => color.bank === currentBank);
  const midpoint = Math.ceil(currentColors.length / 2) - 1;
  return (<div className="color-palette">
    {currentColors.map((entry,i) => {
      const style = {
        backgroundColor: entry.color
      };
      return (<React.Fragment key={entry.index}>
        <div className={activeColor === entry.index ? 'color-entry current' : 'color-entry'} 
          style={style}
          onClick={() => dispatch(setColor(entry.index))}>
            {activeColor === entry.index && (<i />)}
        </div>
        {i === midpoint && <div className="color-break" />}
      </React.Fragment>);
    })}
  </div>);
};

export default ColorPalette;