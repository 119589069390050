import { DEFAULT_PIXEL_WIDTH, EXPORT_WIDTH } from '../../config';

export const getHeightWithTemplate = (template, sourceWidth) => {
  if (!template) {
    return sourceWidth;
  }
  const { height, width } = template;
  return Math.ceil(sourceWidth/width*height);
}

export const calcCellWidth = (windowSize, pixelWidth) => {
  const pixelDivider = pixelWidth / DEFAULT_PIXEL_WIDTH;
  const defaultCellWidth = Math.floor(16 / pixelDivider);
  if (windowSize && windowSize.width < defaultCellWidth * pixelWidth) {
    return Math.max(Math.floor(windowSize.width / pixelWidth), 1);
  } else {
    return defaultCellWidth;
  }
};

export const getPreviewHeight = (template, previewWidth) => getHeightWithTemplate(template, previewWidth);
export const getPixelHeight = (template, pixelWidth) => getHeightWithTemplate(template, pixelWidth);
export const getExportHeight = template => getHeightWithTemplate(template, EXPORT_WIDTH);