import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { fetchUserIfNeeded } from '../../actions/user';
import Moose from '../../clients/moose';

import MyAccountPayments from '../../components/my-account/my-account-payments';
import MyAccountCredits from '../../components/my-account/my-account-credits';
import Header from '../../components/structure/header';
import MyAccountHeader from '../../components/my-account/my-account-header';
import BackgroundOverlay from '../../components/structure/background-overlay';

const PaymentsRoute = createReactClass({

  propTypes: {
    user: PropTypes.object.isRequired,
    fetchUserIfNeeded: PropTypes.func.isRequired
  },

  getInitialState() {
    return {
      isFetchingPayments: false,
      sources: []
    }
  },

  componentWillMount() {
    this.fetchPayments();    
  },

  fetchPayments() {
    this.setState({ isFetchingPayments: true });
    Moose.fetchPaymentMethods().then((payment) => {
      this.setState({ sources: payment.sources, isFetchingPayments: false });
    });
  },

  handleRemovePayment(sourceId) {
    Moose.removePaymentMethod( sourceId ).then(res => {
      this.fetchPayments(); 
    });
  },
  
  render() {
    const { user } = this.props;
    return (
      <>
        <Header />
        <BackgroundOverlay />
        <div className="my-account clearfix">
          <MyAccountHeader user={user} />
          <div className="my-account__content">
            <div className="my-account__payments clearfix">
              <MyAccountPayments onRemove={this.handleRemovePayment} 
                sources={this.state.sources}
                isFetching={this.state.isFetchingPayments} />
              <MyAccountCredits user={user} />
            </div>
          </div>
        </div>
      </>
    );
  }

});

function mapStateToProps (state) {
  return {
  };
}

const mapDispatchToProps = {
  fetchUserIfNeeded
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsRoute);
