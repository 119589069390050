import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setIsLoading } from '../../actions/editor';
import { addFlowFromEditor } from '../../actions/flow';
import { allVariantsSelected } from '../../utils/editor/products';
import { PainterContext } from '../../contexts';
import { uploadBlob } from '../../clients/moose';
import { createBlob } from '../../utils/editor';
import { noop } from 'lodash';
import Button from './button';
import { ReactComponent as CartSVG } from '../../assets/icons/cart.svg';
import { isMobileApp, sendAppMessage } from '../../utils/mobile-app'

const AddToCart = ({ template, text, setShowTemplateWarning=noop }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const sku = useSelector(state => state.editor.templateOptions.sku);
  const variants = useSelector(state => state.editor.templateOptions.variants);
  const designersName = useSelector(state => state.editor.templateOptions.designersName);
  const products = useSelector(state => state.products.list.entities);
  const isLoading = useSelector(state => state.editor.isLoading);
  const painter = useContext(PainterContext);

  const addToCart = async () => {
    dispatch(setIsLoading(true));
    const canvasState = painter.current.freezeState();
    const blob = await createBlob(canvasState, template);
    const upload = await uploadBlob(blob);
    dispatch(addFlowFromEditor({ canvasState, upload }));
    dispatch(setIsLoading(false));
    if (isMobileApp()) {
      sendAppMessage('reviewCartAddon');
    } else {
      history.push('/product/review');
    }
  };

  const canAddToCart = () => {
    const product = products.find(product => product.sku === sku);
    return !isLoading && sku && allVariantsSelected(product, variants) && template && designersName;
  };

  useEffect(() => {
    if (isMobileApp()) {
      setShowTemplateWarning(!template);
    }
  }, [ template ]);

  return (<Button wide onClick={addToCart} 
      onMouseOver={() => setShowTemplateWarning(!canAddToCart())}
      onMouseOut={() => setShowTemplateWarning(false)}
      disabled={!canAddToCart()} 
      title="Add to Cart" 
      className="add-to-cart">
    {text && (<div>{text}</div>)}
    <CartSVG />
  </Button>);
};

export default AddToCart;