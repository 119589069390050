import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';

import { removeMagicCodeFromCart, removeStoreCreditFromCart, removeAffiliateCookie } from '../../actions/cart';
import { getPricing } from '../../reducers/cart';
import { formatPrice } from '../../utils/currency';
import { beforeTaxCredits, afterTaxCredits } from '../../utils/checkout';

import CheckoutBox from './checkout-box';
import EntityDetail from '../meta/entity-detail';

const CheckoutTotals = createReactClass({
  propTypes: {
    entity: PropTypes.shape({
      credits: PropTypes.array.isRequired,
      shippingDescription: PropTypes.string.isRequired,
      shippingPrice: PropTypes.number.isRequired,
      subtotalPrice: PropTypes.number.isRequired,
      salesTax: PropTypes.number,
      finalPrice: PropTypes.number.isRequired
    }),
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.object,
    removeMagicCodeFromCart: PropTypes.func.isRequired,
    removeStoreCreditFromCart:  PropTypes.func.isRequired
  },

  handleRemove (magicCodeObj) {
    const magicCodeStr = magicCodeObj.magicCode;
    const userCreditId = magicCodeObj.id;
    const isAffiliateDiscount = magicCodeObj.type === 'affiliate-discount';
    if (userCreditId) {
      if (window.confirm('Are you sure you would like to remove this store credit?')) {
        this.props.removeStoreCreditFromCart(userCreditId);
      }
    } else if (isAffiliateDiscount) {
      if (window.confirm('Are you sure you would like to remove this affiliate discount? The affiliate will also not be credited.')) {
        this.props.removeAffiliateCookie();
      }
    } else {
      if (window.confirm('Are you sure you would like to remove this magic code?')) {
        this.props.removeMagicCodeFromCart(magicCodeStr);
      }
    }
  },

  renderCredit(credit, idx) {
    let label = credit.magicCode || credit.description;
    return (
      <div className="checkout-totals__line checkout-totals__line-credit" key={`c${idx}`}>
        <span className="checkout-totals__line-label">
          {label}
          <button type="button" className="button--reset checkout-totals__remove" onClick={_.partial(this.handleRemove, credit)}><i className="icon icon-delete" /></button>
        </span>
        <span className="checkout-totals__line-value">-{formatPrice(credit.amountUsed)}</span>
      </div>
    );
  },

  render () {
    const { entity, isFetching, error } = this.props;

    return (
      <CheckoutBox title="Total">
        <div className="checkout-totals">
          <EntityDetail
            entity={entity}
            isFetching={isFetching}
            error={error}
            alwaysShowFetching={true}
            render={() => {
              const { credits, finalPrice, salesTax, shippingDescription, shippingPrice, subtotalPrice, lineItems } = entity;
              const showShipping = lineItems && lineItems.length > 0;
              const showTax = salesTax > 0;

              return (
                <div>
                  <div className="checkout-totals__line">
                    <span className="checkout-totals__line-label">Subtotal</span>
                    <span className="checkout-totals__line-value">{formatPrice(subtotalPrice)}</span>
                  </div>

                  {beforeTaxCredits(credits).map(this.renderCredit)}

                  {showTax && <div className="checkout-totals__line">
                    <span className="checkout-totals__line-label">Sales Tax</span>
                    <span className="checkout-totals__line-value">{formatPrice(salesTax)}</span>
                  </div>}

                  {showShipping && <div className="checkout-totals__line">
                    <span className="checkout-totals__line-label">{shippingDescription}</span>
                    <span className="checkout-totals__line-value">{formatPrice(shippingPrice)}</span>
                  </div>}

                  {afterTaxCredits(credits).map(this.renderCredit)}

                  <div className="checkout-totals__line checkout-totals__total">
                    <span className="checkout-totals__line-label">Total</span>
                    <span className="checkout-totals__line-value">{formatPrice(finalPrice)}</span>
                  </div>
                </div>
              );
            }} />
        </div>
      </CheckoutBox>
    );
  }
});

function mapStateToProps (state) {
  const { entity, isFetching, error } = getPricing(state.cart);
  return {
    entity,
    isFetching,
    error
  };
}

const mapDispatchToProps = {
  removeMagicCodeFromCart,
  removeStoreCreditFromCart,
  removeAffiliateCookie
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutTotals);
