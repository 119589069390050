import _ from 'lodash';
import { CALL_API } from '@meadow/redux-api-middleware';
import { retrievePricingIfNeeded } from './cart';
import { trackSignup } from '../utils/tracking';
import { isMobileApp, sendAppMessage } from '../utils/mobile-app';

import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  SIGN_IN_USER_REQUEST,
  SIGN_IN_USER_SUCCESS,
  SIGN_IN_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  SET_LOGIN_NOTE,
  SET_LOGIN_REDIRECT,
  SIGN_OUT_USER
} from './';
import Moose from '../clients/moose';
import { getToken } from '../reducers/user';

export function signOut () {
  return (dispatch) => {
    if (isMobileApp()) {
      sendAppMessage('logOut');
    }
    dispatch({ type: SIGN_OUT_USER });
    Moose.clearAuthenticationToken();
    dispatch( retrievePricingIfNeeded() );
  };
}

function fetchUser () {
  return (dispatch, getState) => {
    Moose.setAuthenticationToken(getToken(getState().user));
    dispatch( retrievePricingIfNeeded() );

    return dispatch({
      [CALL_API]: {
        types: [
          FETCH_USER_REQUEST,
          FETCH_USER_SUCCESS,
          FETCH_USER_FAILURE
        ],
        promise: Moose.fetchCurrentUser()
      }
    }).catch(function (err) {
      dispatch(signOut());

      throw err;
    });
  };
}

export function fetchUserIfNeeded () {
  return (dispatch, getState) => {
    const token = getToken(getState().user);

    if (token) {
      return dispatch(fetchUser());
    }
  };
}

export function createUser ({ email, name, password }) {
  return (dispatch) => {
    const promise = dispatch({
      [CALL_API]: {
        types: [
          CREATE_USER_REQUEST,
          CREATE_USER_SUCCESS,
          CREATE_USER_FAILURE
        ],
        promise: Moose.createUser({ email, name, password })
      }
    });

    return promise.then((data) => {
      const token = _.get(data, 'token.token');

      if (token) {
        if (isMobileApp()) {
          sendAppMessage('loginToken', token);
        }
        try {
          trackSignup();
        } catch(e) {
          console.error(e);
        };
        return dispatch(fetchUser());
      }
    });
  };
}

export function signInUser ({ email, password }) {
  return (dispatch) => {
    const promise = dispatch({
      [CALL_API]: {
        types: [
          SIGN_IN_USER_REQUEST,
          SIGN_IN_USER_SUCCESS,
          SIGN_IN_USER_FAILURE
        ],
        promise: Moose.signIn({ email, password })
      }
    });

    return promise.then((data) => {
      const token = _.get(data, 'data.token');

      if (token) {
        if (isMobileApp()) {
          sendAppMessage('loginToken', token);
        }
        return dispatch(fetchUser());
      }
    });
  };
}

export function setLoginNote(message) {
  return (dispatch) => {
    dispatch({
      type: SET_LOGIN_NOTE,
      payload: { message }
    });
  };  
}

export function setLoginRedirect(path) {
  return (dispatch) => {
    dispatch({
      type: SET_LOGIN_REDIRECT,
      payload: path
    });
  };  
}