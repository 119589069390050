import _ from 'lodash';
import dressIcon from '../assets/products/icons/dress.svg';
import shirtIcon from '../assets/products/icons/shirt.svg';
import leggingsIcon from '../assets/products/icons/leggings.svg';
import beanieIcon from '../assets/products/icons/beanie.svg';
import maskIcon from '../assets/products/icons/face-mask.svg';
import usaIcon from '../assets/products/icons/usa.svg';
import intlIcon from '../assets/products/icons/international.svg';

export const giftProductOptions = [
  { icon: dressIcon, title:  'Dresses' },
  { id: 'D1', size: 'All Sizes', price: 4900 },
  { id: 'D2', size: 'Matching 18″ doll dress add-on', price: 1800 },
  { id: 'D3', size: 'Matching scrunchie add-on', price: 700 },
  { icon: shirtIcon, title: 'Shirts' },
  { id: 'S1', size: 'All Sizes', price: 4900 },
  { id: 'S2', size: 'Matching 18″ doll shirt add-on', price: 1800 },
  { id: 'S3', size: 'Matching scrunchie add-on', price: 700 },
  { icon: leggingsIcon, title: 'Leggings' },
  { id: 'L1', size: 'Toddler 2T to 4T', price: 4900 },
  { id: 'L2', size: 'Kids 5 to 12', price: 5900 },
  { id: 'L3', size: 'Junior 14 to 18', price: 6900 },
  { id: 'L4', size: 'Adult XS to XL', price: 7900 },
  { id: 'L5', size: 'Extras 2XL to 5XL', price: 8900 },
  { id: 'L6', size: 'Matching 18″ doll leggings add-on', price: 1800 },
  { id: 'L7', size: 'Matching scrunchie add-on', price: 700 },
  { icon: maskIcon, title: 'Face Cover' },
  { id: 'F1', size: 'All Sizes', price: 2450 },
  { id: 'F2', size: 'Matching 18″ doll face cover add-on', price: 1100 },
  { id: 'F3', size: 'Matching scrunchie add-on', price: 700 },
  { icon: beanieIcon, title: 'Beanies' },
  { id: 'B1', size: 'All Sizes', price: 1900 },
  { id: 'B2', size: 'Matching 18″ doll beanie add-on', price: 900 },
  { id: 'B3', size: 'Matching scrunchie add-on', price: 700 },
];

export const giftShippingOptions = [
  { id: 'U1', icon: usaIcon, name: 'USA', size: '1 to 3 garments', price: 500 },
  { id: 'U2', icon: usaIcon, name: 'USA', size: '3+ garments', price: 0 },
  { id: 'I1', icon: intlIcon, name: 'International', size: '1 to 3 garments', price: 1500 },
  { id: 'I2', icon: intlIcon, name: 'International', size: '3+ garments' }
];

export const giftOptionsById = [
  ...giftProductOptions,
  ...giftShippingOptions
].filter((option) => option.id)
.reduce((optionMap, option) => ({
    ...optionMap,
    [ option.id ]: _.pick(option, [ 'price' ])
  }), {});