import React from 'react';
import classNames from 'classnames';

const LoadingSpinner = ({ mini, centered }) => {

  const className = classNames({
    'mini-spinner': mini,
    'centered-spinner': centered,
    'loading-spinner__content': true
  });

  return (<div className={className}>
    <div className="loading-spinner" />
  </div>);
};

export default LoadingSpinner;
