import { useState, useEffect } from 'react';
import useLatest from 'use-latest';

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.outerWidth,
        height: window.outerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

export function useUnload(handler) {
  const handlerRef = useLatest(handler);
  const handleUnload = event => {
    if (handlerRef.current != null) {
      return handlerRef.current(event);
    }
  };
  useEffect(() => {
    const eventName = 'unload';
    window.addEventListener(eventName, handleUnload);
    return () => {
      window.removeEventListener(eventName, handleUnload);
    };
  }, []);
};