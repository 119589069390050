import { formatPriceShort } from '../../utils/currency';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const PresetPriceSelector = ({ 
  priceOptions=[],
  presetAmount='',
  typedAmount='',
  invalidPriceError,
  onChangePrice,
  onClickPrice,
  children
}) => {
  const priceButtons = priceOptions.map((buttonAmount,i) => {
    /* eslint-disable eqeqeq */
    const buttonClass = buttonAmount == presetAmount 
      ? 'preset-price-selector__selected' 
      : '';
    /* eslint-enable eqeqeq */
    return (<button className={buttonClass} 
      key={`priceOption${i}`} onClick={() => onClickPrice(buttonAmount)}>
      {formatPriceShort(buttonAmount)}
    </button>);
  });
  const inputClass = classNames({
    input: true,
    'preset-price-selector__selected': typedAmount && !invalidPriceError,
    'preset-price-selector__amount-typed': typedAmount,
    'preset-price-selector__error': typedAmount && invalidPriceError
  });
  return (
    <div className="preset-price-selector">
      {priceButtons}
      {children}
      <input type="text" className={inputClass} placeholder="Enter Amount" 
        value={typedAmount} onChange={(e) => onChangePrice(e.target.value)} />
    </div>
  );
};

PresetPriceSelector.propTypes = {
  product: PropTypes.array
};

export default PresetPriceSelector;
