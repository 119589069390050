import React, { useState } from 'react';
import { useWaitForImage } from '../../utils/hooks';
import Header from '../structure/header';
import MyAccountHeader from './my-account-header';
import BackgroundOverlay from '../structure/background-overlay';
import ProfileImage from '../user-store/profile-image';
import UploadInput from '../products/upload-input';
import ColorPicker from './color-picker';
import AccentStyles from '../user-store/accent-styles';
import ErrorBox from '../meta/error-box';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import defaultHeader from '../../assets/userstores/backgrounds/hero.jpg';

const MyStoreWrapper = ({ user, store, children, minimal, onChange }) => {
  const accentColor = _.get(store, 'accentColor') || 'ff6f00';
  const backgroundImage = _.get(store, 'headerImage.thumbnails.header') || defaultHeader;
  const profileImage = _.get(store, 'profileImage.thumbnails.profile') || store.gravatar;
  const isLoadingBackground = useWaitForImage(backgroundImage);
  const loaderImage = (<i className="fa fa-spin fa-circle-o-notch" />);
  const storeExists = store && store.id;
  const [ backgroundImageErrors, setBackgroundImageErrors ] = useState([]);
  const [ profileImageErrors, setProfileImageErrors ] = useState([]);


  return (<>
    <Header styleColor={`#${accentColor}`} />
    <BackgroundOverlay backgroundImage={backgroundImage} isLoading={isLoadingBackground} />
    <div className="my-account user-store-wrapper clearfix">
      <AccentStyles accentColor={accentColor} />
      <MyAccountHeader user={user}>
        {minimal 
          ? (<><div></div><div></div></>)
          : (<>
              <div>
                {storeExists && <Link className="button slim white view-store-stats" to="/account/my-stats">View Shop Stats</Link>}
              </div>
              <div>
                <div className="user-store-header-upload">
                  {storeExists && <UploadInput 
                      minWidth={1280}
                      minHeight={384}
                      rejectHeic={true}
                      uploadType="storeHeader"
                      render={(props,{ saving }) => {
                        return saving || isLoadingBackground 
                          ? loaderImage
                          : (<div className="circle-icon-button"><i className="fa fa-camera" /></div>);
                      }}
                      onChange={(headerImage) => onChange({ headerImage })}
                      onError={(errors) => setBackgroundImageErrors(errors)}
                    />}
                </div>                
                <ProfileImage image={profileImage}>
                  {storeExists && <UploadInput 
                    minWidthHeight={400}
                    rejectHeic={true}
                    uploadType="storeProfile"
                    render={(props, { saving }) => {
                      return saving
                        ? loaderImage
                        : (<div className="circle-icon-button"><i className="fa fa-camera" /></div>);
                    }}
                    onChange={(profileImage) => onChange({ profileImage })}
                    onError={(errors) => setProfileImageErrors(errors)}
                  />}
                </ProfileImage>
                {storeExists && <ColorPicker
                  value={store.accentColor}
                  onChange={(accentColor) => onChange({ accentColor })}
                />}
              </div>
        </>)}
      </MyAccountHeader>
      <ErrorBox title="Profile Image" errors={profileImageErrors} onClick={() => setProfileImageErrors([])} />
      <ErrorBox title="Background Image" errors={backgroundImageErrors} onClick={() => setBackgroundImageErrors([])} />
      {children}
    </div>
  </>);
};

export default MyStoreWrapper;

