import React, { useState } from 'react';
import classNames from 'classnames';

const DescriptionSection = ({ descriptionSection: { id, name, html } }) => {
  const [isOpen, setIsOpen] = useState(false);
  const className = classNames('product-description-section', { open: isOpen });
  const toggleClass = isOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down';
  const htmlContent = { __html: html };
  return (
    <div className={className}>
      <button onClick={() => setIsOpen(!isOpen)}>{name} <i className={toggleClass} /></button>
      <div className="product-description-section__content" dangerouslySetInnerHTML={htmlContent} />
    </div>
  );
};

export default DescriptionSection;