import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import DualPrice from '../products/dual-price';

const CartGiftCertificateRow = ({ giftCertificate, onRemoveClick }) => {
  const { amount, to, from, message } = giftCertificate;

  const className = classNames({
    clearfix: true,
    'cart-line-item-row': true,
    'cart-gift-certificate-row': true,
    'review-line-item': true,
    'review-line-item--editable': true
  });

  return (
    <div>
      <div className={className}>
        <div className="review-line-item__thumbnail-container">
          <div className="review-line-item__thumbnail" />
        </div>

        <div className="review-line-item__content">
          <div className="review-line-item__title">
            Gift Certificate
          </div>

          <div className="review-line-item__quantity" />
          <div className="review-line-item__price text--bold"><DualPrice subtotalPrice={amount} finalPrice={amount} /></div>
          <div className="review-line-item__delete"><i className="icon icon-delete" onClick={onRemoveClick} /></div>
        </div>
      </div>

      <div className="cart-gift-certificate-row__details">
        {to && <p><strong>To: </strong><span className="text--light">{to}</span></p>}
        {from && <p><strong>From: </strong><span className="text--light">{from}</span></p>}
        {message && <p><strong>Message: </strong><span className="text--light">{message}</span></p>}
      </div>
    </div>
  );
};

CartGiftCertificateRow.propTypes = {
  giftCertificate: PropTypes.object.isRequired,
  onRemoveClick: PropTypes.func.isRequired
};

export default CartGiftCertificateRow;
