import React from 'react';
import createReactClass from 'create-react-class';
import { Link } from 'react-router-dom';
import { isValidEmailTest } from '../../utils/validators';
import Moose from '../../clients/moose'

const Footer = createReactClass({

  getInitialState() {
    return {
      email: '',
      badEmail: false,
      subscribed: false,
      submitting: false,
      isError: false
    };
  },

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  },

  handleSubmit (e) {
    e.preventDefault();

    const { email } = this.state;
    if ( email && isValidEmailTest( email ) ) {
      this.setState({ submitting: true, badEmail: false, subscribed: false, isError: false });
      Moose.subscribeEmail(email).then(response => {
        this.setState({ submitting: false, subscribed: true, email: '' });
      }).catch(e => {
        this.setState({ submitting: false, isError: true });
      });
    } else {
      this.setState({ submitting: false, badEmail: true });
    }
  },

  render () {
    const { email, badEmail, subscribed, submitting, isError } = this.state;
    return (
      <div className="footer">
        <div className="footer__links footer__section">
          <h6 className="header">Useful Links</h6>

          <ul>
            <li><a href="http://picturethisclothing.com/faq/">FAQ</a></li>
            <li><a href="http://picturethisclothing.com/about/">About</a></li>
            <li><Link to="/account">My Account</Link></li>
            <li><a href="http://picturethisclothing.com/terms-and-conditions/">Terms &amp; Conditions</a></li>
          </ul>
        </div>

        <div className="footer__newsletter footer__section">
          <h6 className="header">Newsletter</h6>

          <form onSubmit={this.handleSubmit}>
            
            <input type="text" placeholder="you@party.com" value={email} onChange={this.handleEmailChange} />

            {submitting ? <button type="submit" className="button dark" disabled><i className="fa fa-spin fa-spinner" /></button> : <button type="submit" className="button dark">Subscribe</button> }

          </form>

          {isError ? <div className="footer__newsletter-notice"><i className="fa fa-exclamation-triangle" /> Sorry, there was a problem subscribing you.</div> : ''}

          {badEmail ? <div className="footer__newsletter-notice"><i className="fa fa-exclamation-triangle" /> Please provide a valid email address.</div> : ''}
          
          {subscribed ? <div className="footer__newsletter-notice"><i className="fa fa-check" /> Success! Thank you for subscribing to our newsletter.</div> : ''}
        </div>

        <div className="footer__social footer__section">
          <h6 className="header">Stay in Touch!</h6>

          <div>
            <a className="footer__social-link" href="https://www.instagram.com/picturethisclothing/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" /></a>
            <a className="footer__social-link" href="https://www.facebook.com/picturethisclothing/" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook" /></a>
            <a className="footer__social-link" href="https://twitter.com/picturethisco" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter" /></a>
          </div>

          <p>Made with fun by Picture This Clothing,<br /> Codingscape, and Meno Design.</p>
        </div>
      </div>
    );
  }
});

export default Footer;
