import React from 'react';
import buyimg from '../../assets/userstores/howitworks/buy.svg';
import moneyimg from '../../assets/userstores/howitworks/money.svg';
import shareimg from '../../assets/userstores/howitworks/share.svg';

const MyStoreFooter = () => {

  return (<div className="my-store-footer">
    <h2 className="header">How it works</h2>
    <div className="my-store-footer__steps">
      <div>
        <img src={shareimg} alt="" />
        <h3>Share your store</h3>
        <p>Your store has its own link, share so people can start buying your amazing designs!</p>
      </div>

      <div>
        <img src={buyimg} alt="" />
        <h3>People buy your design</h3>
        <p>Items you’ve created with us can be added to your very own store, anytime!</p>
      </div>

      <div>
        <img src={moneyimg} alt="" />
        <h3>You get money!</h3>
        <p>Earn a 10% commission from every sale your store makes!</p>
      </div>
    </div>
  </div>);

}

export default MyStoreFooter;