import { createValidator } from 'revalidate';


export const isValidEmailTest = function isValidEmailTest(value) {
  return value && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i).test(value);
}

export const isValidEmail = createValidator(
  message => value => {
    if (!isValidEmailTest(value)) {
      return message;
    }
    return null;
  },
  'Invalid email'
);

export const isValidEmailIf = isRequired => createValidator(
  message => (value, values) => {
    const emailRequired = isRequired(values);
    if ( emailRequired && !isValidEmailTest(value) ) {
      return message;
    }
    return null;
  }, 
  field => `Valid ${field} is required`
);

export const isValidCode = createValidator(
  message => value => {
    if (value && value.match(/[^0-9a-z._-]/i)) {
      return message;
    }
    return null;
  },
  'Invalid characters'
);

export const isChecked = createValidator(
  message => value => !!value ? null : message,
  field => `You must accept ${field}`
);