import React from 'react';
import cx from 'classnames';

const OnOffSwitch = ({ value, updating }) => {
  const className = cx(
    'onoffswitch',
    value ? 'onoffswitch-on' : 'onoffswitch-off',
    { 'onoffswitch-updating': false }
  );
  const switchClass = updating ? 'fa fa-circle-o-notch fa-spin' : '';
  return (<div className={className}><i className={switchClass}/></div>);
};

export default OnOffSwitch;