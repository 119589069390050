import React from 'react';
import { useWaitForImage } from '../../utils/hooks';
import BackgroundOverlay from '../structure/background-overlay';
import ProfileImage from './profile-image';
import AccentStyles from './accent-styles';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import defaultHeader from '../../assets/userstores/backgrounds/hero.jpg';

const UserStoreWrapper = ({ store, children, minimal }) => {
  const backgroundImage = _.get(store, 'headerImage.thumbnails.header') || defaultHeader;
  const profileImage = _.get(store, 'profileImage.thumbnails.profile') || _.get(store, 'gravatar');
  const accentColor = _.get(store, 'accentColor') || 'ff6f00';
  const isLoadingBackground = useWaitForImage(backgroundImage);
  return (<>
    <BackgroundOverlay styleColor={`#${accentColor}`} backgroundImage={backgroundImage} isLoading={isLoadingBackground} />
    <div className="my-account user-store-wrapper clearfix">
      <AccentStyles accentColor={accentColor} />
      <div className="my-account__header">
        {minimal 
          ? (<><div></div><div></div></>)
          : (<>
              <div>
              </div>
              <div>
                <ProfileImage image={profileImage} />
              </div>
            </>)}
        <div className="my-account__breadcrumbs">
          <nav>
            <Link to="/shops/">Designer Shops</Link>
            <Link to={`/shop/${_.get(store, 'code')}`}>{_.get(store, 'name')}</Link>
          </nav>
        </div>
      </div>
      {children}
    </div>
  </>);
};

export default UserStoreWrapper;