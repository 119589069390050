let PRODUCT_DATA = [];

export function updateProductData(productList=[]) {
  PRODUCT_DATA = productList;
}

function getProductData(lineItem) {
  const sku = lineItem.sku || lineItem.productSku;
  const id = lineItem.id || lineItem.productId;
  return PRODUCT_DATA.find(product => product.sku === sku || product.id === id);
}

function fbEvent(...args) {
  // https://www.facebook.com/business/help/402791146561655?id=1205376682832142
  if (process.env.REACT_APP_ENV==='production') {
    if (window.fbq) {
      window.fbq.apply(null, args);
    }
  } else {
    console.log('[fbEvent]', args);
  }
}

function gtagEvent(...args) {
  // https://developers.google.com/analytics/devguides/collection/gtagjs/ecommerce 
  if (process.env.REACT_APP_ENV==='production') {
    if (window.gtag) {
      window.gtag.apply(null, args);
    }
  } else {
    console.log('[gtagEvent]', args);
  }
}

function pinEvent(...args) {
  // https://developers.pinterest.com/docs/ad-tools/conversion-tag/?
  if (process.env.REACT_APP_ENV==='production') {
    if (window.pintrk) {
      window.pintrk.apply(null, args);
    }
  } else {
    console.log('[pinEvent]', args);
  }
}

function fmtPrice(price) {
  return Math.round(price / 100);
}

function lineItemPrice(li) {
  const product = getProductData(li);
  const isBundled = li.parentUniqueId || !isNaN(li.parentIndex); 
  return (isBundled 
    ? product.bundledPrice
    : product.price) || 0;
};

function getIdQtyList(lineItems=[], giftCertificates=[], options={}) {
  const mapKey = key => (options.mapKeys && options.mapKeys[key]) || key;
  return [
    ...lineItems.map(li => {
      const product = getProductData(li);
      return { 
        [mapKey('id')]: product.sku,
        [mapKey('quantity')]: li.quantity,
        ...(options.price ? { [mapKey('price')]: fmtPrice( lineItemPrice(li) ) } : {})
      };
    }),
    ...(giftCertificates.map(gc => ({ 
      [mapKey('id')]: 'GC', 
      [mapKey('quantity')]: 1,
      ...(options.price ? { [mapKey('price')]: fmtPrice(gc.amount) } : {})
    }))),
  ];
}

function getLineItemsValue(lineItems=[], giftCertificates=[]) {
  let totalValue = 0;
  lineItems.forEach(li => {
    const skuValue = lineItemPrice(li);
    totalValue += skuValue * li.quantity;
  });
  giftCertificates.forEach(gc => {
    totalValue += gc.amount || 0;
  });
  return fmtPrice(totalValue);
}

/********************************
 * --- TRACKING EVENT HOOKS --- *
 ********************************/

export function trackPageView() {
  fbEvent('track', 'PageView');

  gtagEvent('config', process.env.REACT_APP_GOOGLE_UA);

  pinEvent('track', 'PageVisit');
}

export function trackViewProduct(sku) {
  fbEvent('track', 'ViewContent', {
    content_type: 'product',
    content_ids: sku
  });

  gtagEvent('event', 'view_item', {
    items: [ { id: sku } ]
  });
}

export function trackSignup() {
  fbEvent('track', 'CompleteRegistration');

  gtagEvent('event', 'sign_up');

  pinEvent('track', 'Signup');
}

export function trackAddProductToCart(lineItems=[]) {
  const addedValue = getLineItemsValue(lineItems);

  fbEvent('track', 'AddToCart', {
    content_type: 'product',
    currency: 'USD',
    value: addedValue,
    contents: getIdQtyList(lineItems)
  });

  gtagEvent('event', 'add_to_cart', {
    items: getIdQtyList(lineItems, [], { price: true })
  });

  pinEvent('track', 'AddToCart', {
    value: addedValue,
    currency: 'USD',
    line_items: getIdQtyList(lineItems, [], { mapKeys: { id: 'product_id', quantity: 'product_quantity' } })
  });
}

export function trackAddGCToCart(giftCertificate) {
  const gcAmount = fmtPrice(giftCertificate.amount);

  fbEvent('track', 'AddToCart', {
    content_type: 'product',
    currency: 'USD',
    value: gcAmount,
    contents: [{ id: 'GC', quantity: 1 }]
  });

  gtagEvent('event', 'add_to_cart', {
    items: [{ id: 'GC', quantity: 1, price: gcAmount }]
  });

  pinEvent('track', 'AddToCart', {
    value: gcAmount,
    currency: 'USD',
    line_items: [{ product_id: 'GC', product_quantity: 1 }]
  });
}

export function trackInitiateCheckout({ lineItems=[], giftCertificates=[] }) {
  fbEvent('track', 'InitiateCheckout', {
    currency: 'USD',
    value: getLineItemsValue(lineItems, giftCertificates),
    contents: getIdQtyList(lineItems, giftCertificates)
  });

  gtagEvent('event', 'begin_checkout', {
    items: getIdQtyList(lineItems, giftCertificates, { price: true })
  });
}

export function trackPurchase({ order={}, lineItems=[], giftCertificates=[] }) {
  const orderValue = getLineItemsValue(lineItems, giftCertificates);
  const orderQty = lineItems.length + giftCertificates.length;
 
  fbEvent('track', 'Purchase', {
    currency: 'USD',
    value: orderValue,
    content_type: 'product',
    contents: getIdQtyList(lineItems, giftCertificates)
  });

  gtagEvent('event', 'purchase', {
    transaction_id: order.id,
    value: orderValue,
    currency: 'USD',
    items: getIdQtyList(lineItems, giftCertificates, { price: true })
  });

  pinEvent('track', 'Checkout', {
    value: orderValue,
    order_quantity: orderQty,
    currency: 'USD',
    line_items: getIdQtyList(lineItems, giftCertificates, { mapKeys: { id: 'product_id', quantity: 'product_quantity' } })
  });
}