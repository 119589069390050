import React from 'react';

const AffiliateTOS = () => {
  return (<div className="affiliate-tos">
    <h2>Affiliate Terms and Conditions</h2>

    <p>As an authorized affiliate, referer, or fundraising entity (Affiliate) of Picture This Clothing, LLC (the Company), you agree to abide by the terms and conditions contained in this Agreement (Agreement). Please read the entire Agreement carefully before registering and promoting Picture This Clothing as an Affiliate.</p>

    <p>Your participation in the Program is solely to legally advertise our website to receive a commission on products purchased by individuals referred to Picture This Clothing by your own website or personal referrals and use of your affiliate code.</p>

    <p>By participating in the Picture This Clothing Affiliate Program (the Program), you indicate your acceptance of this Agreement and its terms and conditions.</p>

    <h3>1. Approval or Rejection of the Affiliate</h3>

    <p>We reserve the right to approve or reject ANY Affiliate from our program at our sole and absolute discretion. You will have no legal recourse against us for the rejection of your Affiliate code, status, account, or commission. Violations of these Terms may result in the forfeiture of all Commission previously earned through the program.</p>

    <h3>2. Qualifying Referrals</h3>

    <p>Active Picture This Clothing customers can earn Commission in the following ways:</p>

    <ol>

      <li>A referred friend must make a purchase through the active customer’s unique Affiliate referral link or using an Affiliate code.</li>

      <li>The referred friend must successfully complete an order totaling $49.00 USD (1 product) or more.<br /><br />
      
      If your friend does not click on the personal referral link in their invite email or any other invite mechanism, or enter your referral code manually in the Magic Code field during checkout, you may not receive Commission and we will have no liability to you because there is no discernible way to identify that the purchase resulted from your referral. We are not able to apply affiliate codes,  affiliate discounts, or commissions retroactively to an order that has already been completed.
      </li>

      <li>No Spam<br /><br />

      Active Picture This Clothing customers should only distribute their personal referral links via email using the Picture This Clothing email referral tool hosted at www.picturethisclothing.com. Emails must be created and distributed in a personal manner and bulk email distribution is prohibited. Any distribution of your referral link that could constitute unsolicited commercial or “spam” under any applicable law or regulation is expressly prohibited and will be grounds for immediate termination of your account and exclusion from Picture This Clothing’s Affiliate Program. Purchases from Picture This Clothing made through affiliate links that were distributed through an unauthorized email channel will not result in qualified Affiliate credit and, if discovered, any Commission issued in connection with such transactions may be revoked.
  </li>

      <li>Sharing Affiliate Links Affiliate referrals should strictly be used for personal purposes and shared with personal connections. Personal links should not be distributed to commercial websites (such as coupon websites, etc). We reserve the right to revoke or refuse to issue any Commission for qualified Affiliate referrals that we suspect were generate through improper channels.</li>

    </ol>

    <h3>3. Commission &amp; Payment</h3>

    <p>Our current Commission rate is 10% of the total sale price we receive from your Affiliate referral. Your Affiliate code also provides 10% off for the customer using the code. If a customer uses your code to purchase one item for $49.00 USD, they will receive 10% off ($44.10 USD), and you will receive a 10% commission of the sale price ($4.41 USD) as Store Credit or as cash via PayPal (US dollars minus PayPal transaction fees.) Commission does not include a percentage of shipping fees. We reserve the right to change the commission rate at our sole discretion any time, for any reason, or for no reason.</p>

    <p>Commission payouts can be received in one of two ways:</p>

    <p><b>1. Store Credit</b></p>

    <p>Commissions are payable by default as Picture This Clothing Store Credits issued as a percentage of their Affiliate referral’s purchase cart total excluding shipping unless explicitly noted otherwise in an offer. Store Credits have no cash value, can only be redeemed for Picture This Clothing merchandise, and may be subject to expiration dates or other restrictions at our sole discretion. Store credits cannot, under any circumstances, be redeemed for cash or any cash equivalent including but not limited to refunds. Store Credits are not transferable and may not be auctioned, traded, bartered or sold, but may be used toward the purchase of Gift Certificates.</p>

    <p><b>2. Cash via PayPal</b></p>

    <p>Commission payments via PayPal (US dollars, minus PayPal transaction fees) can be opted for instead of Store Credit in your Affiliate dashboard under your Picture This Clothing account. Affiliates are responsible for having their own PayPal accounts and are responsible for any fees and/or taxes due as a result of any Affiliate commission payout.</p>

    <p>Payment</p>

    <p>Payments due from sales that used your Affiliate code will be documented in your Picture This Clothing account under the “Affiliate” dashboard. For an Affiliate to receive a commission, the referred account must remain active for a minimum of 60 days. Orders will be verified as delivered, non-returned, and non-refunded before paid. Commission payments may take 30-45 days to become verified and active.</p>

    <p>You can use your own Affiliate referral code, and you can receive a commission on your own purchases. The reason for this is to support the needs of group orders and fundraising. There is currently no limit to the amount of times your referral code can be used by someone or by yourself. Orders must be verified as non-returned and non-refunded before commission payments will be disbursed.</p>

    <p>Payments will only be sent for transactions that have been successfully completed. Transactions that result in chargebacks, bank disputes, cancellations, or refunds will not be paid out. In the instance your account is identified at our sole discretion as suspicious of abuse, chargebacks, bank disputes, cancellations, refunds or any activity deemed misaligned with any of our Terms of Use, your account may be suspended or terminated.</p>

    <p>As an active Affiliate, you are responsible for any and all tax liability and income reporting resulting from the Affiliate Program.</p>

    <h3>4. Termination</h3>

    <p>Your affiliate code(s) and status in the Program may be suspended or terminated for any of the following reasons:</p>

    <p>Inappropriate advertisements (false claims, misleading hyperlinks, etc.).</p>

    <p>Spamming (mass email, mass newsgroup posting, etc.).</p>

    <p>Advertising on sites containing or promoting illegal activities or content we reserve the right to deem “unfit”.</p>

    <p>Failure to disclose the affiliate relationship for any promotion that qualifies as an endorsement under existing Federal Trade Commission guidelines and regulations, or any applicable state laws.</p>

    <p>Violation of intellectual property rights. Picture This Clothing reserves the right to require license agreements from those who employ trademarks of Picture This Clothing in order to protect our intellectual property rights.</p>

    <p>Offering rebates, coupons, or other form of promised kick-backs from your affiliate commission as an incentive. Adding bonuses or bundling other products with Picture This Clothing, however, is acceptable.</p>

    <p>Self-referrals, fraudulent transactions, suspected Affiliate fraud.</p>

    <p>Suspected mis-use or abuse of the Affiliate Program.</p>

    <p>Picture This Clothing reserves the right to close the account(s) of any active customers and/or Affiliate-referred friend and to cancel all related Commission and request for payment if we determine in our sole discretion that the active customer and/or referred friend has tampered with the Picture This Clothing Affiliate Program or used or attempted to use the program in an abusive, unethical, unsportsmanlike or otherwise questionable or suspicious manner or breaches any of these Terms &amp; Conditions or is in violation of any law, statute or governmental regulation.</p>

    <p>Additionally, Picture This Clothing reserves the right to cancel the Affiliate Program or change these Terms &amp; Conditions at any time in its sole discretion. Any unclaimed referral rewards will be forfeited at that time.</p>

    <h3>5. Affiliate Links</h3>

    <p>You may use graphic and text links both on your website and within in your email messages. You may also advertise the Picture This Clothing site in online and offline classified ads, magazines, and newspapers.</p>

    <p>You may use the graphics and text provided by us, or you may create your own as long as they are deemed appropriate according to the conditions and not in violation as outlined in this agreement or the Picture This Clothing Terms of Use.</p>

    <h3>6. Coupons, Promos, and Deal Sites</h3>

    <p>Picture This Clothing occasionally offers coupon to select affiliates and to our newsletter subscribers from time to time. Our coupon codes or promotions may override or offer a greater value to customers than the value of your Affiliate code. Affiliate codes cannot be combined with any other offers or promotions.</p>

    <p>Below are the terms that apply for any affiliate who is considering the promotion of our products in relation to a deal or coupon:</p>

    <p>Affiliates may not bid on Picture This Clothing Coupons, Picture This Clothing Discounts or other phrases implying coupons are available.</p>

    <p>Affiliates may not bid on Picture This Clothing Coupons, Picture This Clothing Discounts or other phrases implying coupons are available.</p>

    <p>Affiliates may not generate pop-ups, pop-unders, iframes, frames, or any other seen or unseen actions that set affiliate cookies unless the user has expressed a clear and explicit interest in activating a specific savings by clicking on a clearly marked link, button or image for that particular coupon or deal. Your link must send the visitor to the merchant site.</p>

    <p>User must be able to see coupon/deal/savings information and details before an affiliate cookie is set (i.e. “click here to see coupons and open a window to merchant site” is NOT allowed).</p>

    <p>Affiliate sites may not have “Click for (or to see) Deal/Coupon” or any variation, when there are no coupons or deals available, and the click opens the merchant site or sets a cookie. Affiliates with such text on the merchant landing page will be removed from the program immediately.</p>

    <h3>7. Pay Per Click (PPC) Policy</h3>

    <p>PPC bidding is NOT allowed without prior written permission.</p>

    <h3>8. Liability</h3>

    <p>Picture This Clothing will not be liable for indirect or accidental damages (loss of revenue, commissions) due to affiliate tracking failures, loss of database files, or any results of intents of harm to the Program and/or to our website(s).</p>

    <p>We do not make any expressed or implied warranties with respect to the Affiliate Program and/or the accounts created, or products sold by Picture This Clothing. We make no claim that the operation of the Program and/or our website(s) will be error-free and we will not be liable for any interruptions or errors.</p>

    <p>As an active Affiliate, you are responsible for any and all tax liability and income reporting resulting from the Affiliate Program.</p>

    <h3>9. Term of the Agreement</h3>

    <p>The term of this Agreement begins upon your acceptance of the Affiliate Program and will end when your Affiliate account is terminated.</p>

    <p>The terms and conditions of this agreement may be modified by us at any time. If any modification to the terms and conditions of this Agreement are unacceptable to you, your only choice is to terminate your account or discontinue use of your Affiliate code(s). Your continuing participation in the Program will constitute your acceptance of any change.</p>

    <h3>10. Indemnification</h3>

    <p>Affiliate shall indemnify and hold harmless Picture This Clothing and any of its affiliate and subsidiary companies, officers, directors, employees, licensees, successors and assigns, including those licensed or authorized by Picture This Clothing to transmit and distribute materials, from any and all liabilities, damages, fines, judgments, claims, costs, losses, and expenses (including reasonable legal fees and costs) arising out of or related to any and all claims sustained in connection with this Agreement due to the negligence, misrepresentation, failure to disclose, or intentional misconduct of Affiliate.</p>

    <p>As an active Affiliate, you are responsible for any and all tax liability and income reporting resulting from the Affiliate Program.</p>

    <h3>11. Governing Law, Jurisdiction, and Attorney Fees</h3>

    <p>This Agreement shall be governed by and construed in accordance with the laws of the United States and the State of Nevada. Any dispute arising under or related in any way to this Agreement shall be adjudicated exclusively in the state courts located in Clark County within the City of Las Vegas, Nevada.</p>

    <p>In the event of litigation to enforce any provision of this Agreement, the prevailing party will be entitled to recover from the other party its costs and fees, including reasonable legal fees.</p>

    <h3>12. Electronic Signatures Effective</h3>

    <p>The Agreement is an electronic contract that sets out the legally binding terms of your participation in the Picture This Clothing Affiliate Program. You indicate your acceptance of this Agreement and all of the terms and conditions contained or referenced in this Agreement by checking the “I agree to the terms of service” checkbox in your Affiliate dashboard within your Picture This Clothing account. This action creates an electronic signature that has the same legal force and effect as a handwritten signature.</p>

  </div>);
};

export default AffiliateTOS;
