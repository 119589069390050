import { CALL_API } from '@meadow/redux-api-middleware';

import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE
} from './';
import Moose from '../clients/moose';

export function fetchProducts () {
  return {
    [CALL_API]: {
      types: [
        FETCH_PRODUCTS_REQUEST,
        FETCH_PRODUCTS_SUCCESS,
        FETCH_PRODUCTS_FAILURE
      ],
      promise: Moose.listProducts()
    }
  };
}
