import React from 'react';
import { useWaitForImage } from '../../utils/hooks';

const ProfileImage = ({ image, children }) => {
   const isLoading = useWaitForImage(image);

  const style = isLoading ? {} : {
    backgroundImage: `url(${image})`
  };
  return (<div className="user-store__profile-image" style={style}>
    {isLoading ? (<i className="fa fa-spin fa-circle-o-notch" />) : children}
  </div>);
};

export default ProfileImage;