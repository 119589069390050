import React from 'react';
import { Field } from 'redux-form';
import CheckoutCheckbox from './checkout-checkbox';

const CheckoutInternational = () => {
  return (
    <div className="checkout-box checkout-international">
      <Field
        name="internationalDisclaimer" 
        component={CheckoutCheckbox} 
        className="checkout__input" 
        label="I understand there may be additional customs / duty taxes due upon my package’s arrival." />
    </div>
  );
};

export default CheckoutInternational;