import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { fetchUserIfNeeded } from '../../actions/user';
import { getCurrentUser } from '../../reducers/user';
import { setPixelWidth, setCanvasState, setTemplateOptions, setLoadedDesign } from '../../actions/editor';
import MyStoreForm from '../../components/my-account/my-store-form';
import LoadingSpinner from '../../components/meta/loading-spinner';
import MyStoreWrapper from '../../components/my-account/my-store-wrapper';
import MyStoreFooter from '../../components/my-account/my-store-footer';
import UserProduct from '../../components/user-store/product';
import ProductSellingToggle from '../../components/user-store/product-selling-toggle';
import { updateStaticImage } from '../../utils/editor';
import { Link, withRouter } from 'react-router-dom';
import Moose from '../../clients/moose';

const MyStoreRoute = createReactClass({

  propTypes: {
    user: PropTypes.object.isRequired,
    fetchUserIfNeeded: PropTypes.func.isRequired
  },

  getInitialState() {
    return {
      isLoadingDesigns: false,
      isLoadingStore: false,
      isUpdatingStore: false,
      isUploadingProduct: false,
      showProductForm: false,
      showStoreForm: false,
      showPictureRequirements: false,
      designs: [],
      store: {},
      errorMessage: null
    }
  },

  componentDidMount() {
    this.setState({ isLoadingDesigns: true });
    this.fetchDesigns();
    this.fetchShop();
  },

  fetchDesigns() {
    Moose.fetchPixelDesigns().then(designs => {
      this.setState({ 
        isLoadingDesigns: false,
        designs
      });
    });
  },

  fetchShop() {
    this.setState({ isLoadingStore: true });
    Moose.fetchMyShop().then(store => {
      this.setState({ 
        isLoadingStore: false,
        store 
      });
    });
  },

  handleUpdateStoreSettings(values) {
    this.setState({ isUpdatingStore: true });
    Moose.updateMyShop(values).then(store => {
      this.setState({ 
        isUpdatingStore: false,
        showStoreForm: false,
        errorMessage: null,
        store 
      });
    }).catch(e => {
      switch (e.errorCode) {
        case 'CODE_IN_USE':
        case 'CODE_UNACCEPTABLE':
          this.setState({ errorMessage: 'Sorry, please select a different store code.' });
          break;
        default:
          this.setState({ errorMessage: 'Sorry, there was an error creating your store.' });
      }
    });
  },


  handleToggleProductSellable(design) {
    const { id, userProduct } = design;
    const { designs } = this.state;
    const newStatus = userProduct.status === 'selling' ? 'hidden' : 'selling';
    const newDesigns = designs.map(design => {
      if (design.id === id) {
        return {
          ...design,
          userProduct: {
            ...userProduct,
            status: newStatus,
            updating: !userProduct.updating
          }
        };
      }
      return design;
    });
    this.setState({ designs: newDesigns });
    Moose.updatePixelDesignProduct(id, {
      status: newStatus
    }).then(() => {
      updateStaticImage(design);
      this.fetchDesigns();
    });
  },

  toggleShowProductForm() {
    this.setState({
      showProductForm: !this.state.showProductForm,
      showPictureRequirements: false
    });
  },

  toggleShowStoreForm() {
    this.setState({
      showStoreForm: !this.state.showStoreForm
    });
  },

  toggleShowPictureRequirements() {
    this.setState({
      showPictureRequirements: !this.state.showPictureRequirements
    });
  },

  handleEditDesign(design) {
    const { data: canvasState, product: { sku }, variants, designersName } = design; 
    const { name: storeName } = this.state.store;
    this.props.setCanvasState(canvasState);
    this.props.setTemplateOptions({
      sku,
      variants: variants.map(v => _.pick(v, ['variantId', 'variantOptionId'])),
      designersName: designersName || storeName,
    });
    this.props.setLoadedDesign(design);
    this.props.setPixelWidth(canvasState.width);
    this.props.history.push('/');
  },

  handleDeleteDesign(design) {
    if (window.confirm('Do you want to delete this design?')) {
      Moose.deletePixelDesign(design.id).then(() => {
        this.fetchDesigns();
      });
    }
  },

  renderMyDesigns() {
    const { store, designs, isLoadingDesigns } = this.state;
    const storeExists = store && store.id;
    if (isLoadingDesigns) {
      return (<LoadingSpinner />);
    }
    const className = !designs || designs.length < 2 
      ? 'user-store-products user-store-products-center' 
      : 'user-store-products';
    const editLink = design =>  `/account/my-shop/product/${design.id}`;

    return (
      <div className={className}>
        {(designs || []).map(design => {
          const isToggling = design.updating;
          return (<UserProduct 
              key={design.id} 
              imageLinkTo={editLink(design)}
              pixelDesign={design} 
              productData={this.props.products}>
            <div className="user-product-controls">
              <div className="user-product-buttons">
                {storeExists && (<Link to={editLink(design)} title="Edit Product">
                    <i className="fa fa-pencil" />
                    Describe
                </Link>)}
                <button onClick={() => this.handleEditDesign(design)} title="Edit Design">
                  <i className="fa fa-paint-brush" />
                  Design
                </button>
                <button onClick={() => this.handleDeleteDesign(design)} title="Delete Design">
                  <i className="fa fa-trash" />
                  Delete
                </button>
              </div>
              {storeExists && <ProductSellingToggle design={design} onToggle={() => this.handleToggleProductSellable(design)} disabled={isToggling} />}
            </div>
          </UserProduct>);
        })}
        <a href="/" className="user-product">
          <div className="image">
            <i className="fa fa-plus-circle" />
          </div>
          <div className="info">
            <div className="name">Add New Design</div>
          </div>
          <div className="description">Create and save new pixel art to see it listed here.</div>
          <div className="user-product-controls">
            <button className="order-product">Create a Design <i className="fa fa-plus" /></button>
          </div>
        </a>
      </div>
    );
  },

  renderStoreLink() {
    const { store, products, isLoadingDesigns } = this.state;
    if (!store) {
      return null;
    }
    const sellableProducts = products ? products.filter(product => product.status === 'selling') : [];
    const showNotice = products && !isLoadingDesigns && sellableProducts < 1;
    if (showNotice) {
      return (<div className="user-store-disabled">Note: your store will not be visible to users until you have at least one product for sale.</div>);
    } else {
      const storeLink = `${process.env.REACT_APP_URL}/shop/${store.code}`;
      return (<div className="user-store-link">
        <a href={storeLink} target="_blank" rel="noopener noreferrer">{storeLink}</a>
        <i className="fa fa-external-link" />
      </div>);
    }
  },

  renderStoreInfo() {
    const { store, isLoadingStore, showStoreForm, errorMessage } = this.state;
    const storeExists = store && store.id;
    if (isLoadingStore) {
      return (<LoadingSpinner />);    
    }
    if (showStoreForm) {
      return (<MyStoreForm 
        user={this.props.user}
        initialValues={store}
        storeExists={storeExists}
        errorMessage={errorMessage}
        onCancel={this.toggleShowStoreForm}
        onSubmit={this.handleUpdateStoreSettings} />);
    }
    if (storeExists) {
      return (<div className="user-store-info">
        <h2>{store.name}</h2>
        <p>{store.description}</p>
        {this.renderStoreLink()}
        <button className="circle-icon-button" 
          onClick={this.toggleShowStoreForm}>
          <i className="fa fa-pencil" />
        </button>
      </div>);
    } else {
      return (<div className="user-store-cta">
        <p>Want to sell your designs?</p>
        <button className="button primary" type="button" onClick={this.toggleShowStoreForm}>Create your store now!</button>
      </div>);
    }
  },

  renderDisabledNotice() {
    const { store } = this.state;
    if (store && store.disabled) {
      return (<div className="user-store-disabled">
        <p>Your store has been disabled and is not currently visible to users.</p>
        <p>If you have any questions, please email <a href="mailto:hello@picturethisclothing.com">hello@picturethisclothing.com</a>.</p>
      </div>);
    }
    return null;
  },

  render() {
    const { user } = this.props;
    const { store } = this.state;
    return (<>
      <MyStoreWrapper store={store} user={user} onChange={this.handleUpdateStoreSettings}>
        {this.renderStoreInfo()}

        {this.renderDisabledNotice()}

        {this.renderMyDesigns()}
      </MyStoreWrapper>
      <MyStoreFooter />
    </>);
  }

});

function mapStateToProps (state) {
  const { entity } = getCurrentUser(state.user);
  return {
    user: entity,
    products: state.products.list.entities
  };
}

const mapDispatchToProps = {
  fetchUserIfNeeded,
  setCanvasState,
  setTemplateOptions,
  setPixelWidth,
  setLoadedDesign
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyStoreRoute));